import { toast, ToastContent } from 'react-toastify';

export const toastSuccess = (msg: ToastContent) =>
  toast.success(msg, {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  });
