import { useQuery } from 'react-query';
import { tokenManager } from '../../token-manager';
import { api, Cheque } from 'api';
import { useAppSelector } from '../../../app/hooks';
import { selectRole } from '../../auth/authSlice';
import { keySelfChequesList } from './keys';

export const useQuerySelfCheques = () => {
  const { id: roleId } = useAppSelector(selectRole)!;
  return useQuery<Cheque[]>(keySelfChequesList, async () => {
    const result = await api.selfPendingCheques({
      params: { roleId },
      token: tokenManager.get(),
    });
    return result.cheques!;
  });
};
