import React from 'react';
import { AreaThemeProvider } from './AreaThemeProvider/AreaThemeProvider';
import { ThemedBoard } from './ThemedBoard';

export const Board = () => {
  return (
    <AreaThemeProvider>
      <ThemedBoard />
    </AreaThemeProvider>
  );
};
