// **unstable_createMuiStrictModeTheme** https://stackoverflow.com/a/64135466/1663283
import {
  PaletteColor,
  unstable_createMuiStrictModeTheme as createTheme,
} from '@mui/material';
import { RoleType } from 'api';

type Domain = RoleType | 'none';

export interface PaletteColorEx extends PaletteColor {
  lighter: string;
}

const palettes = {
  [RoleType.TENANT]: {
    primary: {
      main: '#6ab0d7',
      light: '#a6d0e7',
      lighter: 'rgba(225,239,247,0.5)',
    },
    secondary: {
      main: '#ff77de',
    },
  },
  [RoleType.OWNER]: {
    primary: {
      main: '#f5639c',
      light: '#f9a1c4',
      lighter: 'rgba(253,224,235,0.5)',
    },
    secondary: {
      main: '#3cc43c',
    },
  },
  [RoleType.VAAD]: {
    primary: {
      main: '#e294fd', //https://maketintsandshades.com/#e294fd
      light: '#eebffe',
      lighter: 'rgba(249,234,255,0.50)',
    },
    secondary: {
      main: '#56d3e0',
    },
  },
  none: {
    primary: {
      main: '#9bb4ce',
      light: '#c3d2e2',
      lighter: '#ebf0f5',
    },
    secondary: {
      main: '#f1dd88',
    },
  },
};

export const theme = (domain: Domain) => {
  return createTheme({
    palette: palettes[domain],
  });
};
