import { useMutation, useQuery, useQueryClient } from 'react-query';
import { api, Cheque } from 'api';
import { tokenManager } from '../../token-manager';
import { useAppSelector } from 'app/hooks';
import { selectPropertyId } from '../../auth/authSlice';
import { keyAptData, keyChequesList, keyPaymentsList } from './keys';

const keyAptList = (aptId: string) => keyAptData(aptId, 'cheques', 'list');

const { depositCheque } = api;

export type ChequesData = {
  cheque: {
    id: string;
    propertyId: string;
    aptId: string;
    chequeNo: string;
    date: number;
    amount: number;
  };
  apartment?: {
    aptId: string;
    entrance?: number | undefined;
    aptNum: number;
  };
}[];

export const useQueryCheques = () => {
  const propertyId = useAppSelector(selectPropertyId)!;
  return useQuery<ChequesData>(keyChequesList, () =>
    api.getBuildingPendingCheques({
      params: { propId: propertyId },
      token: tokenManager.get(),
    })
  );
};

export const useQueryAptCheques = (aptId: string) => {
  const propertyId = useAppSelector(selectPropertyId)!;
  return useQuery<Cheque[]>(keyAptList(aptId), () =>
    api
      .fetchAptCheques({
        params: { propId: propertyId, aptId },
        token: tokenManager.get(),
      })
      .then((response) => response.cheques)
  );
};

export const useMutationNewCheque = () => {
  const propertyId = useAppSelector(selectPropertyId)!;
  const queryClient = useQueryClient();
  return useMutation(
    ({
      aptId,
      date,
      chequeNo,
      amount,
      payer,
    }: {
      payer: string;
      aptId: string;
      date: number;
      chequeNo: string;
      amount: number;
    }) => {
      return api.saveCheque({
        token: tokenManager.get(),
        params: { propId: propertyId, aptId },
        body: { payer, cheques: [{ date, chequeNo, amount }] },
      });
    },
    {
      async onSuccess() {
        await queryClient.invalidateQueries(keyChequesList);
        await queryClient.invalidateQueries(keyAptData());
      },
    }
  );
};

export const useMutationDeleteCheque = () => {
  const propertyId = useAppSelector(selectPropertyId)!;
  const queryClient = useQueryClient();
  return useMutation(
    ({ id }: { id: string }) => {
      const previousData =
        queryClient.getQueryData<ChequesData>(keyChequesList);
      const updatedData = previousData?.filter((item) => item.cheque.id !== id);
      queryClient.setQueryData(keyChequesList, updatedData);

      return api.deleteCheque({
        token: tokenManager.get(),
        params: { propId: propertyId, chequeId: id },
      });
    },
    {
      async onSuccess() {
        await queryClient.invalidateQueries(keyPaymentsList);
        await queryClient.invalidateQueries(keyAptData());
      },
    }
  );
};

export const useMutationDepositCheque = () => {
  const propertyId = useAppSelector(selectPropertyId)!;
  const queryClient = useQueryClient();
  return useMutation(
    ({ id }: { id: string }) => {
      const previousData =
        queryClient.getQueryData<ChequesData>(keyChequesList);
      const updatedData = previousData?.filter((item) => item.cheque.id !== id);
      queryClient.setQueryData(keyChequesList, updatedData);

      return depositCheque({
        token: tokenManager.get(),
        params: { propId: propertyId, chequeId: id },
        body: undefined,
      });
    },
    {
      onSuccess() {
        void queryClient.invalidateQueries(keyPaymentsList);
        void queryClient.invalidateQueries(keyAptData());
      },
    }
  );
};
