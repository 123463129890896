import axios, { AxiosAdapter, AxiosInstance } from 'axios';
import { AppDispatch } from '../app/store';
import { needsPaidPlan, networkError } from '../features/auth/authSlice';
import { tokenManager } from '../features/token-manager';
import { apiClientFactory } from '@vaad/client';

interface MaybeAxiosError {
  isAxiosError?: boolean;
}

interface AxiosError {
  isAxiosError?: boolean;
  config?: {
    url: string;
  };
  response?: {
    status: number;
    data?: {
      error?: { needsPaidPlan?: boolean };
    };
  };
}

function isAxiosError(error: MaybeAxiosError): error is AxiosError {
  return error?.isAxiosError === true;
}

const isCheckTokenUnauthorizedError = (error: MaybeAxiosError): boolean =>
  isAxiosError(error) &&
  error.config?.url === '/login/check-token' &&
  error.response?.status === 401;

const isNeedsPaidPlanError = (error: MaybeAxiosError): boolean =>
  isAxiosError(error) &&
  error.response?.status === 401 &&
  error.response.data?.error?.needsPaidPlan === true;

let instance: AxiosInstance;
export const configureAxios = ({
  root,
  adapter,
  dispatch,
}: {
  root: string;
  adapter?: AxiosAdapter;
  dispatch: AppDispatch;
}) => {
  instance = axios.create({
    adapter,
    baseURL: root,
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (isCheckTokenUnauthorizedError(error)) {
        tokenManager.clear();
        location.href = '/';
      } else if (isNeedsPaidPlanError(error)) {
        dispatch(needsPaidPlan());
      } else {
        dispatch(networkError());
      }
      return Promise.reject(error);
    }
  );
};

export const api = apiClientFactory(() => instance);

export * from '@vaad/client';
