import { EditProjectForm } from './EditProjectForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutationEditProject, useQueryProject } from 'features/data/queries';
import { CircularProgress } from '@mui/material';
import { generatePropertyPath, Paths } from '../../../app/Paths';
import { useCurrentRole } from '../../../app/hooks';
import { Project } from 'api';
import { toastSuccess } from '#common/toasts';
import { tKeys } from '../../../translationKeys';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

export const EditProject = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { roleType, propertyId } = useCurrentRole();
  const { projectId } = useParams<'projectId'>();
  const { isLoading, data: project } = useQueryProject(projectId!);
  const mutationEditProject = useMutationEditProject(projectId!);

  const returnToProjectView = useCallback(() => {
    navigate(
      generatePropertyPath(Paths.property.projects.view, {
        role: roleType,
        propId: propertyId,
        projectId: projectId!,
      })
    );
  }, [roleType, propertyId, projectId]);

  if (isLoading) {
    return <CircularProgress />;
  }

  const onCancel = () => {
    returnToProjectView();
  };

  const onConfirm = ({
    name,
    description,
    amount,
    installments,
  }: Pick<Project, 'name' | 'description' | 'amount' | 'installments'>) => {
    mutationEditProject.mutate(
      { name, description, amount, installments },
      {
        onSuccess() {
          toastSuccess(t(tKeys.projectsPage.newProject.success()));
          returnToProjectView();
        },
      }
    );
  };

  return (
    <EditProjectForm
      editedProject={project}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
};
