import { useCurrentRole } from './useCurrentRole';
import { makeYearMonth } from '@vaad/utils';

export const usePlan = () => {
  const {
    property: { plan },
  } = useCurrentRole();

  const isPaidPlan = (plan?.untilYm ?? 0) >= makeYearMonth(Date.now());

  return { isPaidPlan };
};
