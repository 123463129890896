import { Card, CardContent, Container } from '@mui/material';
import { useFormat, formatDateShort } from 'i18n';
import { useTranslation } from 'react-i18next';
import { Property } from '@vaad/client';
import { TableList } from '#common/TableList';
import { makeDateFromYmd } from '@vaad/utils';
import { tKeys } from 'translationKeys';
import { useAppSelector } from 'app/hooks';
import { selectProperty } from 'features/auth/authSlice';
import { GetBuildingPaymentsResponse } from 'api';
import { TableActions } from '#common/index';
import { entranceName } from 'features/utils';

export const PaymentsManageTable = ({
  payments,
  onCreate,
}: {
  payments: GetBuildingPaymentsResponse['payments'];
  onCreate: () => void;
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { formatCurrency2 } = useFormat();
  const property = useAppSelector(selectProperty)! as Property;
  const hasOnlyOneEntrance = property.entrances.length === 1;

  return (
    <Container maxWidth="md">
      <TableActions
        buttons={[
          {
            caption: t(tKeys.payments.managePage.emptyState.button()),
            needsPaidPlan: true,
            onClick: onCreate,
          },
        ]}
      />
      <Card>
        <CardContent>
          <TableList<typeof payments[number]>
            paper
            size="small"
            columns={[
              {
                title: t(tKeys.table.column.entrance()),
                hidden: hasOnlyOneEntrance,
                render: ({ apartment }) =>
                  entranceName(t)(property, apartment.entrance ?? 1),
              },
              {
                title: t(tKeys.table.column.apartment()),
                render: (row) => row.apartment.aptNum,
              },
              {
                title: t(tKeys.table.column.date()),
                render: (row) =>
                  formatDateShort(makeDateFromYmd(row.payment.date), language),
              },
              {
                title: t(tKeys.table.column.amount()),
                align: 'right',
                render: (row) => formatCurrency2(row.payment.amount),
              },
              {
                title: t(tKeys.payments.table.column.type()),
                render: (row) => t(`payments.types.${row.payment.type}`),
              },
              {
                title: t(tKeys.payments.table.column.ref()),
                render: (row) => row.payment.ref,
              },
            ]}
            items={payments}
          />
        </CardContent>
      </Card>
    </Container>
  );
};
