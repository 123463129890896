import { AppDispatch } from '../app/store';
import { startLoading, stopLoading } from './menu/menuSlice';
import { addActiveRole } from './auth/authSlice';
import { NavigateFunction } from 'react-router-dom';
import { api, EntrancesNumbering, RoleType } from 'api';
import { tokenManager } from './token-manager';
import { generatePropertyPath, Paths } from '../app/Paths';

export type Entrance = {
  name?: string;
  apartments: number;
};

export const saveNewBuilding =
  (
    navigate: NavigateFunction,
    {
      address,
      city,
      numbering,
      entrances,
    }: {
      address: string;
      city: string;
      numbering: EntrancesNumbering;
      entrances: Entrance[];
    }
  ) =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(startLoading());
    const token = tokenManager.get();

    const { role, property } = await api.createProperty({
      token,
      body: {
        address,
        city,
        numbering,
        entrances,
      },
    });
    const newRole = {
      ...role,
      role: role.role as RoleType,
      property: {
        ...property,
        address,
        city,
        entrances: entrances.map((entrance) => entrance.apartments),
      },
    };
    dispatch(addActiveRole(newRole));
    dispatch(stopLoading());
    navigate(
      generatePropertyPath(Paths.property.owners, {
        role: newRole.role,
        propId: newRole.propertyId,
      })
    );
  };
