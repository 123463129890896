import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Paths, usePropertyPath } from 'app/Paths';
import { EmptyState } from '#common/EmptyState';
import { tKeys } from 'translationKeys';
import { makeIcon } from '#common/icon-utils';
import { ReactComponent as Construction } from './Construction.svg';

export const ProjectsEmptyStateVaad = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { generatePath } = usePropertyPath();
  const onClick = () => {
    navigate(generatePath(Paths.property.projects.new));
  };

  return (
    <EmptyState
      tagline={t(tKeys.projectsPage.emptyState.tagline())}
      description={t(tKeys.projectsPage.manage.emptyState.description())}
      button={t(tKeys.projectsPage.manage.emptyState.button())}
      icon={makeIcon(Construction)}
      onClick={onClick}
    />
  );
};
