import { Apartment, Cheque, Property } from 'api';
import { Button, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TableList } from '#common/TableList';
import { useFormat, formatDateMonthNameAndYear } from 'i18n';
import { tKeys } from 'translationKeys';
import { makeDateFromYm, makeYearMonth } from '@vaad/utils';
import { entranceName } from 'features/utils';
import { PaidButton } from '#common/PaidButton';

interface Props {
  property: Property;
  items: { cheque: Cheque; apartment?: Partial<Apartment> }[];
  onDestroyCheque: (id: string) => void;
  onDepositCheque: (id: string, no: string) => void;
  noApt?: boolean;
}

const isDepositable = (cheque: Cheque) =>
  cheque.date <= makeYearMonth(Date.now());

export const BuildingChequesList = ({
  property,
  items,
  onDestroyCheque,
  onDepositCheque,
  noApt,
}: Props) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { formatCurrency2 } = useFormat();

  return (
    <Card>
      <TableList<typeof items[0]>
        size="small"
        columns={[
          {
            hidden: !!noApt,
            title: t(tKeys.table.column.entrance()),
            align: 'center',
            render: ({ apartment }) =>
              entranceName(t)(property, apartment?.entrance ?? 1),
          },
          {
            hidden: !!noApt,
            title: t(tKeys.table.column.apartment()),
            align: 'center',
            render: ({ apartment }) => apartment?.aptNum,
          },
          {
            title: t(tKeys.table.column.chequeNum()),
            align: 'center',
            render: ({ cheque }) => cheque.chequeNo,
          },
          {
            title: t(tKeys.table.column.date()),
            align: 'start',
            render: ({ cheque }) =>
              formatDateMonthNameAndYear(makeDateFromYm(cheque.date), language),
          },
          {
            title: t(tKeys.table.column.amount()),
            align: 'end',
            render: ({ cheque }) => formatCurrency2(cheque.amount),
          },
          {
            align: 'start',
            render: ({ cheque }) => (
              <>
                <Button onClick={() => onDestroyCheque(cheque.id)}>
                  {t(tKeys.chequesList.row.actions.destroy())}
                </Button>
                {isDepositable(cheque) && (
                  <PaidButton
                    onClick={() => onDepositCheque(cheque.id, cheque.chequeNo)}
                  >
                    {t(tKeys.chequesList.row.actions.deposit())}
                  </PaidButton>
                )}
              </>
            ),
          },
        ]}
        items={items}
      />
    </Card>
  );
};
