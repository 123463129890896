import {
  Button,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { Property } from 'api';
import { FC, Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { entranceString, isValidEmail } from 'features/utils';
import { Gravatar } from '#common/Gravatar';
import * as classes from './classes';
import { expiresInT } from 'app/constants';
import { onEnter } from '#common/keyboard-utils';
import { tKeys } from 'translationKeys';
import { OwnersData } from 'features/data/queries/owners';

interface Props {
  property: Property;
  ownersData: OwnersData;
  onSave: ({
    entrance,
    aptNum,
    fullName,
    email,
  }: {
    entrance: number;
    aptNum: number;
    fullName: string;
    email: string;
  }) => void;
  onRevokeInvite: ({
    entrance,
    iApt,
    inviteId,
  }: {
    entrance: number;
    iApt: number;
    inviteId: string;
  }) => void;
}

export const OwnersListAdmin: FC<Props> = ({
  property,
  ownersData,
  onSave,
  onRevokeInvite,
}) => {
  const { t } = useTranslation();
  const expiresIn = expiresInT(t);

  const [editing, setEditing] = useState(
    null as { entrance: number; apt: number; emailField?: 'email' } | null
  );

  const refName = useRef<HTMLInputElement>();
  const refEmail = useRef<HTMLInputElement>();

  useEffect(() => {
    if (editing) {
      (editing.emailField ? refEmail : refName)?.current?.focus();
    }
  }, [editing]);

  const onClickRevoke = (entrance: number, iApt: number, inviteId: string) => {
    setEditing(null);
    onRevokeInvite({ entrance, iApt, inviteId });
  };

  const onClickEdit = (
    entrance: number,
    iApt: number,
    emailField?: 'email'
  ) => {
    setEditing({ entrance, apt: iApt, emailField });
  };

  const onClickDiscard = () => {
    setEditing(null);
  };

  const renderFullStateLine = (
    {
      email,
      fullName,
      emailHash,
      invite,
    }: {
      email?: string;
      fullName: string;
      emailHash?: string;
      invite?: { expires: number; id: string };
    },
    entrance: number,
    aptNum: number
  ) => {
    if (editing?.entrance === entrance && editing?.apt === aptNum) {
      return renderEditingLine(entrance, aptNum);
    }

    return (
      <>
        <TableCell className={classes.minicol}>
          {email && <Gravatar hash={emailHash} email={email} />}
        </TableCell>
        <TableCell>
          <div>{fullName}</div>
          <div className={classes.email}>{email}</div>
          {invite && invite.expires > 0 && (
            <div className={classes.expires}>
              {t(tKeys.invite.pending.expiresIn(), {
                expiresIn: expiresIn(invite.expires),
              })}
            </div>
          )}
        </TableCell>
        <TableCell>
          {!invite && (
            <Button onClick={() => onClickEdit(entrance, aptNum)}>
              {t(tKeys.actions.edit())}
            </Button>
          )}
          {invite?.id && (
            <Button onClick={() => onClickRevoke(entrance, aptNum, invite.id)}>
              {t(tKeys.ownersPage.revokeButton())}
            </Button>
          )}
        </TableCell>
      </>
    );
  };

  const onClickSave = (entrance: number, aptNum: number) => {
    const fullName = refName?.current?.value;
    if (!fullName) {
      refName?.current?.focus();
      return;
    }

    const email = refEmail?.current?.value;
    if (!email || !refEmail.current?.checkValidity() || !isValidEmail(email)) {
      refEmail?.current?.focus();
      return;
    }

    setEditing(null);

    onSave({ entrance, aptNum, fullName, email });
  };

  const renderEditingLine = (entrance: number, aptNum: number) => {
    const owner = ownersData[entrance]?.[aptNum];

    return (
      <>
        <TableCell />
        <TableCell>
          <TextField
            variant="standard"
            label={t(tKeys.ownersPage.editField.name.label())}
            inputRef={refName}
            defaultValue={owner?.fullName}
            onKeyDown={onEnter(() => onClickSave(entrance, aptNum))}
            required
          />
          <TextField
            className={classes.emailTextField}
            variant="standard"
            label={t(tKeys.ownersPage.editField.email.label())}
            inputRef={refEmail}
            defaultValue={owner?.email}
            onKeyDown={onEnter(() => onClickSave(entrance, aptNum))}
            type="email"
            required
          />
        </TableCell>
        <TableCell>
          <Button
            variant="contained"
            onClick={() => onClickSave(entrance, aptNum)}
          >
            {t(tKeys.actions.save())}
          </Button>{' '}
          <Button onClick={() => onClickDiscard()}>
            {t(tKeys.ownersPage.discardButton())}
          </Button>
        </TableCell>
      </>
    );
  };

  const renderEmptyStateLine = (entrance: number, iApt: number) => {
    if (editing?.entrance === entrance && editing?.apt === iApt) {
      return renderEditingLine(entrance, iApt);
    }

    return (
      <>
        <TableCell className={classes.empty} />
        <TableCell
          className={classes.empty}
          onClick={() => onClickEdit(entrance, iApt)}
        />
        <TableCell>
          <Button onClick={() => onClickEdit(entrance, iApt)}>
            {t(tKeys.actions.edit())}
          </Button>
        </TableCell>
      </>
    );
  };

  const renderEntrance = (entrance: number, nbApts: number) => {
    return (
      <Fragment key={`entrance_${entrance}`}>
        <TableHead>
          <TableRow>
            {property.entrances.length > 1 && (
              <TableCell align="center">
                {t(tKeys.ownersPage.table.entrance())}
              </TableCell>
            )}
            <TableCell align="center">
              {t(tKeys.ownersPage.table.apartment())}
            </TableCell>
            <TableCell />
            <TableCell>{t(tKeys.ownersPage.table.owner())}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {Array.from(Array(nbApts)).map((_, iApt) => {
            const aptNum = iApt + 1;
            const owner = ownersData[entrance]?.[aptNum];
            return (
              <TableRow key={`entrance_${entrance}_${aptNum}`}>
                {property.entrances.length > 1 && (
                  <TableCell align="center" className={classes.minicol}>
                    {entranceString(t, property, entrance)}
                  </TableCell>
                )}
                <TableCell
                  align="center"
                  size="small"
                  className={classes.minicol}
                >
                  {aptNum}
                </TableCell>
                {owner && renderFullStateLine(owner, entrance, aptNum)}
                {!owner && renderEmptyStateLine(entrance, aptNum)}
              </TableRow>
            );
          })}
        </TableBody>
      </Fragment>
    );
  };

  return (
    <Container>
      <Card>
        <TableContainer>
          <Table>
            {property.entrances.map((nbApts, iEntrance) =>
              renderEntrance(iEntrance + 1, nbApts)
            )}
          </Table>
        </TableContainer>
      </Card>
    </Container>
  );
};
