import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, MenuItem, Select } from '@mui/material';
import { entranceName } from 'features/utils';
import { Property } from 'api';
import { useQueryResidents } from 'features/data/queries/residents';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

const Segment = styled.div`
  display: inline-block;
  min-width: 40px;
`;

export const ApartmentSelector: FC<
  {
    aptId: string;
    onChange(aptId: string): void;
    property: Property;
  } & { 'data-testid'?: string }
> = ({ aptId, onChange, property, 'data-testid': testId }) => {
  const { isFetching, data: residents } = useQueryResidents();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (!isFetching && residents && residents.length === 0) {
      navigate('../owners');
    }
  }, [residents]);

  if (isFetching || !residents) {
    return <CircularProgress data-testid="loader" />;
  }

  return (
    <Select
      style={{ minWidth: '120px' }}
      data-testid={testId}
      name="aptId"
      value={aptId}
      autoWidth
      onChange={(evt) => onChange(evt.target.value)}
    >
      {residents.map((entry) => (
        <MenuItem key={entry.aptId} value={entry.aptId}>
          <Segment>{entranceName(t)(property, entry.entrance)}</Segment>
          <Segment>{entry.apt}</Segment>
          <Segment>{entry.owners.join(', ')}</Segment>
        </MenuItem>
      ))}
    </Select>
  );
};
