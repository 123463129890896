import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  closeDrawer,
  openDrawer,
  selectIsDrawerOpen,
} from 'features/menu/menuSlice';

export const useMenuOpen = () => {
  const isOpen = useAppSelector(selectIsDrawerOpen);
  const dispatch = useAppDispatch();

  const setOpen = (value: boolean) => {
    dispatch(value ? openDrawer() : closeDrawer());
  };

  return { isOpen, setOpen };
};
