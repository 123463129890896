import { FC, PropsWithChildren } from 'react';

export enum Appearance {
  draft = 'draft',
  danger = 'danger',
  active = 'active',
  archived = 'archived',
}

interface Props extends PropsWithChildren {
  appearance: Appearance;
}

const appearanceToColor: Record<Appearance, string> = {
  [Appearance.draft]: '#e3e3e3ff',
  [Appearance.active]: '#D4ECB5FF',
  [Appearance.danger]: 'red',
  [Appearance.archived]: '#ffcc6c',
};

export const Pill: FC<Props> = ({ children, appearance }) => {
  return (
    <div
      style={{
        display: 'inline',
        fontFamily: 'Monospace',
        fontSize: '.75rem',
        backgroundColor: appearanceToColor[appearance],
        paddingBlock: '.25rem',
        paddingInline: '.5rem',
        borderRadius: 12,
      }}
    >
      {children}
    </div>
  );
};
