import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const ActionsBar = styled(Box)`
  text-align: end;
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
