export const keyPaymentsList = ['payments', 'list'];
export const keyChequesList = ['cheques', 'list'];
export const keyProjectsList = ['projects', 'list'];
export const keyResidentsList = ['residents', 'list'];

export const keySelfPaymentsList = ['self', 'payments', 'list'];
export const keySelfChequesList = ['self', 'cheques', 'list'];
export const keySelfInvitesList = ['self', 'invites', 'list'];

export const keyAptData = (aptId?: string, ...sections: string[]): string[] => [
  'apt',
  ...(aptId ? [aptId, ...sections] : []),
];

export const keyTenantData = (roleId: string) => ['role', roleId, 'tenant'];

export const keyOwners = (propId?: string) => [
  'owners',
  ...(propId ? [propId] : []),
];
export const keyBudget = ['budget'];
