import { Page } from '#common/Page/Page';
import { useTranslation } from 'react-i18next';
import { Container, Paper } from '@mui/material';
import { selectRole } from 'features/auth/authSlice';
import { useAppSelector } from 'app/hooks';
import { BankDetailsEmptyPage } from './BankDetailsEmptyPage';
import { tKeys } from 'translationKeys';
import { VerticalForm } from '#common/VerticalForm';

export const BankDetailsPage = () => {
  const { t } = useTranslation();
  const { bank } = useAppSelector(selectRole)!.property;

  if (!bank) {
    return <BankDetailsEmptyPage />;
  }

  return (
    <Page
      title={t(tKeys.bankDetails.page.title())}
      subtitle={t(tKeys.bankDetails.page.subtitle())}
    >
      <Container maxWidth="sm" component={Paper}>
        <VerticalForm
          lines={[
            { label: t(tKeys.bankDetails.page.bank()), value: bank.bank },
            { label: t(tKeys.bankDetails.page.branch()), value: bank.branch },
            { label: t(tKeys.bankDetails.page.account()), value: bank.account },
            { label: t(tKeys.bankDetails.page.name()), value: bank.name },
            { label: t(tKeys.bankDetails.page.iban()), value: bank.iban },
          ]}
        />
      </Container>
    </Page>
  );
};
