import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { EntrancesNumbering } from 'api';
import { onEnter } from '#common/keyboard-utils';
import { tKeys } from 'translationKeys';
type Props = {
  count: number;
  onSetCount(val: number): void;
  numbering: string;
  onSetNumbering(val: EntrancesNumbering): void;
  names: string[];
  onSetName(i: number, name: string): void;
};

const EntrancesAlphabetNames = [
  'Alpha',
  'Bravo',
  'Charlie',
  'Delta',
  'Echo',
  'Foxtrot',
  'Golf',
  'Hotel',
  'India',
  'Juliet',
];

export const StepEntrances = ({
  count,
  onSetCount,
  numbering,
  onSetNumbering,
  names,
  onSetName,
}: Props) => {
  const {
    t,
    i18n: { dir },
  } = useTranslation();
  const [countWasClicked, setCountWasClicked] = useState(false);
  const [numberingWasClicked, setNumberingWasClicked] = useState(false);

  const refEntranceName = Array.from({ length: 10 }).map(() =>
    useRef<HTMLInputElement>()
  );

  const handleNumberingChange = (event: SelectChangeEvent) => {
    onSetNumbering(event.target.value as EntrancesNumbering);
    setNumberingWasClicked(true);
  };

  const handleNameChange = (
    i: number,
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    onSetName(i, event.target.value as string);
  };

  const refNumbering = useRef<HTMLSelectElement>();

  useEffect(() => {
    if (numberingWasClicked) {
      if (numbering === EntrancesNumbering.NAME && !names[0].trim()) {
        refEntranceName[0].current?.focus();
      }
    } else if (countWasClicked) {
      refNumbering.current?.focus();
    }
  });

  const handleSetCount = (n: number) => {
    onSetCount(n);
    setCountWasClicked(true);
  };

  const onPressEnter = (n: number) => {
    if (n < 9) {
      refEntranceName[n + 1].current?.focus();
    }
  };

  return (
    <div dir={dir()}>
      <Container>
        <Typography>{t(tKeys.addRole.entrances.count.label())}</Typography>

        <Grid container spacing={1}>
          {Array.from({ length: 10 }).map((v, i) => (
            <Grid item key={i}>
              <Button
                onClick={() => handleSetCount(i + 1)}
                variant={count > i ? 'contained' : 'outlined'}
              >
                {i + 1}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Container>

      {count > 1 && (
        <Container sx={{ paddingTop: '30px' }}>
          <Typography>
            {t(tKeys.addRole.entrances.numbering.label())}
          </Typography>
          <Select
            value={numbering}
            onChange={handleNumberingChange}
            inputRef={refNumbering}
          >
            <MenuItem style={{ direction: dir() }} value="1">
              1, 2{count > 2 ? ', 3' : ''}
            </MenuItem>
            <MenuItem style={{ direction: dir() }} value="A">
              {t(
                count === 2
                  ? tKeys.addRole.entrances.ab()
                  : count === 3
                  ? tKeys.addRole.entrances.abc()
                  : tKeys.addRole.entrances.abcEllipsis()
              )}
            </MenuItem>
            <MenuItem style={{ direction: dir() }} value="x">
              {t(tKeys.addRole.entrances.customNames())}
            </MenuItem>
          </Select>
        </Container>
      )}

      {count > 1 && numbering === 'x' && (
        <Box maxWidth="sm" sx={{ paddingTop: '30px' }}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {Array.from(Array(count).keys()).map((i) => (
                  <TableRow key={i}>
                    <TableCell>
                      {t(tKeys.addRole.entrances.entranceN(), {
                        num: i + 1,
                      })}
                    </TableCell>
                    <TableCell>
                      <TextField
                        inputRef={refEntranceName[i]}
                        variant="standard"
                        value={names[i] || ''}
                        placeholder={EntrancesAlphabetNames[i]}
                        onChange={(evt) => handleNameChange(i, evt)}
                        onKeyDown={onEnter(() => onPressEnter(i))}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </div>
  );
};
