import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, List } from '@mui/material';
import PaymentsIcon from '@mui/icons-material/ReceiptTwoTone';
import ChequesIcon from '@mui/icons-material/NoteTwoTone';
import AccountBalanceIcon from '@mui/icons-material/AccountBalanceTwoTone';
import { Item } from './Item';
import { useAppSelector } from 'app/hooks';
import {
  selectAptId,
  selectPropertyId,
  selectRole,
} from '../../features/auth/authSlice';
import { Subheader } from './Subheader';
import { tKeys } from 'translationKeys';
import { generateApartmentPath, Paths } from 'app/Paths';

export const MenuListTenant = () => {
  const { t } = useTranslation();
  const activeRole = useAppSelector(selectRole)!;
  const propId = useAppSelector(selectPropertyId)!;
  const aptId = useAppSelector(selectAptId)!;
  const prefix = (path: string) =>
    generateApartmentPath(path, { role: activeRole.role, propId, aptId });

  return (
    <>
      <Divider />
      <List>
        <Subheader text={t(tKeys.menu.header.tenant())} />

        <Item
          menuId="payments"
          Icon={PaymentsIcon}
          text={t(tKeys.menu.resident.payments())}
          to={prefix(Paths.apartment.payments)}
        />
        <Item
          menuId="cheques"
          Icon={ChequesIcon}
          text={t(tKeys.menu.resident.cheques())}
          to={prefix(Paths.apartment.cheques)}
        />
        <Item
          menuId="account-balance"
          Icon={AccountBalanceIcon}
          text={t(tKeys.menu.resident.accountBalance())}
          to={prefix(Paths.apartment.accountBalance)}
        />
      </List>
    </>
  );
};
