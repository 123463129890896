import { useTranslation } from 'react-i18next';
import { BankDetailsEmptyState } from './BankDetailsEmptyState';
import { Page } from '#common/Page/Page';
import { tKeys } from 'translationKeys';
export const BankDetailsEmptyPage = () => {
  const { t } = useTranslation();

  return (
    <Page title={t(tKeys.bankDetails.page.title())}>
      <BankDetailsEmptyState />
    </Page>
  );
};
