import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import { tKeys } from 'translationKeys';
import { EmptyState } from '#common/EmptyState';
import { useTranslation } from 'react-i18next';

export const DocumentsEmptyState = () => {
  const { t } = useTranslation();
  return (
    <EmptyState
      icon={DescriptionTwoToneIcon}
      tagline={t(tKeys.documents.page.emptyState.tagline())}
      description={t(tKeys.documents.page.emptyState.description())}
    />
  );
};
