import { useAppSelector, useCurrentRole } from 'app/hooks';
import { selectPropertyId } from 'features/auth/authSlice';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { api, Project, GetProjectsResponse } from 'api';
import { tokenManager } from 'features/token-manager';
import { keyProjectsList } from './keys';

export const useMutationNewProject = () => {
  const propId = useAppSelector(selectPropertyId)!;
  const queryClient = useQueryClient();

  return useMutation(
    ({
      name,
      description,
      amount,
      installments,
    }: {
      name: string;
      description: string;
      amount: number;
      installments: { amount: number; ym: number }[];
    }) => {
      return api.createProject({
        params: { propId },
        body: {
          name,
          description,
          amount,
          installments,
        },
        token: tokenManager.get(),
      });
    },
    {
      onSuccess() {
        return queryClient.invalidateQueries(keyProjectsList);
      },
    }
  );
};

export const useQueryProjects = () => {
  const propId = useAppSelector(selectPropertyId)!;
  return useQuery<GetProjectsResponse['projects']>(
    keyProjectsList,
    async () => {
      const result = await api.fetchProjects({
        params: { propId },
        token: tokenManager.get(),
      });
      return result.projects;
    }
  );
};

export const useQueryProject = (projectId: string) => {
  const propId = useAppSelector(selectPropertyId)!;
  return useQuery<Project | undefined>(['projects', projectId], async () => {
    const result = await api.fetchProject({
      params: { propId, projectId },
      token: tokenManager.get(),
    });
    return result.project;
  });
};

export const useMutationDeleteProject = () => {
  const { propertyId } = useCurrentRole();
  const queryClient = useQueryClient();

  return useMutation(
    ({ id }: { id: string }) => {
      return api.deleteProject({
        token: tokenManager.get(),
        params: { propId: propertyId, projectId: id },
      });
    },
    {
      async onSuccess() {
        await queryClient.invalidateQueries(keyProjectsList);
      },
    }
  );
};

export const useMutationActivteProject = () => {
  const { propertyId } = useCurrentRole();
  const queryClient = useQueryClient();

  return useMutation(
    ({ id }: { id: string }) => {
      return api.activateProject({
        token: tokenManager.get(),
        body: undefined,
        params: { propId: propertyId, projectId: id },
      });
    },
    {
      async onSuccess(_data, { id }) {
        await queryClient.invalidateQueries(keyProjectsList);
        await queryClient.invalidateQueries(['projects', id]);
      },
    }
  );
};

export const useMutationEditProject = (projectId: string) => {
  const { propertyId: propId } = useCurrentRole();
  const queryClient = useQueryClient();

  return useMutation(
    ({
      name,
      description,
      amount,
      installments,
    }: {
      name: string;
      description: string;
      amount: number;
      installments: { amount: number; ym: number }[];
    }) => {
      return api.updateProject({
        params: { propId, projectId },
        body: {
          name,
          description,
          amount,
          installments,
        },
        token: tokenManager.get(),
      });
    },
    {
      async onSuccess() {
        await queryClient.invalidateQueries(keyProjectsList);
        await queryClient.invalidateQueries(['projects', projectId]);
      },
    }
  );
};
