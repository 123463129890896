import React from 'react';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../translationKeys';
import { EmptyState } from '#common/EmptyState';
import MailTwoToneIcon from '@mui/icons-material/MailTwoTone';

export const EmptyInvites = () => {
  const { t } = useTranslation();

  return (
    <EmptyState
      tagline={t(tKeys.invitesPage.emptyState.tagline())}
      description={t(tKeys.invitesPage.emptyState.description())}
      icon={MailTwoToneIcon}
    />
  );
};
