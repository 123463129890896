import { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, CircularProgress, Container } from '@mui/material';
import { RtlTextField } from '#common/RtlTextField';
import { LanguageMenu } from '#common/AppBar/LanguageMenu';
import { FullPageRollingBg } from '#common/FullPageRollingBg/FullPageRollingBg';
import { isValidEmail } from 'features/utils';
import { Logo128 } from '#common/Logo128/Logo128';
import { tKeys } from 'translationKeys';
import styled from '@emotion/styled';
import { StyledLoginBox } from './StyledLoginBox';

const StyledCenter = styled.div`
  text-align: center;
`;

export const LoginBox = ({
  isLoading,
  isSent,
  performLogin,
}: {
  isLoading: boolean;
  isSent: boolean;
  performLogin: (email: string) => void;
}) => {
  const { t } = useTranslation();
  const refEmail = useRef<HTMLInputElement>();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    refEmail?.current?.focus();
  });

  const isError = (): boolean => {
    return (
      !!email && (!refEmail.current?.checkValidity() || !isValidEmail(email))
    );
  };

  const onClick = () => {
    if (!email || isError()) {
      refEmail.current!.focus();
      setError(true);
      return;
    }
    performLogin(email);
  };

  return (
    <FullPageRollingBg>
      <div style={{ height: '100%', position: 'relative' }}>
        <div
          style={{
            margin: 0,
            width: '100%',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          <Container maxWidth="sm">
            <Card>
              <Box paddingTop={'30px'}>
                <Logo128 />
              </Box>
              <StyledLoginBox>
                {isLoading ? (
                  <StyledCenter>
                    <CircularProgress data-testid="login-loader" />
                  </StyledCenter>
                ) : isSent ? (
                  <div style={{ textAlign: 'center' }}>
                    {t(tKeys.login.page.checkYourMailBox())}
                  </div>
                ) : (
                  <>
                    <div>
                      <RtlTextField
                        error={error}
                        inputRef={refEmail}
                        name="email"
                        type="email"
                        variant="standard"
                        fullWidth
                        placeholder="name@email.com"
                        label="email"
                        value={email}
                        onChange={(evt) => {
                          setEmail(evt.target.value);
                          setError(false);
                        }}
                        onKeyDown={(ev) => {
                          if (ev.key === 'Enter') {
                            ev.preventDefault();
                            onClick();
                          }
                        }}
                      />
                    </div>
                    <div
                      style={{
                        paddingTop: '30px',
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Button
                        variant="contained"
                        data-testid="login-button"
                        onClick={() => onClick()}
                      >
                        {t(tKeys.login.page.loginButton())}
                      </Button>
                      <LanguageMenu />
                    </div>
                  </>
                )}
              </StyledLoginBox>
            </Card>
          </Container>
        </div>
      </div>
    </FullPageRollingBg>
  );
};
