import { ProjectsEmptyStateVaad } from './ProjectsEmptyStateVaad';
import { useQueryProjects } from 'features/data/queries/projects';
import { Container } from '@mui/material';
import { generatePropertyPath, Paths } from 'app/Paths';
import { ProjectsEmptyState } from './ProjectsEmptyState';
import { ProjectsTable } from './ProjectsTable';
import { tKeys } from 'translationKeys';
import { useTranslation } from 'react-i18next';
import { TableActions } from '#common/index';
import { useCurrentRole } from 'app/hooks/useCurrentRole';
import { ProjectResponse } from 'api';
import { useNavigate } from 'react-router-dom';
import { ProjectPageLocationState } from './ProjectPageLocationState';
import { ContentOrEmptyOrLoader } from '#common/ContentOrEmptyOrLoader';

export const ProjectsList = () => {
  const { t } = useTranslation();
  const { isFetching, data: projects } = useQueryProjects();
  const { roleType, propertyId, isVaad } = useCurrentRole();
  const navigate = useNavigate();

  const onClickOnProject = (project: ProjectResponse) => {
    const { name, amount, status } = project;
    const locationState: ProjectPageLocationState = {
      project: { name, amount, status },
    };
    navigate(
      generatePropertyPath(Paths.property.projects.view, {
        role: roleType,
        propId: propertyId,
        projectId: project.id,
      }),
      { state: { ...locationState } }
    );
  };

  const toNewProject = () =>
    generatePropertyPath(Paths.property.projects.new, {
      role: roleType,
      propId: propertyId,
    });

  return (
    <ContentOrEmptyOrLoader
      isLoading={isFetching || !projects}
      isEmpty={!!projects && projects.length === 0}
      EmptyState={isVaad ? <ProjectsEmptyStateVaad /> : <ProjectsEmptyState />}
      Content={
        <Container maxWidth="sm">
          {isVaad && (
            <TableActions
              buttons={[
                {
                  to: toNewProject(),
                  caption: t(tKeys.projectsPage.tableActions.createProject()),
                },
              ]}
            />
          )}

          {projects && (
            <ProjectsTable
              projects={projects}
              onClickOnProject={onClickOnProject}
            />
          )}
        </Container>
      }
    />
  );
};
