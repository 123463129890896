import { useMutation, useQuery, useQueryClient } from 'react-query';
import { api, InvitesListResponse, RoleType } from 'api';
import { tokenManager } from '../../token-manager';
import { useAppDispatch, useCurrentRole } from 'app/hooks';
import { keySelfInvitesList, keyTenantData } from './keys';
import { checkTokenAsync } from '../../auth/authSlice';

export const useQuerySelfInvites = () => {
  return useQuery<InvitesListResponse>(keySelfInvitesList, async () => {
    return api.getSelfInvitesReceived({ token: tokenManager.get() });
  });
};

export const useMutationSendInvite = () => {
  const queryClient = useQueryClient();
  const { roleId, aptId } = useCurrentRole();

  return useMutation(
    ({
      name,
      email,
      lang,
      type,
    }: {
      name: string;
      email: string;
      lang: string;
      type: RoleType;
    }) => {
      return api.invite({
        params: { roleId },
        body: { name, email, lang, type, aptId },
        token: tokenManager.get(),
      });
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(keyTenantData(roleId));
      },
    }
  );
};

export const useMutationRevokeInvite = () => {
  const queryClient = useQueryClient();
  const { roleId } = useCurrentRole();

  return useMutation(
    ({ id, roleType }: { id: string; roleType: RoleType }) => {
      return api.revokeInvite({
        params: {
          roleId,
          inviteId: id,
          roleType,
        },
        token: tokenManager.get(),
      });
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(keyTenantData(roleId));
      },
    }
  );
};

export const useMutationDeclineInvite = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (inviteId: string) => {
      return api.declineInvite({
        params: {
          inviteId,
        },
        token: tokenManager.get(),
      });
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(keySelfInvitesList);
      },
    }
  );
};

export const useMutationAcceptInvite = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  return useMutation(
    (inviteId: string) => {
      return api.acceptInviteId({
        params: { inviteId },
        body: undefined,
        token: tokenManager.get(),
      });
    },
    {
      onSuccess: async () => {
        dispatch(checkTokenAsync());
        await queryClient.invalidateQueries(keySelfInvitesList);
      },
    }
  );
};
