import React from 'react';
import { ListSubheader } from '@mui/material';
import { useClasses } from '#common/styles-utils';

const styles = () => ({
  root: {
    textAlign: 'end',
    paddingInlineStart: '72px',
    paddingInlineEnd: '16px',
  },
});

export const Subheader = ({ text }: { text: string }) => {
  const classes = useClasses(styles);
  return (
    <ListSubheader inset classes={{ root: classes.root }}>
      {text}
    </ListSubheader>
  );
};
