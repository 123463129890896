import { useQuery } from 'react-query';
import { api, BalanceResponse } from 'api';
import { tokenManager } from '../../token-manager';
import { useCurrentRole } from 'app/hooks';
import { keyAptData } from './keys';

export const useQueryBalance = () => {
  const { roleId } = useCurrentRole();
  return useQuery<BalanceResponse>(['balance', 'role', roleId], () =>
    api.selfBalance({ token: tokenManager.get(), params: { roleId } })
  );
};

export const useQueryAptBalance = (propertyId: string, aptId: string) => {
  return useQuery<BalanceResponse>(keyAptData(aptId, 'balance'), () =>
    api.fetchAptBalance({
      token: tokenManager.get(),
      params: {
        propId: propertyId,
        aptId,
      },
    })
  );
};
