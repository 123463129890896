import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
} from '@mui/material';
import * as classes from './classes';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Gravatar } from '#common/Gravatar';
import { expiresInT } from '../../../app/constants';
import { RoleType } from '@vaad/client';
import { tKeys } from 'translationKeys';
import { useMutationRevokeInvite } from 'features/data/queries';

export const OwnerInvitePending = ({
  invite: { name, email, expires, id: inviteId },
}: {
  invite: { name: string; email: string; expires: number; id: string };
}) => {
  const { t } = useTranslation();
  const [revoking, setRevoking] = useState(false);
  const mutationRevokeInvite = useMutationRevokeInvite();

  const expiresIn = expiresInT(t);

  const onRevoke = () => {
    setRevoking(true);
    mutationRevokeInvite.mutate({ roleType: RoleType.TENANT, id: inviteId });
  };

  return (
    <Container maxWidth="md">
      <Card>
        <CardHeader title={t(tKeys.tenantPage.invite.pending.title())} />
        <CardContent>
          <Box className={classes.inviteRow}>
            <Gravatar email={email} />
            <div>
              {name} &lt;<a href={`mailto:${email}`}>{email}</a>&gt;
            </div>
            <div className={classes.expires}>
              {expires > 0 &&
                t(tKeys.invite.pending.expiresIn(), {
                  expiresIn: expiresIn(expires),
                })}
            </div>
          </Box>
        </CardContent>
        <CardActions className={classes.cardActionReverse}>
          {!revoking && (
            <Button onClick={onRevoke}>
              {t(tKeys.tenantPage.invite.revoke.button())}
            </Button>
          )}
          {revoking && <CircularProgress />}
        </CardActions>
      </Card>
    </Container>
  );
};
