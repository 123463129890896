import React from 'react';
import { PaymentsManageEmptyState } from './PaymentsManageEmptyState';
import { useQueryPayments } from 'features/data/queries';
import { Loader } from '#common/Loader';
import { useAppSelector, useNavigateToPropertyPath } from 'app/hooks';
import { selectRole } from 'features/auth/authSlice';
import { Paths } from 'app/Paths';
import { PaymentsManageTable } from './PaymentsManageTable';

export const PaymentsManageList = () => {
  const role = useAppSelector(selectRole)!;
  const goTo = useNavigateToPropertyPath();

  const { isFetching, data } = useQueryPayments();

  const onEmptyStateClick = () => {
    goTo(Paths.property.payments.new, {
      role: role.role,
      propId: role.propertyId,
    });
  };

  const isLoadedButEmpty = () => !isFetching && data && data.length === 0;
  const hasData = () => !isFetching && data && data.length > 0;

  return (
    <>
      {isFetching && <Loader size="big" />}

      {isLoadedButEmpty() && (
        <PaymentsManageEmptyState onClick={onEmptyStateClick} />
      )}

      {hasData() && (
        <PaymentsManageTable payments={data!} onCreate={onEmptyStateClick} />
      )}
    </>
  );
};
