import { useTranslation } from 'react-i18next';
import { Card, CardContent, Container } from '@mui/material';
import React from 'react';
import { Page } from '#common/Page/Page';
import { ChequesList } from './ChequesList';
import { ChequesEmptyState } from './ChequesEmptyState';
import { useQuerySelfCheques } from 'features/data/queries';
import { Loader } from '#common/Loader';
import { tKeys } from 'translationKeys';
export const ChequesPage = () => {
  const { t } = useTranslation();
  const { isFetching, data: cheques } = useQuerySelfCheques();

  if (!isFetching && cheques && cheques.length === 0) {
    return <ChequesEmptyState />;
  }

  return (
    <Page
      title={t(tKeys.cheques.page.title())}
      subtitle={t(tKeys.cheques.page.subtitle())}
    >
      {isFetching && <Loader size="big" />}

      {!isFetching && cheques && (
        <Container maxWidth="sm">
          <Card>
            <CardContent>
              <ChequesList cheques={cheques} />
            </CardContent>
          </Card>
        </Container>
      )}
    </Page>
  );
};
