import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { Loader } from '#common/Loader';
import {
  flattenOwnersData,
  useQueryBudget,
  useQueryOwners,
} from 'features/data/queries';
import { useCurrentRole } from 'app/hooks';
import { numberOfApartments } from '../../../features/property';
import { tKeys } from '../../../translationKeys';
import { useTranslation } from 'react-i18next';
import { useFormat } from '../../../i18n';

export const DebtorsWidget = () => {
  const { t } = useTranslation();
  const { formatCurrency } = useFormat();
  const { propertyId, property } = useCurrentRole();
  const { isLoading: isLoadingOwners, data: ownersData } =
    useQueryOwners(propertyId);
  const flatData = ownersData ? flattenOwnersData(ownersData) : [];
  const { isLoading: isLoadingBudget, data: budget } = useQueryBudget();

  const isLoading = isLoadingOwners || isLoadingBudget;

  const numberOfDebtors = flatData.reduce(
    (acc, current) => (current.balance.budget < 0 ? acc + 1 : acc),
    0
  );

  const totalDebt = flatData.reduce(
    (acc, current) =>
      current.balance.budget < 0 ? acc - current.balance.budget : acc,
    0
  );

  return (
    <Grid container spacing={'1rem'}>
      <Grid item xs>
        <Card>
          <CardHeader
            title={t(tKeys.viewBudget.debtors())}
            titleTypographyProps={{ variant: 'subtitle1' }}
          />
          <CardContent sx={{ paddingBlockStart: 0 }}>
            <Typography variant={'h4'} align="center">
              {isLoading ? <Loader size="small" /> : numberOfDebtors}
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs>
        <Card>
          <CardHeader
            title={t(tKeys.viewBudget.totalDebt())}
            titleTypographyProps={{ variant: 'subtitle1' }}
          />
          <CardContent sx={{ paddingBlockStart: 0 }}>
            <Typography variant={'h4'} align="center">
              {isLoading ? <Loader size="small" /> : formatCurrency(totalDebt)}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs>
        <Card>
          <CardHeader
            title={t(tKeys.viewBudget.totalMonthsDue())}
            titleTypographyProps={{ variant: 'subtitle1' }}
          />
          <CardContent sx={{ paddingBlockStart: 0 }}>
            <Typography variant={'h4'} align="center">
              {isLoading || !budget ? (
                <Loader size="small" />
              ) : (
                Math.round(
                  (10 * totalDebt) /
                    budget.collection /
                    numberOfApartments(property)
                ) / 10
              )}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
