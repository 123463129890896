import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentRole } from 'app/hooks';
import { Page } from '#common/Page/Page';
import { OwnersListAdmin } from './OwnersListAdmin';
import { OwnersPageEmptyState } from './OwnersPageEmptyState';
import { tKeys } from 'translationKeys';
import { useLoadOwnersData } from './useLoadOwnersData';
import { Loader } from '#common/Loader';
import { OwnersListRead } from './OwnersListRead';
import { TableActions } from '#common/TableActions/TableActions';
import { Container } from '@mui/material';
import { OwnersBreadcrumbs } from './OwnersBreadcrumbs';

export const OwnersPage = () => {
  const { t } = useTranslation();

  const { property } = useCurrentRole();
  const [creating, setCreating] = useState(false);
  const {
    isLoading,
    ownersData,
    setOwnersData,
    modifyOwnersData,
    revokeOwnerInvite,
    declareOwner,
    isEmpty,
  } = useLoadOwnersData();

  const onClickEmptyState = () => {
    setCreating(true);
  };

  const onRevokeInvite = ({
    entrance,
    iApt,
    inviteId,
  }: {
    entrance: number;
    iApt: number;
    inviteId: string;
  }) => {
    const newOwnersData = {
      ...ownersData,
    };

    if (newOwnersData[entrance]) {
      delete newOwnersData[entrance][iApt];
    }
    setOwnersData(newOwnersData);
    revokeOwnerInvite.mutate(inviteId);
  };

  const onSave = ({
    entrance,
    aptNum,
    fullName,
    email,
  }: {
    entrance: number;
    aptNum: number;
    fullName: string;
    email: string;
  }) => {
    modifyOwnersData(entrance, aptNum, {
      email,
      fullName,
      emailHash: undefined,
      invite: {
        expires: 0,
        id: '',
      },
    });

    declareOwner({ entrance, aptNum, fullName, email });
  };

  return (
    <>
      <OwnersBreadcrumbs />
      <Page title={t(tKeys.ownersPage.title())}>
        {isLoading && <Loader size="big" />}

        {!isLoading && (
          <>
            {!creating && isEmpty && (
              <OwnersPageEmptyState onClickEmptyState={onClickEmptyState} />
            )}
            {!creating && !isEmpty && (
              <Container maxWidth="md">
                <TableActions
                  buttons={[
                    {
                      caption: t(tKeys.actions.edit()),
                      onClick: () => setCreating(true),
                    },
                  ]}
                />
                <OwnersListRead
                  property={property}
                  ownersData={ownersData ?? {}}
                />
              </Container>
            )}
            {creating && (
              <OwnersListAdmin
                property={property}
                ownersData={ownersData ?? {}}
                onSave={onSave}
                onRevokeInvite={onRevokeInvite}
              />
            )}
          </>
        )}
      </Page>
    </>
  );
};
