import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { tKeys } from 'translationKeys';
import { entranceName } from 'features/utils';
import { EntrancesNumbering } from 'api';

type Props = {
  count: number;
  numbering: EntrancesNumbering;
  names: string[];
  apts: number[];
  onSetApts(entrance: number, apts: number): void;
};

export const StepApartments = ({
  count,
  numbering,
  names,
  apts,
  onSetApts,
}: Props) => {
  const { t } = useTranslation();

  const handleChange = (
    i: number,
    evt: React.ChangeEvent<{ value: unknown }>
  ) => {
    const numberOfApts = parseInt(evt.target.value as string);
    if (numberOfApts >= 2) {
      onSetApts(i, numberOfApts);
    }
  };

  return (
    <Box sx={{ maxWidth: 'sm' }}>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {Array.from(Array(count).keys()).map((i) => (
              <TableRow key={i}>
                {count > 1 && (
                  <TableCell>
                    {t(tKeys.addRole.apartments.entranceN(), {
                      name: entranceName(t)(
                        { numbering, entranceNames: names },
                        i + 1
                      ),
                    })}
                  </TableCell>
                )}
                <TableCell>
                  <TextField
                    style={{ width: '4rem' }}
                    type="number"
                    size="small"
                    value={apts[i]}
                    onChange={(evt) => handleChange(i, evt)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
