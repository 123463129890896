import { InvitesListResponse } from 'api';
import React, { FC, useState } from 'react';
import {
  Button,
  Card,
  Container,
  CardActions,
  CardContent,
  CardMedia,
  Box,
  CircularProgress,
  Collapse,
  Typography,
} from '@mui/material';
import { RoleIcon } from '#common/RoleIcon/RoleIcon';
import { RoleType } from '@vaad/client';
import { tKeys } from '../../../translationKeys';
import { PropertyAddress } from '#common/PropertyAddress';
import { useTranslation } from 'react-i18next';

type Action = 'accept' | 'decline';

const InviteCard: FC<{
  invite: InvitesListResponse[number];
  onDecline(): void;
  onAccept(): void;
}> = ({ invite, onDecline, onAccept }) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [isCollapsing, setCollapsing] = useState(false);
  const [action, setAction] = useState('accept' as Action);

  return (
    <Collapse
      orientation="vertical"
      in={!isCollapsing}
      onExited={() => {
        (action === 'decline' ? onDecline : onAccept)();
      }}
    >
      <Box paddingBottom="20px">
        <Card>
          <CardContent>
            <CardMedia>
              <RoleIcon roleType={invite.invite.role as RoleType} />
            </CardMedia>
            <PropertyAddress
              property={invite.property}
              apartment={invite.apartment}
            />
            <Typography>
              {t(tKeys.invite.invitedBy(), {
                invitorName: invite.invitor.name,
              })}
            </Typography>
          </CardContent>
          <CardActions style={{ justifyContent: 'end' }}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setLoading(true);
                    setAction('decline');
                    setTimeout(() => setCollapsing(true), 300);
                  }}
                >
                  {t(tKeys.actions.decline())}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    setLoading(true);
                    setTimeout(() => setCollapsing(true), 300);
                  }}
                >
                  {t(tKeys.actions.accept())}
                </Button>
              </>
            )}
          </CardActions>
        </Card>
      </Box>
    </Collapse>
  );
};

export const InvitesList: FC<{
  invites: InvitesListResponse;
  onDecline(_: string): void;
  onAccept(_: string): void;
}> = ({ invites, onDecline, onAccept }) => {
  return (
    <Container maxWidth="sm">
      {invites.map((invite) => (
        <InviteCard
          key={invite.invite.id}
          invite={invite}
          onDecline={() => onDecline(invite.invite.id)}
          onAccept={() => onAccept(invite.invite.id)}
        />
      ))}
    </Container>
  );
};
