import { useAppSelector } from 'app/hooks';
import { selectPropertyId } from 'features/auth/authSlice';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { api, GetBuildingPaymentsResponse } from 'api';
import { tokenManager } from 'features/token-manager';
import { keyAptData, keyOwners, keyPaymentsList } from './keys';
import type { Payment, PaymentDispatch } from 'api';

const keyAptList = (aptId: string) => keyAptData(aptId, 'payments', 'list');

export const useQueryPayments = () => {
  const propertyId = useAppSelector(selectPropertyId)!;
  return useQuery<GetBuildingPaymentsResponse['payments']>(
    keyPaymentsList,
    async () => {
      const result = await api.getBuildingPayments({
        params: { propId: propertyId },
        token: tokenManager.get(),
      });
      return result.payments;
    }
  );
};

export const useMutationNewPayment = () => {
  const propertyId = useAppSelector(selectPropertyId)!;
  const queryClient = useQueryClient();
  return useMutation(
    ({
      aptId,
      date,
      amount,
      dispatch,
    }: {
      aptId: string;
      date: string;
      amount: number;
      dispatch: PaymentDispatch;
    }) => {
      return api.savePayment({
        token: tokenManager.get(),
        params: { propId: propertyId, aptId },
        body: { date, amount, dispatch },
      });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(keyPaymentsList);
        await queryClient.invalidateQueries(keyOwners());
      },
    }
  );
};

export const useQueryAptPayments = (aptId: string) => {
  const propertyId = useAppSelector(selectPropertyId)!;
  return useQuery<Payment[]>(keyAptList(aptId), () =>
    api
      .fetchAptPayments({
        params: { propId: propertyId, aptId },
        token: tokenManager.get(),
      })
      .then((response) => response.payments)
  );
};
