import { Paths } from 'app/Paths';
import { useTranslation } from 'react-i18next';
import { relativize, useNestedRoutes } from 'features/utils';
import { tKeys } from 'translationKeys';
import { Page } from '#common/Page/Page';
import { CreateCheque } from './CreateCheque';
import { ChequesManageList } from './ChequesManageList';

const root = Paths.property.cheques.list;

export const ChequesPageTitles = () => {
  const { t } = useTranslation();
  return useNestedRoutes(root, [
    {
      index: true,
      element: t(tKeys.cheques.page.title()),
    },
    {
      path: Paths.property.cheques.new,
      element: t(tKeys.cheques.managePage.newCheque.title()),
    },
  ]);
};

export const ChequesRoutes = () => {
  return useNestedRoutes(root, [
    {
      index: true,
      element: <ChequesManageList />,
    },
    {
      path: relativize(root)(Paths.property.cheques.new),
      element: <CreateCheque />,
    },
  ]);
};

export const ChequesVaadPage = () => {
  return (
    <Page title={<ChequesPageTitles />}>
      <ChequesRoutes />
    </Page>
  );
};
