import { useBreadcrumbsForApartment } from '#common/Breadcrumbs/useBreadcrumbsForApartment';
import { Breadcrumbs } from '#common/Breadcrumbs/Breadcrumbs';
import React from 'react';
import { useUrlParser } from 'features/urlParser';
import { useEntranceForAptId } from 'app/hooks/useEntranceForAptId';
import { Vignette } from '#common/Vignette';
import { useClasses } from '#common/styles-utils';
import styled from '@emotion/styled';
import { Container } from '@mui/material';
import { Link } from 'react-router-dom';
import { generateApartmentPath, Paths } from '../../app/Paths';
import { useCurrentRole } from '../../app/hooks';

const styles = {
  vignette: {
    height: '130px',
    width: '130px',
    margin: '10px',
    overflow: 'hidden',
  },
};

const StyledContainer = styled(Container)`
  display: flex !important;
  flex-wrap: wrap;
  padding-top: 0;
  max-width: 100% !important;
`;

export const ApartmentIndexPage = () => {
  const { aptId } = useUrlParser();
  const { roleType, propertyId } = useCurrentRole();
  const classes = useClasses(styles);

  const { entranceNum, aptNum } = useEntranceForAptId(aptId);

  const breadcrumbsForApartment = useBreadcrumbsForApartment({
    entranceNum,
    aptNum,
  });

  const pathProps = { aptId: aptId!, role: roleType, propId: propertyId };

  return (
    <>
      <Breadcrumbs crumbs={breadcrumbsForApartment} />

      <StyledContainer>
        <Link to={generateApartmentPath(Paths.apartment.cheques, pathProps)}>
          <Vignette
            title={'cheques'}
            elevateOnHover
            className={classes.vignette}
          />
        </Link>

        <Link to={generateApartmentPath(Paths.apartment.payments, pathProps)}>
          <Vignette
            title={'payments'}
            elevateOnHover
            className={classes.vignette}
          />
        </Link>

        <Link
          to={generateApartmentPath(Paths.apartment.accountBalance, pathProps)}
        >
          <Vignette
            title={'solde'}
            elevateOnHover
            className={classes.vignette}
          />
        </Link>
      </StyledContainer>
    </>
  );
};
