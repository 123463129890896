import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { Box, Button, CardActions } from '@mui/material';
import { RtlTextField } from '#common/RtlTextField';
import { LanguageMenu } from '#common/AppBar/LanguageMenu';
import { Gravatar } from '#common/Gravatar';
import { onEnter } from '#common/keyboard-utils';
import { tKeys } from 'translationKeys';
export const SignupForm = (props: {
  emailHash: string;
  onAccept: (name: string) => void;
}) => {
  const {
    t,
    i18n: { dir },
  } = useTranslation();
  const direction = dir();
  const refName = useRef<HTMLInputElement>();
  const [name, setName] = useState('');

  useEffect(() => {
    if (!name) {
      refName.current?.focus();
    }
  }, [name]);

  const onAccept = () => {
    if (!name || !name.trim()) {
      refName.current?.focus();
    } else {
      props.onAccept(name);
    }
  };

  const onChangeName = (val: string) => setName(val);

  return (
    <div
      style={{
        direction,
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <Gravatar hash={props.emailHash} defaultType="silhouette" />
          <RtlTextField
            inputProps={{ 'data-testid': 'input-fullname' }}
            inputRef={refName}
            label={t(tKeys.signupPage.fullName.label())}
            variant="standard"
            value={name}
            onChange={(evt) => onChangeName(evt.currentTarget.value)}
            onKeyDown={onEnter(onAccept)}
          />
        </Box>
      </Box>

      <CardActions
        style={{
          marginTop: 20,
          flexDirection: 'row-reverse',
          justifyContent: 'space-between',
        }}
      >
        <Button
          variant="contained"
          onClick={onAccept}
          data-testid="button-enter"
        >
          {t(tKeys.signupPage.enterButton())}
        </Button>
        <LanguageMenu />
      </CardActions>
    </div>
  );
};
