import { tKeys } from 'translationKeys';
import { useUrlParser } from 'features/urlParser';
import { useQueryAptPayments } from 'features/data/queries';
import React from 'react';
import { PaymentsTable } from './PaymentsTable';
import { AptPage } from '#common/AptPage/AptPage';
import { ContentOrEmptyOrLoader } from '#common/ContentOrEmptyOrLoader';
import { EmptyState } from '#common/EmptyState';
import { ReactComponent as Money } from './Money.svg';
import { makeIcon } from '#common/icon-utils';
import { generatePropertyPath, Paths } from 'app/Paths';
import { useCurrentRole } from 'app/hooks';
import { useNavigate } from 'react-router-dom';

const EmptyList = () => {
  const navigate = useNavigate();
  const { roleType, propertyId } = useCurrentRole();
  const { aptId } = useUrlParser();

  const onAddPayment = () => {
    navigate({
      pathname: generatePropertyPath(Paths.property.payments.new, {
        propId: propertyId,
        role: roleType,
      }),
      search: new URLSearchParams({
        aptId: aptId!,
      }).toString(),
    });
  };

  return (
    <EmptyState
      tagline={'Tagline'}
      description={'Description'}
      icon={makeIcon(Money)}
      button={'shoot'}
      onClick={onAddPayment}
    />
  );
};

export const AptPaymentsPage = () => {
  const { aptId } = useUrlParser();

  const { isFetching: isFetchingPayments, data: payments } =
    useQueryAptPayments(aptId!);

  return (
    <AptPage lastCrumbKey={tKeys.menu.vaad.payments()}>
      <ContentOrEmptyOrLoader
        isLoading={isFetchingPayments || !payments}
        isEmpty={!isFetchingPayments && !!payments && payments.length === 0}
        EmptyState={<EmptyList />}
        Content={<PaymentsTable payments={payments!} />}
      />
    </AptPage>
  );
};
