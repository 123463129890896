import { AppBar } from '#common/AppBar';
import { Box } from '@mui/material';
import { cx } from '@emotion/css';
import { RoleSelector } from './RoleSelector/RoleSelector';
import React, { useEffect, useState } from 'react';
import { useRoleBackgroundClasses } from '#common/styles-utils';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'app/hooks';
import { selectRoles } from 'features/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import { FullPageLoader } from '#common/FullPageLoader';
import { tKeys } from 'translationKeys';
import { useQuerySelfInvites } from 'features/data/queries';

export const RolesFullPage = () => {
  const styledClasses = useRoleBackgroundClasses();
  const { t } = useTranslation();
  const roles = useAppSelector(selectRoles);
  const { data: myInvites } = useQuerySelfInvites();
  const [fetchInvitesSent, setFetchInvitesSent] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (roles.length === 0) {
      if (myInvites && myInvites.length > 0) {
        navigate('/my-invites');
      }
    }
  }, [roles, fetchInvitesSent, setFetchInvitesSent, myInvites]);

  const isLoading =
    roles.length === 0 && (myInvites === null || (myInvites?.length ?? 0) > 0);

  return (
    <>
      <AppBar
        fullWidth
        noAddress
        role={null}
        title={isLoading ? '' : t(tKeys.appBar.roles())}
      />
      {isLoading ? (
        <FullPageLoader />
      ) : (
        <Box className={cx(styledClasses.contentBox, styledClasses.page)}>
          <RoleSelector />
        </Box>
      )}
    </>
  );
};
