import { Budget, BalanceResponse } from 'api';

export const budgetAngle = ({
  budget,
  balance,
}: {
  budget?: Budget;
  balance?: BalanceResponse;
}) =>
  budget && budget.collection && balance
    ? (balance.budget / (3 * budget.collection)) * 90
    : 0;

export const projectAngle = (balance?: BalanceResponse) =>
  Math.floor(((balance?.project || 0) / 1000) * 90);
