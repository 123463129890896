import { useMutation, useQuery, useQueryClient } from 'react-query';
import { api, OwnersResponse, RoleType } from 'api';
import { tokenManager } from '../../token-manager';
import { useCurrentLanguage } from 'app/hooks';
import { keyOwners } from './keys';

export type EntranceNumber = number;
export type AptNumber = number;

export interface OwnersData {
  [entrance: EntranceNumber]: {
    [apt: AptNumber]: Pick<
      OwnersResponse[number],
      'aptId' | 'email' | 'emailHash' | 'invite' | 'balance'
    > & {
      fullName: string;
    };
  };
}

export const ownersResponseToOwnersData = (
  data: OwnersResponse
): OwnersData => {
  const result = {} as OwnersData;
  data.forEach((owner) => {
    const entrance = owner.entrance || 1;
    if (!result[entrance]) {
      result[entrance] = {};
    }
    result[entrance][owner.apt] = {
      ...owner,
      fullName: owner.name,
    };
  });

  return result;
};

export const useQueryOwners = (propId: string) => {
  return useQuery<OwnersData>(keyOwners(propId), async () => {
    const result = await api.getOwners({
      params: {
        propId,
      },
      token: tokenManager.get(),
    });

    return ownersResponseToOwnersData(result);
  });
};

export const useMutationRevokeOwnerInvite = (roleId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (inviteId: string) => {
      return api.revokeInvite({
        token: tokenManager.get(),
        params: {
          roleId,
          roleType: RoleType.OWNER,
          inviteId,
        },
      });
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(keyOwners());
      },
    }
  );
};

export const useMutationDeclareOwner = (roleId: string) => {
  const lang = useCurrentLanguage();
  const queryClient = useQueryClient();

  return useMutation(
    ({
      entrance,
      aptNum,
      fullName,
      email,
    }: {
      entrance: number;
      aptNum: number;
      fullName: string;
      email: string;
    }) => {
      return api.declareOwner({
        token: tokenManager.get(),
        params: {
          roleId,
        },
        body: {
          lang,
          entrance,
          apt: aptNum,
          email,
          name: fullName,
        },
      });
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(keyOwners());
      },
    }
  );
};

export type FlatOwner = {
  entrance: EntranceNumber;
  aptNum: AptNumber;
} & OwnersData[EntranceNumber][AptNumber];

export const flattenOwnersData: (ownersData: OwnersData) => FlatOwner[] = (
  ownersData
) => {
  const result: FlatOwner[] = [];
  Object.entries(ownersData).forEach(([entrance, entranceData]) => {
    Object.entries(entranceData).forEach(([aptNum, ownerData]) => {
      result.push({
        entrance: entrance as unknown as FlatOwner['entrance'],
        aptNum: aptNum as unknown as FlatOwner['aptNum'],
        ...(ownerData as OwnersData[EntranceNumber][AptNumber]),
      });
    });
  });
  return result;
};
