import { CircularProgress } from '@mui/material';
import React from 'react';

type Props = {
  size: 'big' | 'small';
};

export const Loader = ({ size }: Props) => (
  <div style={{ textAlign: 'center' }}>
    <CircularProgress size={size === 'big' ? 100 : 24} />
  </div>
);
