import { css } from '@emotion/css';

export const blur = css`
  z-index: -1;
  backdrop-filter: blur(1px);
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
`;

export const background = css`
  z-index: -1;
  top: 0;
  left: 0;
  position: absolute;
  padding: 0;
  margin: 0;
  background-color: #f4f4f4;
  width: 100vw;
  height: 100vh;
  background-size: cover;
`;
