import { FC } from 'react';
import { useNestedRoutes } from 'features/utils';
import { Breadcrumbs } from '#common/Breadcrumbs/Breadcrumbs';
import { FlowItem } from '#common/Breadcrumbs/FlowItem';

function crumbIsJSX(crumb: FlowItem['crumbs'][number]): crumb is JSX.Element {
  return typeof crumb === 'object' && !Object.hasOwn(crumb, 'title');
}

export const GenericBreadcrumbs: FC<{ root: string; items: FlowItem[] }> = ({
  root,
  items,
}) =>
  useNestedRoutes(
    root,
    items.map((item) => ({
      index: item.index,
      path: item.path,
      element: (
        <Breadcrumbs
          crumbs={item.crumbs.map((crumb) => {
            if (crumbIsJSX(crumb) || typeof crumb === 'string') {
              return { title: crumb };
            }
            return crumb;
          })}
        />
      ),
    }))
  );
