import React, { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs } from '#common/Breadcrumbs/Breadcrumbs';
import { useUrlParser } from 'features/urlParser';
import { useBreadcrumbsForApartment } from '#common/Breadcrumbs/useBreadcrumbsForApartment';
import { useEntranceForAptId } from 'app/hooks/useEntranceForAptId';
import { Loader } from '#common/Loader';
import { Container } from '@mui/material';

interface Props {
  lastCrumbKey: string;
}

export const AptPage: FC<Props & PropsWithChildren> = ({
  lastCrumbKey,
  children,
}) => {
  const { t } = useTranslation();
  const { aptId } = useUrlParser();

  const {
    isFetching: isFetchingProp,
    entranceNum,
    aptNum,
  } = useEntranceForAptId(aptId);

  const breadcrumbsForApartment = useBreadcrumbsForApartment({
    entranceNum,
    aptNum,
    aptId,
  });

  if (isFetchingProp) {
    return <Loader size="big" />;
  }

  return (
    <>
      <Breadcrumbs
        crumbs={[...breadcrumbsForApartment, { title: t(lastCrumbKey) }]}
      />
      <Container>{children}</Container>
    </>
  );
};
