import { css } from '@emotion/css';

export const paper = css`
  padding: 16px;
  display: flex;
  overflow: auto;
  flex-direction: column;
  transition: transform 200ms;

  &.moveY {
    transform: translateY(-4px);
  }
`;

export const cardContent = css`
  flex: 1;
`;

export const title = css`
  text-align: start;
`;

export const subtitle = css`
  text-align: end !important;
  font-size: 0.75rem !important;
`;
