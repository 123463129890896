import { Theme } from '@mui/material';

export const styles =
  ({ drawerWidth }: { drawerWidth: number }) =>
  (theme: Theme) => ({
    toolbar: {
      paddingInlineEnd: 20, // keep right padding when drawer closed
    },
    appBar: {
      left: 20,
      width: 'calc(100% - 40px)',
      marginTop: 10,
      borderRadius: 30,
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      position: 'fixed',
    },
    appBarShift: {
      paddingInlineStart: 10,
      marginInlineStart: `${drawerWidth}px`,
      width: `calc(100% - ${drawerWidth + 40}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginInlineEnd: 36,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
    },
  });
