import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, List } from '@mui/material';
import ChequesIcon from '@mui/icons-material/NoteTwoTone';
import { Item } from './Item';
import { Subheader } from './Subheader';
import PaymentsIcon from '@mui/icons-material/ReceiptTwoTone';
import { tKeys } from 'translationKeys';
import { Paths, usePropertyPath } from 'app/Paths';

export const MenuListVaadMoney = () => {
  const { t } = useTranslation();
  const { generatePath } = usePropertyPath();

  return (
    <>
      <Divider />
      <List>
        <Subheader text={t(tKeys.menu.header.vaadMoney())} />

        <Item
          domain="prop"
          menuId="cheques"
          Icon={ChequesIcon}
          text={t(tKeys.menu.vaad.cheques())}
          to={generatePath(Paths.property.cheques.list)}
        />
        <Item
          domain="prop"
          menuId="payments"
          Icon={PaymentsIcon}
          text={t(tKeys.menu.vaad.payments())}
          to={generatePath(Paths.property.payments.list)}
        />
      </List>
    </>
  );
};
