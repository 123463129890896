import { RoleType } from 'api';
import { prefixForRole } from './constants';
import { generatePath } from 'react-router-dom';
import { useAppSelector } from './hooks';
import { selectPropertyId, selectRole } from 'features/auth/authSlice';

const propertyRoot = '/:roleLetter/prop/:propId';
const apartmentRoot = `${propertyRoot}/apt/:aptId`;

export const Paths = {
  apartment: {
    index: apartmentRoot,
    accountBalance: `${apartmentRoot}/account-balance`,
    cheques: `${apartmentRoot}/cheques`,
    payments: `${apartmentRoot}/payments`,
    tenant: `${apartmentRoot}/tenant`,
  },
  addRole: '/add-role',
  inviteLanding: '/invit/:signinCode',
  login: '/login',
  myInvites: '/my-invites',
  notifications: '/notifications',
  property: {
    index: propertyRoot,
    bank: `${propertyRoot}/bank`,
    budget: `${propertyRoot}/budget`,
    cheques: {
      list: `${propertyRoot}/cheques`,
      new: `${propertyRoot}/cheques/new`,
    },
    documents: `${propertyRoot}/documents`,
    owners: `${propertyRoot}/owners`,
    payments: {
      list: `${propertyRoot}/payments`,
      new: `${propertyRoot}/payments/new`,
    },
    pricing: `${propertyRoot}/pricing`,
    projects: {
      list: `${propertyRoot}/projects`,
      new: `${propertyRoot}/projects/new`,
      view: `${propertyRoot}/projects/:projectId`,
      edit: `${propertyRoot}/projects/:projectId/edit`,
    },
    surveys: `${propertyRoot}/surveys`,
    vaad: `${propertyRoot}/vaad`,
  },
  roles: '/roles',
  signin: '/sign-in/:signinCode',
  signup: '/sign-up/:signinCode/:emailHash',
};

export const generateApartmentPath = (
  path: string,
  {
    role,
    propId,
    aptId,
  }: { role: string | RoleType; propId: string; aptId: string }
) =>
  generatePath(path, {
    roleLetter: prefixForRole(role as RoleType),
    propId,
    aptId,
  });

export const generatePropertyPath = (
  path: string,
  {
    role,
    propId,
    ...rest
  }: { role: string | RoleType; propId: string } & Record<string, string>
) =>
  generatePath(path, {
    roleLetter: prefixForRole(role as RoleType),
    propId,
    ...rest,
  });

export const usePropertyPath = () => {
  const activeRole = useAppSelector(selectRole)!;
  const propId = useAppSelector(selectPropertyId)!;
  return {
    generatePath: (path: string) =>
      generatePropertyPath(path, { role: activeRole.role, propId }),
  };
};
