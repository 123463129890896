import { generatePropertyPath, Paths } from 'app/Paths';
import { useAppSelector } from 'app/hooks';
import { selectRole } from 'features/auth/authSlice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { MutableRefObject, ReactNode, useState } from 'react';
import { toastSuccess } from '#common/toasts';
import { useMutationNewCheque } from 'features/data/queries';

const makeDateString = (date: Date) =>
  `${date.getFullYear()}${String(100 + (date.getMonth() + 1)).substring(1)}`;

const parseDateString: (str: string | null) => Date | undefined = (str) =>
  str
    ? new Date(`${str.substring(0, 4)}-${str.substring(4, 6)}-01`)
    : undefined;

const nextMonth = (date: string) => {
  const newDate = parseDateString(date)!;
  newDate.setMonth(newDate.getMonth() + 1);
  return newDate;
};

export const useCreateCheque = (
  toastMsg: (chequeNo: string) => ReactNode,
  chequeNoRef: MutableRefObject<HTMLInputElement | undefined>
) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const role = useAppSelector(selectRole)!;
  const [aptId, setAptId] = useState(searchParams.get('aptId') ?? '');
  const [amount, setAmount] = useState(
    parseFloat(searchParams.get('amount') ?? '0')
  );
  const this_month = new Date(Date.now()).getMonth() + 1;
  const this_year = new Date(Date.now()).getFullYear();
  const minDate = new Date(`${this_year - 7}-01-01`);
  const maxDate = new Date(`${this_year + 8}-12-31`);

  const [date, setDate] = useState(
    parseDateString(searchParams.get('date')) ??
      new Date(`${this_year}-${this_month}-01`)
  );
  const [chequeNo, setChequeNo] = useState('');
  const mutationNewCheque = useMutationNewCheque();

  const onChangeDate = (val: Date | null) => {
    if (!val || !('getFullYear' in val)) {
      return;
    }
    setDate(new Date(`${val.getFullYear()}-${val.getMonth() + 1}-01`));
  };

  const onChangeApt = (val: string) => {
    setAptId(val);
  };

  const onCancel = () => {
    navigate(
      generatePropertyPath(Paths.property.cheques.list, {
        role: role.role,
        propId: role.propertyId,
      })
    );
  };

  const canSave = !(!amount || !chequeNo || !aptId);

  const onCreateCheque = ({
    date,
    aptId,
    amount,
    chequeNo,
  }: {
    date: string;
    aptId: string;
    amount: number;
    chequeNo: string;
  }) => {
    mutationNewCheque.mutate(
      {
        date: parseInt(date),
        aptId,
        amount,
        chequeNo,
        payer: '',
      },
      {
        onSuccess() {
          toastSuccess(toastMsg(chequeNo));
          setChequeNo('');
          chequeNoRef?.current?.focus();
          const newDate = nextMonth(date);
          setDate(newDate);
        },
      }
    );
  };

  const onSave = () => {
    if (!canSave) {
      return;
    }

    onCreateCheque({
      date: makeDateString(date),
      aptId,
      amount,
      chequeNo,
    });
  };

  const onChangeAmount = (val: number) => {
    setAmount(val);
  };

  const onChangeChequeNo = (val: string) => {
    setChequeNo(val);
  };

  return {
    aptId,
    onCancel,
    onChangeApt,
    onChangeAmount,
    onChangeChequeNo,
    onChangeDate,
    date,
    onSave,
    canSave,
    chequeNo,
    amount,
    minDate,
    maxDate,
  };
};
