import { Button } from '@mui/material';
import { tKeys } from 'translationKeys';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { ActionsBar } from '#common/ActionsBar';

interface Props {
  canSave: boolean;
  onSave(): void;
  onCancel(): void;
}

export const SaveActionBar: FC<Props> = ({ onSave, canSave, onCancel }) => {
  const { t } = useTranslation();

  return (
    <ActionsBar>
      <Button
        data-testid="actionbar-save-button"
        variant="contained"
        onClick={onSave}
        disabled={!canSave}
      >
        {t(tKeys.actions.save())}
      </Button>

      <Button
        data-testid="actionbar-cancel-button"
        variant="outlined"
        onClick={onCancel}
      >
        {t(tKeys.actions.cancel())}
      </Button>
    </ActionsBar>
  );
};
