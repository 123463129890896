import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as classes from './classes';
import React, { useState } from 'react';
import { Gauge } from '#common/Gauge/Gauge';
import { useFormat } from '../../../i18n';
import { Gravatar } from '#common/Gravatar/Gravatar';
import { tKeys } from 'translationKeys';
import {
  TenantInfo,
  useQueryBalance,
  useQueryBudget,
} from 'features/data/queries';
import { budgetAngle } from '../BalancePage/balance-angles';
export const ShowTenantInfo = ({
  tenant,
  onRevoke,
}: {
  tenant: TenantInfo;
  onRevoke: () => void;
}) => {
  const { t } = useTranslation();
  const { formatCurrency } = useFormat();
  const [revoking, setRevoking] = useState(false);
  const { data: balance } = useQueryBalance();
  const { data: budget } = useQueryBudget();
  const angleBudget = budgetAngle({ budget, balance });

  const onClickRevoke = () => {
    setRevoking(true);
    onRevoke();
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Card>
            <CardHeader title={t(tKeys.tenantPage.currentTenant.title())} />
            <CardContent>
              <div className={classes.currentTenant}>
                <Gravatar email={tenant.email} />
                <div>
                  <Typography>{tenant.name}</Typography>
                  <Typography variant="caption">
                    <a href={`mailto:${tenant.email}`}>{tenant.email}</a>
                  </Typography>
                </div>
                <div>
                  <Typography>Since: {tenant.entryDate}</Typography>
                </div>
              </div>
              {!revoking && (
                <div className={classes.alignEnd}>
                  <Button onClick={() => onClickRevoke()}>
                    {t(tKeys.tenantPage.currentTenant.terminate())}
                  </Button>
                </div>
              )}
              {revoking && <CircularProgress />}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Card>
            <CardHeader title={t(tKeys.tenantPage.balanceCard.title())} />
            <CardContent>
              <Container className={classes.center}>
                <div>
                  <Gauge angle={angleBudget} />
                </div>
                <Typography variant="h4">
                  {formatCurrency(balance?.budget ?? 0)}
                </Typography>
              </Container>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Card>
            <CardHeader title={t(tKeys.tenantPage.paymentsCard.title())} />
            <CardContent></CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};
