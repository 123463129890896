import { useMatch } from 'react-router-dom';
import { RoleLetter, RoleType } from 'api';

export type Domain = 'prop' | 'apt' | 'vaad';

type RouteParams = {
  roleLetter?: string;
  propertyId?: string;
  propertyAction?: string;
  aptId?: string;
  aptAction?: string;
};

type RouteInfo = RouteParams & {
  domain: Domain;
  role: RoleType | null;
  activeMenu?: string;
  propertyId?: string;
  isVaad?: boolean;
};

const roleForLetter = (letter: RoleLetter): RoleType | null => {
  const map: { [key: string]: RoleType } = {
    t: RoleType.TENANT,
    o: RoleType.OWNER,
    v: RoleType.VAAD,
  };

  return map[letter];
};

export const useUrlParser = (): RouteInfo => {
  const propIndexMatch = useMatch(':roleLetter/prop/:propertyId');
  const propMatch = useMatch(':roleLetter/prop/:propertyId/:propertyAction/*');
  const aptIndexMatch = useMatch(':roleLetter/prop/:propertyId/apt/:aptId');
  const aptMatch = useMatch(
    ':roleLetter/prop/:propertyId/apt/:aptId/:aptAction'
  );
  const addRoleMatch = useMatch('add-role/*');

  const match =
    aptMatch || aptIndexMatch || propMatch || propIndexMatch || addRoleMatch;

  const params: RouteParams = match?.params as RouteParams;
  const roleLetter = params?.roleLetter;
  const propertyId = params?.propertyId;

  let activeMenu = '';
  let domain: Domain = 'prop';
  const role = roleForLetter(roleLetter as RoleLetter);

  if (aptIndexMatch) {
    activeMenu = 'owners';
    domain = 'prop';
  } else if (params?.propertyAction) {
    activeMenu = params.propertyAction;
    domain = 'prop';
  } else if (params?.aptAction) {
    activeMenu = params.aptAction;
    domain = 'apt';
  } else if (addRoleMatch) {
    domain = 'vaad';
  }

  const isVaad = role === RoleType.VAAD;

  return {
    ...params,
    activeMenu,
    domain,
    role,
    propertyId,
    isVaad,
  };
};
