import { tKeys } from 'translationKeys';
import { useUrlParser } from 'features/urlParser';
import { useQueryAptCheques } from 'features/data/queries';
import React from 'react';
import { AptPage } from '#common/AptPage/AptPage';
import { ContentOrEmptyOrLoader } from '#common/ContentOrEmptyOrLoader';
import { ChequesManageEmptyState } from './ChequesManageEmptyState';
import { generatePropertyPath, Paths } from 'app/Paths';
import { useCurrentRole } from 'app/hooks';
import { useNavigate } from 'react-router-dom';
import { ChequesManageTable } from './ChequesManageTable';

export const AptChequesPage = () => {
  const { aptId } = useUrlParser();
  const { roleType, propertyId } = useCurrentRole();
  const navigate = useNavigate();

  const { isFetching: isFetchingCheques, data: cheques } = useQueryAptCheques(
    aptId!
  );

  const onAddCheque = () => {
    navigate({
      pathname: generatePropertyPath(Paths.property.cheques.new, {
        propId: propertyId,
        role: roleType,
      }),
      search: new URLSearchParams({
        aptId: aptId!,
      }).toString(),
    });
  };

  return (
    <AptPage lastCrumbKey={tKeys.menu.vaad.cheques()}>
      <ContentOrEmptyOrLoader
        isLoading={isFetchingCheques || !cheques}
        isEmpty={!isFetchingCheques && !!cheques && cheques.length === 0}
        EmptyState={<ChequesManageEmptyState onClick={onAddCheque} />}
        Content={
          <ChequesManageTable
            cheques={(cheques ?? []).map((cheque) => ({ cheque }))}
            onCreate={onAddCheque}
            noApt
          />
        }
      />
    </AptPage>
  );
};
