import { BudgetEmptyState } from './BudgetEmptyState';
import React, { useState } from 'react';
import { BudgetCreateForm } from './BudgetCreateForm';
import * as classes from './classes';
import { useCurrentRole } from 'app/hooks';
import { CircularProgress, Container, Grid } from '@mui/material';
import { BudgetLine } from '@vaad/client';
import { BudgetViewEmptyState } from './BudgetViewEmptyState';
import { useTranslation } from 'react-i18next';
import { Page } from '#common/Page/Page';
import { tKeys } from 'translationKeys';
import {
  useMutationSaveBudget,
  useQueryBudget,
} from 'features/data/queries/budget';
import { toastSuccess } from '#common/toasts';
import { ViewBudget } from './ViewBudget';
import { DebtorsWidget } from '#common/widgets/DebtorsWidget';

export const BudgetPage = () => {
  const { isFetching, data: budget } = useQueryBudget();
  const mutationSaveBudget = useMutationSaveBudget();
  const [isCreating, setIsCreating] = useState(false);
  const { isVaad } = useCurrentRole();
  const { t } = useTranslation();

  const isEmpty = (budget?.lines?.length ?? 0) === 0;

  const handleCreateBudget = () => {
    setIsCreating(true);
  };

  const onCreate = ({
    collection,
    lines,
  }: {
    collection: number;
    lines: BudgetLine[];
  }) => {
    mutationSaveBudget.mutate(
      { collection, lines },
      {
        onSuccess() {
          toastSuccess(t(tKeys.budgetPage.manage.success()));
          setIsCreating(false);
        },
      }
    );
  };

  if (isFetching || mutationSaveBudget.isLoading) {
    return (
      <Container className={classes.loader}>
        <CircularProgress />
      </Container>
    );
  }

  if (isEmpty && !isCreating) {
    return (
      <Page title={t(tKeys.budget.page.title())}>
        {!isVaad && <BudgetViewEmptyState />}
        {isVaad && <BudgetEmptyState onPrimaryClick={handleCreateBudget} />}
      </Page>
    );
  }

  return (
    <Page title={t(tKeys.budget.page.title())}>
      <div className={classes.budgetPage}>
        {isCreating && (
          <BudgetCreateForm
            onCreate={onCreate}
            onCancel={() => setIsCreating(false)}
            previousCollection={budget?.collection || 0}
            previousBudgetLines={budget?.lines || []}
          />
        )}

        {!isCreating && (
          <Grid container spacing={'1rem'}>
            <Grid item xs={12}>
              <DebtorsWidget />
            </Grid>
            <Grid item xs={12}>
              <ViewBudget
                budget={budget}
                onUpdate={() => setIsCreating(true)}
              />
            </Grid>
          </Grid>
        )}
      </div>
    </Page>
  );
};
