import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { makeIcon } from '#common/icon-utils';
import { EmptyState } from '#common/EmptyState';
import { ReactComponent as Cheque } from './Cheque.svg';
import { tKeys } from 'translationKeys';

export const ChequesManageEmptyState = ({
  onClick,
}: {
  onClick: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <EmptyState
      tagline={t(tKeys.cheques.managePage.emptyState.tagline())}
      description={
        <Trans
          i18nKey={tKeys.cheques.managePage.emptyState.description()}
          components={{ br: <div style={{ height: '20px' }} /> }}
        />
      }
      button={t(tKeys.cheques.managePage.emptyState.button())}
      icon={makeIcon(Cheque)}
      onClick={onClick}
    />
  );
};
