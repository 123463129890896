import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import type { RootState, AppDispatch } from '../store';
import { tKeys } from 'translationKeys';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useCurrentLanguage = (): string => {
  const { t } = useTranslation();
  return t(tKeys.languageCode());
};

export * from './useCurrentRole';
export * from './useNavigateToPropertyPath';
export * from './usePlan';
