import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Divider, IconButton, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { logout, selectIsLogged } from '../../../../features/auth/authSlice';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import * as classes from './classes';
import { avatarSrc } from '../../../../features/utils';
import { tKeys } from 'translationKeys';
export const UserMenu = () => {
  const {
    t,
    i18n: { dir },
  } = useTranslation();
  const { email, fullName } = useAppSelector(selectIsLogged);
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  const menuOpen = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <img
          className={classes.round}
          alt="avatar"
          src={avatarSrc({ email: email! })}
        />
      </IconButton>
      <Menu
        id="menu-appbar"
        sx={{ direction: dir() === 'rtl' ? 'rtl' : 'ltr' }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={menuOpen}
        onClose={handleClose}
      >
        <Typography
          sx={{ fontSize: '80%', paddingInline: '10px', paddingBottom: '5px' }}
        >
          {fullName}
        </Typography>
        <Typography
          sx={{ fontSize: '80%', paddingInline: '10px', paddingBottom: '5px' }}
        >
          {email}
        </Typography>
        <Divider />

        <Link className={classes.nude} to="/roles">
          <MenuItem onClick={handleClose}>{t(tKeys.appBar.roles())}</MenuItem>
        </Link>
        <MenuItem onClick={() => dispatch(logout())}>
          {t(tKeys.appBar.logout())}
        </MenuItem>
      </Menu>
    </div>
  );
};
