import { useTranslation } from 'react-i18next';
import GroupsTwoToneIcon from '@mui/icons-material/GroupsTwoTone';
import { Page } from '#common/Page/Page';
import { EmptyState } from '#common/EmptyState';
import { tKeys } from 'translationKeys';
export const VaadMembersEmptyState = ({ onClick }: { onClick(): void }) => {
  const { t } = useTranslation();
  return (
    <Page title={t(tKeys.vaadMembersPage.title())}>
      <EmptyState
        tagline={t(tKeys.vaadMembersPage.emptyState.tagline())}
        description={t(tKeys.vaadMembersPage.emptyState.description())}
        button={t(tKeys.vaadMembersPage.emptyState.button())}
        icon={GroupsTwoToneIcon}
        onClick={() => onClick()}
      />
    </Page>
  );
};
