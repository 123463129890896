export const tKeys = {
  actions: {
    accept: () => 'actions.accept',
    activate: () => 'actions.activate',
    cancel: () => 'actions.cancel',
    decline: () => 'actions.decline',
    delete: () => 'actions.delete',
    edit: () => 'actions.edit',
    save: () => 'actions.save',
    view: () => 'actions.view',
  },
  addRole: {
    address: {
      helper: () => 'addRole.address.helper',
      label: () => 'addRole.address.label',
      placeholder: () => 'addRole.address.placeholder',
    },
    apartments: {
      entranceN: () => 'addRole.apartments.entranceN',
    },
    button: {
      back: () => 'addRole.button.back',
      finish: () => 'addRole.button.finish',
      next: () => 'addRole.button.next',
    },
    city: {
      label: () => 'addRole.city.label',
      placeholder: () => 'addRole.city.placeholder',
    },
    entrances: {
      ab: () => 'addRole.entrances.ab',
      abc: () => 'addRole.entrances.abc',
      abcEllipsis: () => 'addRole.entrances.abcEllipsis',
      count: {
        label: () => 'addRole.entrances.count.label',
      },
      customNames: () => 'addRole.entrances.customNames',
      entranceN: () => 'addRole.entrances.entranceN',
      numbering: {
        label: () => 'addRole.entrances.numbering.label',
      },
    },
    stepLabel: {
      address: () => 'addRole.stepLabel.address',
      apartments: () => 'addRole.stepLabel.apartments',
      entrances: () => 'addRole.stepLabel.entrances',
    },
    title: () => 'addRole.title',
  },
  appBar: {
    invites: () => 'appBar.invites',
    logout: () => 'appBar.logout',
    myAccount_owner: () => 'appBar.myAccount_owner',
    myAccount_tenant: () => 'appBar.myAccount_tenant',
    myAccount_vaad: () => 'appBar.myAccount_vaad',
    roleType_owner: () => 'appBar.roleType_owner',
    roleType_tenant: () => 'appBar.roleType_tenant',
    roleType_vaad: () => 'appBar.roleType_vaad',
    roles: () => 'appBar.roles',
  },
  balance: {
    page: {
      card: {
        projects: {
          title: () => 'balance.page.card.projects.title',
        },
        recurring: {
          title: () => 'balance.page.card.recurring.title',
        },
      },
      subtitle: () => 'balance.page.subtitle',
      title: () => 'balance.page.title',
    },
  },
  bankDetails: {
    emptyState: {
      description: () => 'bankDetails.emptyState.description',
      tagline: () => 'bankDetails.emptyState.tagline',
      title: () => 'bankDetails.emptyState.title',
    },
    page: {
      account: () => 'bankDetails.page.account',
      bank: () => 'bankDetails.page.bank',
      branch: () => 'bankDetails.page.branch',
      iban: () => 'bankDetails.page.iban',
      name: () => 'bankDetails.page.name',
      subtitle: () => 'bankDetails.page.subtitle',
      title: () => 'bankDetails.page.title',
    },
  },
  bankManage: {
    emptyState: {
      button: () => 'bankManage.emptyState.button',
      description: () => 'bankManage.emptyState.description',
      tagline: () => 'bankManage.emptyState.tagline',
    },
  },
  budget: {
    category: () => 'budget.category',
    estimatePerApt: () => 'budget.estimatePerApt',
    monthlyCollection: {
      helper: () => 'budget.monthlyCollection.helper',
      label: () => 'budget.monthlyCollection.label',
    },
    page: {
      subtitle: () => 'budget.page.subtitle',
      title: () => 'budget.page.title',
    },
    topic: {
      cleaning: () => 'budget.topic.cleaning',
      electricity: () => 'budget.topic.electricity',
      elevators: () => 'budget.topic.elevators',
      gardening: () => 'budget.topic.gardening',
      insurance: () => 'budget.topic.insurance',
      legal: () => 'budget.topic.legal',
      maintenance: () => 'budget.topic.maintenance',
      other: () => 'budget.topic.other',
      reserve: () => 'budget.topic.reserve',
      supplies: () => 'budget.topic.supplies',
      taxes: () => 'budget.topic.taxes',
      water: () => 'budget.topic.water',
    },
    total: () => 'budget.total',
    yearlyExpense: () => 'budget.yearlyExpense',
  },
  budgetPage: {
    manage: {
      success: () => 'budgetPage.manage.success',
      updateButton: {
        caption: () => 'budgetPage.manage.updateButton.caption',
        hint: () => 'budgetPage.manage.updateButton.hint',
      },
    },
  },
  cheques: {
    managePage: {
      depositCheque: {
        success: () => 'cheques.managePage.depositCheque.success',
      },
      emptyState: {
        button: () => 'cheques.managePage.emptyState.button',
        description: () => 'cheques.managePage.emptyState.description',
        tagline: () => 'cheques.managePage.emptyState.tagline',
      },
      newCheque: {
        amount: () => 'cheques.managePage.newCheque.amount',
        apartment: () => 'cheques.managePage.newCheque.apartment',
        chequeNum: () => 'cheques.managePage.newCheque.chequeNum',
        date: () => 'cheques.managePage.newCheque.date',
        dispatch: () => 'cheques.managePage.newCheque.dispatch',
        dispatchBudget: () => 'cheques.managePage.newCheque.dispatchBudget',
        dispatchProject: () => 'cheques.managePage.newCheque.dispatchProject',
        success: () => 'cheques.managePage.newCheque.success',
        title: () => 'cheques.managePage.newCheque.title',
      },
      title: () => 'cheques.managePage.title',
    },
    page: {
      emptyState: {
        description: () => 'cheques.page.emptyState.description',
        tagline: () => 'cheques.page.emptyState.tagline',
        title: () => 'cheques.page.emptyState.title',
      },
      subtitle: () => 'cheques.page.subtitle',
      title: () => 'cheques.page.title',
    },
  },
  chequesList: {
    row: {
      actions: {
        deposit: () => 'chequesList.row.actions.deposit',
        destroy: () => 'chequesList.row.actions.destroy',
      },
    },
  },
  configureBudget: {
    emptyState: {
      button: () => 'configureBudget.emptyState.button',
      description: () => 'configureBudget.emptyState.description',
      tagline: () => 'configureBudget.emptyState.tagline',
    },
  },
  documents: {
    page: {
      title: () => 'documents.page.title',
      subtitle: () => 'documents.page.subtitle',
      emptyState: {
        tagline: () => 'documents.page.emptyState.tagline',
        description: () => 'documents.page.emptyState.description',
      },
      vaadEmptyState: {
        description: () => 'documents.page.vaadEmptyState.description',
        button: () => 'documents.page.vaadEmptyState.button',
      },
    },
  },
  general: {
    semicolon: () => 'general.semicolon',
  },
  houseChooser: {
    emptyState: {
      button: () => 'houseChooser.emptyState.button',
      description: () => 'houseChooser.emptyState.description',
      tagline: () => 'houseChooser.emptyState.tagline',
      title: () => 'houseChooser.emptyState.title',
    },
  },
  invite: {
    invitedBy: () => 'invite.invitedBy',
    pending: {
      expiresIn: () => 'invite.pending.expiresIn',
      expiresIn2Days: () => 'invite.pending.expiresIn2Days',
      expiresIn2Hours: () => 'invite.pending.expiresIn2Hours',
      expiresInDays: () => 'invite.pending.expiresInDays',
      expiresInHours: () => 'invite.pending.expiresInHours',
      expiresInLessThan1Hour: () => 'invite.pending.expiresInLessThan1Hour',
      expiresTomorrow: () => 'invite.pending.expiresTomorrow',
    },
  },
  invitesPage: {
    acceptSuccess: () => 'invitesPage.acceptSuccess',
    declinedSuccess: () => 'invitesPage.declinedSuccess',
    emptyState: {
      tagline: () => 'invitesPage.emptyState.tagline',
      description: () => 'invitesPage.emptyState.description',
    },
  },
  languageCode: () => 'languageCode',
  languageName: () => 'languageName',
  login: {
    page: {
      checkYourMailBox: () => 'login.page.checkYourMailBox',
      loginButton: () => 'login.page.loginButton',
    },
  },
  menu: {
    building: {
      bank: () => 'menu.building.bank',
      budget: () => 'menu.building.budget',
      owners: () => 'menu.building.owners',
      projects: () => 'menu.building.projects',
      vaad: () => 'menu.building.vaad',
    },
    community: {
      documents: () => 'menu.community.documents',
      surveys: () => 'menu.community.surveys',
    },
    header: {
      building: () => 'menu.header.building',
      community: () => 'menu.header.community',
      owner: () => 'menu.header.owner',
      renter: () => 'menu.header.renter',
      tenant: () => 'menu.header.tenant',
      vaadMoney: () => 'menu.header.vaadMoney',
    },
    renter: {
      currentTenant: () => 'menu.renter.currentTenant',
    },
    resident: {
      accountBalance: () => 'menu.resident.accountBalance',
      cheques: () => 'menu.resident.cheques',
      payments: () => 'menu.resident.payments',
    },
    vaad: {
      cheques: () => 'menu.vaad.cheques',
      payments: () => 'menu.vaad.payments',
    },
  },
  networkError: () => 'networkError',
  ownersPage: {
    discardButton: () => 'ownersPage.discardButton',
    editField: {
      email: {
        label: () => 'ownersPage.editField.email.label',
      },
      name: {
        label: () => 'ownersPage.editField.name.label',
      },
    },
    emptyState: {
      button: () => 'ownersPage.emptyState.button',
      description: () => 'ownersPage.emptyState.description',
      tagline: () => 'ownersPage.emptyState.tagline',
    },
    revokeButton: () => 'ownersPage.revokeButton',
    table: {
      apartment: () => 'ownersPage.table.apartment',
      debtBudget: () => 'ownersPage.table.debtBudget',
      debtProject: () => 'ownersPage.table.debtProject',
      email: () => 'ownersPage.table.email',
      entrance: () => 'ownersPage.table.entrance',
      owner: () => 'ownersPage.table.owner',
    },
    title: () => 'ownersPage.title',
  },
  paidPlans: {
    tooltip: () => 'paidPlans.tooltip',
  },
  payments: {
    managePage: {
      emptyState: {
        button: () => 'payments.managePage.emptyState.button',
        description: () => 'payments.managePage.emptyState.description',
        tagline: () => 'payments.managePage.emptyState.tagline',
      },
      newPayment: {
        success: () => 'payments.managePage.newPayment.success',
        title: () => 'payments.managePage.newPayment.title',
      },
    },
    page: {
      emptyState: {
        description: () => 'payments.page.emptyState.description',
        tagline: () => 'payments.page.emptyState.tagline',
      },
      subtitle: () => 'payments.page.subtitle',
      title: () => 'payments.page.title',
    },
    table: {
      column: {
        ref: () => 'payments.table.column.ref',
        type: () => 'payments.table.column.type',
      },
    },
    types: {
      cheque: () => 'payments.types.cheque',
      wire: () => 'payments.types.wire',
    },
  },
  preAccept: {
    acceptButton: () => 'preAccept.acceptButton',
    phrase: () => 'preAccept.phrase',
    phraseContinued: () => 'preAccept.phraseContinued',
    yourEmail: () => 'preAccept.yourEmail',
    yourName: () => 'preAccept.yourName',
  },
  projects: {
    table: {
      column: {
        name: () => 'projects.table.column.name',
        status: () => 'projects.table.column.status',
        statusActive: () => 'projects.table.column.statusActive',
        statusArchived: () => 'projects.table.column.statusArchived',
        statusDraft: () => 'projects.table.column.statusDraft',
        statusPaused: () => 'projects.table.column.statusPaused',
      },
    },
  },
  projectsPage: {
    emptyState: {
      description: () => 'projectsPage.emptyState.description',
      tagline: () => 'projectsPage.emptyState.tagline',
    },
    manage: {
      emptyState: {
        button: () => 'projectsPage.manage.emptyState.button',
        description: () => 'projectsPage.manage.emptyState.description',
      },
    },
    newProject: {
      amount: () => 'projectsPage.newProject.amount',
      installmentNum: () => 'projectsPage.newProject.installmentNum',
      name: () => 'projectsPage.newProject.name',
      nbOfInstallments: () => 'projectsPage.newProject.nbOfInstallments',
      success: () => 'projectsPage.newProject.success',
      title: () => 'projectsPage.newProject.title',
      editTitle: () => 'projectsPage.newProject.editTitle',
    },
    tableActions: {
      createProject: () => 'projectsPage.tableActions.createProject',
    },
    title: () => 'projectsPage.title',
    viewProject: {
      amount: () => 'projectsPage.viewProject.amount',
      description: () => 'projectsPage.viewProject.description',
      deleteSuccess: () => 'projectsPage.viewProject.deleteSuccess',
      activateSuccess: () => 'projectsPage.viewProject.activateSuccess',
      projectName: () => 'projectsPage.viewProject.projectName',
      status: () => 'projectsPage.viewProject.status',
      title: () => 'projectsPage.viewProject.title',
    },
  },
  roleSelector: {
    archivedRoles: () => 'roleSelector.archivedRoles',
    createVaad: () => 'roleSelector.createVaad',
    currentRoles: () => 'roleSelector.currentRoles',
    entrances: {
      letters: {
        firstLetter: () => 'roleSelector.entrances.letters.firstLetter',
      },
    },
    subtitle: {
      role_owner: () => 'roleSelector.subtitle.role_owner',
      role_tenant: () => 'roleSelector.subtitle.role_tenant',
      role_vaad: () => 'roleSelector.subtitle.role_vaad',
    },
  },
  signupPage: {
    enterButton: () => 'signupPage.enterButton',
    fullName: {
      label: () => 'signupPage.fullName.label',
    },
  },
  table: {
    column: {
      amount: () => 'table.column.amount',
      apartment: () => 'table.column.apartment',
      chequeNum: () => 'table.column.chequeNum',
      date: () => 'table.column.date',
      entrance: () => 'table.column.entrance',
    },
  },
  tenantPage: {
    balanceCard: {
      title: () => 'tenantPage.balanceCard.title',
    },
    currentTenant: {
      terminate: () => 'tenantPage.currentTenant.terminate',
      title: () => 'tenantPage.currentTenant.title',
    },
    emptyState: {
      button: () => 'tenantPage.emptyState.button',
      description: () => 'tenantPage.emptyState.description',
      tagline: () => 'tenantPage.emptyState.tagline',
      title: () => 'tenantPage.emptyState.title',
    },
    invite: {
      email: {
        label: () => 'tenantPage.invite.email.label',
      },
      name: {
        label: () => 'tenantPage.invite.name.label',
        placeholder: () => 'tenantPage.invite.name.placeholder',
      },
      pending: {
        title: () => 'tenantPage.invite.pending.title',
      },
      revoke: {
        button: () => 'tenantPage.invite.revoke.button',
      },
      send: () => 'tenantPage.invite.send',
    },
    paymentsCard: {
      title: () => 'tenantPage.paymentsCard.title',
    },
    subtitle: () => 'tenantPage.subtitle',
    title: () => 'tenantPage.title',
  },
  vaadMembersPage: {
    button: {
      revoke: () => 'vaadMembersPage.button.revoke',
    },
    cardInvites: {
      nothingToDisplay: () => 'vaadMembersPage.cardInvites.nothingToDisplay',
      title: () => 'vaadMembersPage.cardInvites.title',
    },
    cardMembers: {
      email: () => 'vaadMembersPage.cardMembers.email',
      name: () => 'vaadMembersPage.cardMembers.name',
      title: () => 'vaadMembersPage.cardMembers.title',
    },
    cardNewInvite: {
      button: () => 'vaadMembersPage.cardNewInvite.button',
      title: () => 'vaadMembersPage.cardNewInvite.title',
    },
    emptyState: {
      button: () => 'vaadMembersPage.emptyState.button',
      description: () => 'vaadMembersPage.emptyState.description',
      tagline: () => 'vaadMembersPage.emptyState.tagline',
    },
    title: () => 'vaadMembersPage.title',
  },
  viewBudget: {
    costs: () => 'viewBudget.costs',
    debtors: () => 'viewBudget.debtors',
    emptyState: {
      description: () => 'viewBudget.emptyState.description',
      tagline: () => 'viewBudget.emptyState.tagline',
      title: () => 'viewBudget.emptyState.title',
    },
    monthlyCollection: () => 'viewBudget.monthlyCollection',
    monthlyCollectionTotal: () => 'viewBudget.monthlyCollectionTotal',
    totalDebt: () => 'viewBudget.totalDebt',
    totalMonthsDue: () => 'viewBudget.totalMonthsDue',
  },
};
