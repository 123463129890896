import NotificationsIcon from '@mui/icons-material/Notifications';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Paths } from 'app/Paths';

const StyledHorizontalPadded = styled.div`
  padding-inline-end: 1rem;
`;

const StyledVisitedLink = styled(Link)`
  color: inherit;
  &:visited {
    color: inherit;
  }
`;

export const Notifications = () => {
  return (
    <StyledHorizontalPadded>
      <StyledVisitedLink to={Paths.myInvites}>
        <NotificationsIcon />
      </StyledVisitedLink>
    </StyledHorizontalPadded>
  );
};
