import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Step,
  StepConnector,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { cx } from '@emotion/css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StepAddress } from './StepAddress/StepAddress';
import { StepEntrances } from './StepEntrances/StepEntrances';
import { StepApartments } from './StepApartments/StepApartments';
import { EntrancesNumbering } from 'api';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectIsLoading } from 'features/menu/menuSlice';
import { Entrance, saveNewBuilding } from 'features/apiActions';
import { Page } from '#common/Page/Page';
import { useClasses } from '#common/styles-utils';
import * as classes from './classes';
import { tKeys } from 'translationKeys';

const stepConnectorStyles =
  ({ dir }: { dir: string }) =>
  () => ({
    line: {
      borderRightStyle: dir === 'rtl' ? 'solid' : 'none',
      borderRightWidth: '1px',
      borderLeftStyle: dir === 'rtl' ? 'none' : 'solid',
      marginInlineStart: dir === 'rtl' ? '19px' : '0',
    },
  });

const DirectionalStepConnector = () => {
  const {
    i18n: { dir },
  } = useTranslation();
  const classes = useClasses(stepConnectorStyles({ dir: dir() }));
  return <StepConnector classes={{ line: classes.line }} />;
};

const stepContentStyles =
  ({ dir }: { dir: string }) =>
  () => ({
    root: {
      paddingInlineStart: '30px',
      marginInlineStart: dir === 'rtl' ? '19px' : '12px',
      borderRight: dir === 'rtl' ? 'solid 1px #bdbdbd' : 'none',
      borderLeft: dir !== 'rtl' ? 'solid 1px #bdbdbd' : 'none',
    },
  });

const stepLabelStyles = () => () => ({
  labelContainer: {
    paddingInlineStart: '20px',
    textAlign: 'start',
  },
});

export const AddRole = () => {
  const {
    t,
    i18n: { dir },
  } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoading = useAppSelector(selectIsLoading);

  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [incomplete, setIncomplete] = useState(true);
  const [nbEntrances, setNbEntrances] = useState(1);
  const [entrancesNumbering, setEntrancesNumbering] = useState(
    EntrancesNumbering.NUMBER
  );
  const [entranceNames, setEntranceNames] = useState(
    Array.from({ length: 10 }).map(() => '')
  );
  const [entranceApts, setEntranceApts] = useState(
    Array.from(Array(10).keys()).map(() => 2)
  );

  function getSteps() {
    return [
      t(tKeys.addRole.stepLabel.address()),
      t(tKeys.addRole.stepLabel.entrances()),
      t(tKeys.addRole.stepLabel.apartments()),
    ];
  }

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const saveBuilduing = () => {
    saveNewBuilding(navigate, {
      address,
      city,
      numbering: entrancesNumbering,
      entrances: Array.from(Array(nbEntrances).keys()).map(
        (i: number): Entrance => ({
          name:
            (entrancesNumbering === EntrancesNumbering.NAME &&
              entranceNames[i]) ||
            undefined,
          apartments: entranceApts[i],
        })
      ),
    })(dispatch);
  };

  const handleNext = () => {
    if (!address) {
      return;
    }
    if (activeStep === 2) {
      saveBuilduing();
      return;
    }
    setActiveStep((prevActiveStep) =>
      prevActiveStep < steps.length - 1 ? prevActiveStep + 1 : steps.length - 1
    );
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep > 0 ? prevActiveStep - 1 : 0
    );
  };

  const handleSetEntranceName = (i: number, newName: string) => {
    setEntranceNames(
      entranceNames.map((name, n) => (n === i ? newName : name))
    );
  };

  const handleSetApartments = (i: number, apts: number): void => {
    setEntranceApts(entranceApts.map((val, n) => (n === i ? apts : val)));
  };

  const stepContentClasses = useClasses(stepContentStyles({ dir: dir() }));
  const stepLabelClasses = useClasses(stepLabelStyles());

  return (
    <Page title={t(tKeys.addRole.title())}>
      <Container>
        <Card>
          <CardContent>
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              connector={<DirectionalStepConnector />}
            >
              {steps.map((label, i) => (
                <Step key={label}>
                  <StepLabel
                    dir={dir()}
                    classes={{
                      labelContainer: stepLabelClasses.labelContainer,
                    }}
                  >
                    {label}
                    {activeStep > 0 && i === 0 && (
                      <div>
                        <Typography>{address}</Typography>
                        <Typography>{city}</Typography>
                      </div>
                    )}
                  </StepLabel>
                  <StepContent
                    dir={dir()}
                    classes={{ root: stepContentClasses.root }}
                  >
                    {activeStep === 0 && (
                      <StepAddress
                        address={address}
                        city={city}
                        onSetAddress={(val) => {
                          setAddress(val);
                          setIncomplete(!val || !city);
                        }}
                        onSetCity={(val) => {
                          setCity(val);
                          setIncomplete(!val || !address);
                        }}
                        onEnter={() => handleNext()}
                      />
                    )}

                    {activeStep === 1 && (
                      <StepEntrances
                        count={nbEntrances}
                        onSetCount={setNbEntrances}
                        numbering={entrancesNumbering}
                        onSetNumbering={setEntrancesNumbering}
                        names={entranceNames}
                        onSetName={handleSetEntranceName}
                      />
                    )}

                    {activeStep === 2 && (
                      <StepApartments
                        count={nbEntrances}
                        numbering={entrancesNumbering}
                        names={entranceNames}
                        apts={entranceApts}
                        onSetApts={handleSetApartments}
                      />
                    )}

                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Button
                        onClick={handleBack}
                        className={cx({
                          [classes.backButtonHidden]: activeStep === 0,
                        })}
                      >
                        {t(tKeys.addRole.button.back())}
                      </Button>

                      <Button
                        onClick={handleNext}
                        color="primary"
                        variant="contained"
                        disabled={isLoading || incomplete}
                      >
                        {activeStep === steps.length - 1
                          ? t(tKeys.addRole.button.finish())
                          : t(tKeys.addRole.button.next())}
                      </Button>

                      {isLoading && <CircularProgress />}
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};
