import {
  Card,
  TextField,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  InputAdornment,
  Button,
  Grid,
  TableHead,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteForeverTwoTone';
import { useTranslation } from 'react-i18next';
import React, { createRef, useEffect, useState } from 'react';
import { useAppSelector } from 'app/hooks';
import { BudgetLine, BudgetTopic, BUDGET_TOPIC } from 'api';
import { selectRole } from 'features/auth/authSlice';
import { BudgetPie } from './BudgetPie';
import { useClasses } from '#common/styles-utils';
import { formatDateShort, useFormat } from 'i18n';
import { tKeys } from 'translationKeys';
import { cx } from '@emotion/css';
import { aptsCount } from 'features/utils';

const styles = () => ({
  input: {
    textAlign: 'right',
    width: '80px',
  },
  inputTotal: {
    textAlign: 'right',
    fontWeight: 'bold',
  },
  inputCollectionTooLow: {
    color: 'red',
  },
  deleteCell: {
    padding: 0,
  },
});

const CustomRow = ({
  topics,
  budgetLines,
  onPickTopic,
}: {
  topics: string[];
  budgetLines: BudgetLine[];
  onPickTopic(topic: string): void;
}) => {
  const { t } = useTranslation();

  const onPick = (evt: SelectChangeEvent) => {
    onPickTopic(evt.target.value as string);
  };

  return (
    <TableRow>
      <TableCell>
        <Select value="" onChange={onPick}>
          {topics
            .filter(
              (topic) =>
                topic !== 'other' &&
                !budgetLines
                  .map((line) => line.topic)
                  .includes(topic as BudgetTopic)
            )
            .map((topic) => (
              <MenuItem key={topic} value={topic}>
                {t(`budget.topic.${topic}`)}
              </MenuItem>
            ))}
          <MenuItem value="other">{t(tKeys.budget.topic.other())}</MenuItem>
        </Select>
      </TableCell>

      <TableCell />

      <TableCell />
    </TableRow>
  );
};

function makeNextFirstOfMonth(): Date {
  const now = new Date(Date.now());
  const month = now.getMonth() + 1 === 12 ? '01' : now.getMonth() + 2;
  const year = now.getFullYear() + (month === '01' ? 1 : 0);
  return new Date(`${year}-${month}-01`);
}

export const BudgetCreateForm = ({
  onCreate,
  onCancel,
  previousBudgetLines,
  previousCollection,
}: {
  onCreate(arg: { collection: number; lines: BudgetLine[] }): void;
  onCancel(): void;
  previousCollection: number;
  previousBudgetLines: BudgetLine[];
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { formatCurrency } = useFormat();

  const topics = Object.values(BUDGET_TOPIC);

  const [budgetLines, setBudgetLines] = useState([
    ...previousBudgetLines,
    ...(topics
      .filter(
        (topic) => !previousBudgetLines.find((line) => line.topic === topic)
      )
      .map((topic) => ({ topic, amount: 0 })) as BudgetLine[]),
  ]);
  const classes = useClasses(styles);
  const [shouldFocus, setShouldFocus] = useState('first');
  const [collection, setCollection] = useState(previousCollection);
  const inputRef = createRef<HTMLInputElement>();
  const role = useAppSelector(selectRole)!;
  const startDate = makeNextFirstOfMonth();

  const onClickDeleteLine = (n: number) => {
    setBudgetLines(budgetLines.filter((v, i) => n !== i));
  };

  const onChangeCollection = (evt: React.ChangeEvent<{ value: unknown }>) => {
    setCollection(parseInt(evt.target.value as string));
  };

  const onChangeAmount = (
    i: number,
    evt: React.ChangeEvent<{ value: unknown }>
  ) => {
    const amountInt = parseInt(evt.target.value as string);
    const amount = isNaN(amountInt) || amountInt < 0 ? 0 : amountInt;
    setBudgetLines(
      budgetLines.map((line, n) => (n === i ? { ...line, amount } : line))
    );
  };

  const onChangeDescription = (
    i: number,
    evt: React.ChangeEvent<{ value: unknown }>
  ) => {
    const description = evt.target.value as string;
    setBudgetLines(
      budgetLines.map((line, n) => (n === i ? { ...line, description } : line))
    );
  };

  const total = () => {
    return budgetLines.reduce((acc, line) => acc + line.amount, 0);
  };

  const estimatePerApt = () => {
    return Math.ceil(total() / aptsCount(role.property) / 12);
  };

  const canSave = () => {
    return total() > 0 && collection > 0;
  };

  const onSave = () => {
    if (!canSave()) {
      return;
    }
    onCreate({
      collection,
      lines: budgetLines.filter((line) => line.amount > 0),
    });
  };

  const onPickTopic = (topic: string) => {
    setBudgetLines([
      ...budgetLines,
      { topic: topic as BudgetTopic, amount: 0 },
    ]);
    setShouldFocus('last');
  };

  useEffect(() => {
    inputRef.current && inputRef.current.focus();
    setShouldFocus('');
  }, [shouldFocus, setShouldFocus, inputRef]);

  return (
    <>
      <Typography>{t(tKeys.budget.page.subtitle())}</Typography>
      <Card>
        <Grid container>
          <Grid item sm={12} md={6}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{t(tKeys.budget.category())}</TableCell>
                  <TableCell>{t(tKeys.budget.yearlyExpense())}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {budgetLines.map(({ topic, amount, description }, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      <TextField
                        size="small"
                        label={t(`budget.topic.${topic}`)}
                        value={description || ''}
                        onChange={(evt) => onChangeDescription(i, evt)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        size="small"
                        {...(shouldFocus === 'first' && i === 0
                          ? { inputRef }
                          : {})}
                        {...(shouldFocus === 'last' &&
                        i === budgetLines.length - 1
                          ? { inputRef }
                          : {})}
                        variant="standard"
                        type="number"
                        placeholder={'0'}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">₪</InputAdornment>
                          ),
                          classes: {
                            input: classes.input,
                          },
                        }}
                        margin="none"
                        value={amount === 0 ? '' : amount}
                        onChange={(evt) => onChangeAmount(i, evt)}
                      />
                    </TableCell>
                    <TableCell classes={{ body: classes.deleteCell }}>
                      <Button onClick={() => onClickDeleteLine(i)}>
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>

              <TableBody>
                <CustomRow
                  topics={topics}
                  budgetLines={budgetLines}
                  onPickTopic={onPickTopic}
                />
              </TableBody>
            </Table>
          </Grid>

          <Grid item sm={12} md={6}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <TextField
                      aria-readonly
                      variant="filled"
                      helperText={t(tKeys.budget.estimatePerApt(), {
                        amount: formatCurrency(estimatePerApt()),
                      })}
                      label={t(tKeys.budget.total())}
                      InputProps={{
                        readOnly: true,
                        classes: {
                          input: classes.inputTotal,
                        },
                      }}
                      value={formatCurrency(total())}
                      style={{ width: '80%' }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>

              <TableBody>
                <TableRow>
                  <TableCell>
                    <TextField
                      aria-readonly
                      variant="filled"
                      helperText={t(tKeys.budget.monthlyCollection.helper(), {
                        amount: formatCurrency(estimatePerApt()),
                      })}
                      label={t(tKeys.budget.monthlyCollection.label())}
                      InputProps={{
                        classes: {
                          input: cx(
                            classes.inputTotal,
                            collection < estimatePerApt() &&
                              classes.inputCollectionTooLow
                          ),
                        },
                      }}
                      type="number"
                      value={collection}
                      onChange={(evt) => onChangeCollection(evt)}
                      style={{ width: '80%' }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Button
                      variant="contained"
                      disabled={!canSave()}
                      color="primary"
                      onClick={() => onSave()}
                    >
                      {t(tKeys.actions.save())}
                    </Button>
                    <Typography>
                      {t(tKeys.budgetPage.manage.updateButton.hint(), {
                        fullDate: formatDateShort(startDate, language),
                      })}
                    </Typography>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => onCancel()}
                    >
                      {t(tKeys.actions.cancel())}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>

          <Grid item sm={12} md={12}>
            <BudgetPie budgetLines={budgetLines} layout="horizontal" />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};
