import { Box, Button, CircularProgress, Container, Paper } from '@mui/material';
import { VerticalForm } from '#common/VerticalForm';
import { tKeys } from 'translationKeys';
import { useTranslation } from 'react-i18next';
import { useFormat } from 'i18n';
import { useViewProject } from './useViewProject';
import { ProjectStatus } from 'api';
import { toastSuccess } from '#common/toasts';
import { useCurrentRole, useNavigateToPropertyPath } from '../../../app/hooks';
import { Paths } from '../../../app/Paths';
import { ProjectStatusPill } from './ProjectStatusPill';
import { makeDateFromYm } from '@vaad/utils';
import styled from '@emotion/styled';
import { useMutationActivteProject } from 'features/data/queries';

export const ButtonsBar = styled(Box)`
  padding-top: 20px;
  display: flex;
  gap: 20px;
`;

export const ButtonsBarStart = styled(Box)`
  flex-grow: 2;
`;

const Actions = () => {
  const { t } = useTranslation();
  const { project, deleteProject } = useViewProject();
  const goTo = useNavigateToPropertyPath();
  const { propertyId, roleType } = useCurrentRole();
  const mutationActivateProject = useMutationActivteProject();

  const onActivateProject = () => {
    project?.id &&
      mutationActivateProject.mutate(
        { id: project.id },
        {
          onSuccess() {
            toastSuccess(
              t(tKeys.projectsPage.viewProject.activateSuccess(), {
                name: project.name,
              })
            );
            goTo(Paths.property.projects.list, {
              role: roleType,
              propId: propertyId,
            });
          },
        }
      );
  };

  const onDeleteProject = () => {
    deleteProject({
      onSuccess() {
        project &&
          toastSuccess(
            t(tKeys.projectsPage.viewProject.deleteSuccess(), {
              name: project.name,
            })
          );
        goTo(Paths.property.projects.list, {
          role: roleType,
          propId: propertyId,
        });
      },
    });
  };

  const onEditProject = () => {
    goTo(Paths.property.projects.edit, {
      role: roleType,
      propId: propertyId,
      projectId: project!.id!,
    });
  };

  return (
    <Box paddingBottom="1rem">
      <ButtonsBar>
        <ButtonsBarStart>
          {project?.status === ProjectStatus.draft && (
            <Button onClick={onDeleteProject} color="secondary">
              {t(tKeys.actions.delete())}
            </Button>
          )}
        </ButtonsBarStart>
        <Button onClick={onEditProject}>{t(tKeys.actions.edit())}</Button>
        {project?.status === ProjectStatus.draft && (
          <Button onClick={onActivateProject} variant="contained">
            {t(tKeys.actions.activate())}
          </Button>
        )}
      </ButtonsBar>
    </Box>
  );
};

export const ViewProject = () => {
  const { t } = useTranslation();
  const { formatCurrency, formatDateMonthNameAndYear } = useFormat();
  const { project } = useViewProject();
  const { isVaad } = useCurrentRole();

  return (
    <Container maxWidth="sm" component={Paper}>
      {!project ? (
        <CircularProgress />
      ) : (
        <>
          <VerticalForm
            lines={[
              {
                label: t(tKeys.projectsPage.viewProject.projectName()),
                value: project.name!,
              },
              {
                label: t(tKeys.projectsPage.viewProject.description()),
                value: project.description!,
              },
              {
                label: t(tKeys.projectsPage.viewProject.amount()),
                value: formatCurrency(project.amount!),
              },
              {
                label: t(tKeys.projectsPage.viewProject.status()),
                value: <ProjectStatusPill project={project} />,
              },
              ...(project.installments
                ? []
                : [{ label: '', value: <CircularProgress /> }]),
              ...(project.installments ?? []).map((installment) => ({
                label: t(tKeys.general.semicolon(), {
                  text: formatDateMonthNameAndYear(
                    makeDateFromYm(installment.ym)
                  ),
                }),
                value: formatCurrency(installment.amount),
              })),
            ]}
          />
          {isVaad && <Actions />}
        </>
      )}
    </Container>
  );
};
