import { useNavigate } from 'react-router-dom';
import { Container } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { pickRole, selectRoles } from 'features/auth/authSlice';
import { HouseChooserEmptyState } from './HouseChooserEmptyState';
import { Page } from '#common/Page/Page';
import { RoleVignette } from './RoleVignette';
import { AddRoleVignette } from './AddRoleVignette';
import { PageTitle } from '#common/PageTitle';
import { useTranslation } from 'react-i18next';
import { tKeys } from 'translationKeys';
import styled from '@emotion/styled';

const StyledContainer = styled(Container)`
  display: flex !important;
  flex-wrap: wrap;
  padding-top: 0;
  max-width: 100% !important;
`;

export const RoleSelector = () => {
  const roles = useAppSelector(selectRoles);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onClickRole = (roleId: string) => {
    dispatch(pickRole(roleId));
  };

  const gotoAddRole = () => {
    navigate('/add-role');
  };

  const activeRoles = roles.filter((role) => !role.archiveDate);
  const archivedRoles = roles.filter((role) => role.archiveDate);

  if (roles.length === 0) {
    return (
      <Page>
        <HouseChooserEmptyState onPrimaryClick={gotoAddRole} />
      </Page>
    );
  }

  return (
    <Page>
      <PageTitle title={t(tKeys.roleSelector.currentRoles())} />
      <StyledContainer>
        {activeRoles.map((role, i) => (
          <RoleVignette
            role={role}
            key={role.id}
            i={i}
            onClick={() => onClickRole(role.id)}
          />
        ))}

        <AddRoleVignette />
      </StyledContainer>

      {archivedRoles.length > 0 && (
        <>
          <PageTitle title={t(tKeys.roleSelector.archivedRoles())} />
          <StyledContainer>
            {archivedRoles.map((role, i) => (
              <RoleVignette
                archived
                role={role}
                key={role.id}
                i={i}
                onClick={() => onClickRole(role.id)}
              />
            ))}
          </StyledContainer>
        </>
      )}
    </Page>
  );
};
