import { useQuery } from 'react-query';
import { tokenManager } from '../../token-manager';
import { api, Payment } from 'api';
import { useAppSelector } from '../../../app/hooks';
import { selectRole } from '../../auth/authSlice';
import { keySelfPaymentsList } from './keys';

export const useQuerySelfPayments = () => {
  const { id: roleId } = useAppSelector(selectRole)!;
  return useQuery<Payment[]>(keySelfPaymentsList, async () => {
    const result = await api.selfPayments({
      params: { roleId },
      token: tokenManager.get(),
    });
    return result.payments!;
  });
};
