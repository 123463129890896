import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import {
  clearNetworkError,
  logout,
  performOtpAsync,
  pickRole,
  selectIsLogged,
  selectNetworkError,
  selectRoles,
} from 'features/auth/authSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Paths } from 'app/Paths';
import { linkForRole } from '../../features/auth/linkForRole';

export const Signin = () => {
  const routeParams = useParams();
  const { signinCode } = routeParams;
  const networkError = useAppSelector(selectNetworkError);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLogged } = useAppSelector(selectIsLogged);
  const [otpSent, setOtpSent] = useState(false);
  const roles = useAppSelector(selectRoles);

  useEffect(() => {
    if (!otpSent) {
      dispatch(logout());
      dispatch(performOtpAsync(signinCode!));
      setOtpSent(true);
    }
  }, [otpSent, setOtpSent]);

  useEffect(() => {
    if (networkError) {
      dispatch(clearNetworkError());
      navigate(Paths.login);
    }
  }, [networkError, navigate]);

  useEffect(() => {
    if (isLogged && otpSent) {
      const r = new URLSearchParams(location.search).get('r');
      if (r && r.match(/^\//)) {
        navigate(r);
      } else if (roles.length === 1) {
        dispatch(pickRole(roles[0].id));
        navigate(linkForRole(roles[0]));
      } else {
        navigate(Paths.roles);
      }
    }
  }, [isLogged, navigate, otpSent]);

  return <CircularProgress />;
};
