import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableProps,
  TableRow,
  Theme,
} from '@mui/material';
import { PaletteColorEx } from '../../Board/AreaThemeProvider/theme';
import { useClasses } from '../styles-utils';

const styles = (theme: Theme) => ({
  thead: {
    backgroundColor: (theme.palette.primary as PaletteColorEx).light,
  },
});

type Alignment = 'start' | 'end' | 'center' | 'right';

interface Column<Item> {
  title?: string;
  hidden?: boolean;
  align?: Alignment;
  render: (
    item: Item,
    index: number
  ) => JSX.Element | string | number | undefined;
}

type Props<Item> = {
  size?: TableProps['size'];
  paper?: boolean;
  columns: Column<Item>[];
  items: Item[];
  onClick?: (item: Item, index?: number) => void;
};

const alignToMui = (align: Alignment | undefined) =>
  align === 'end' ? 'right' : align === 'start' ? 'left' : align;

export const TableList = <T,>({
  columns,
  items,
  size,
  paper,
  onClick,
}: Props<T>) => {
  const styledClasses = useClasses(styles);

  const visibleColumns = columns.filter((column) => !column.hidden);
  const hover = Boolean(onClick);

  return (
    <TableContainer {...(paper && { component: Paper })}>
      <Table size={size}>
        <TableHead className={styledClasses.thead}>
          <TableRow>
            {visibleColumns.map((column, index) => (
              <TableCell key={index} align={alignToMui(column.align)}>
                {column.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, index) => (
            <TableRow
              key={index}
              hover={hover}
              onClick={() => onClick?.(item, index)}
              sx={{ cursor: 'pointer' }}
            >
              {visibleColumns.map((column, iCol) => (
                <TableCell key={iCol} align={alignToMui(column.align)}>
                  {column.render(item, index)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
