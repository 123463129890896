import {
  Button,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Gravatar } from '#common/Gravatar';
import { tKeys } from 'translationKeys';
export const MembersCard = ({
  members,
  onRevoke,
  admin,
}: {
  members: { name: string; email: string }[];
  onRevoke: (email: string) => void;
  admin?: boolean;
}) => {
  const [hovering, setHovering] = useState({} as { [email: string]: boolean });

  const onHoverOverButton = (email: string) => {
    setHovering({ ...hovering, [email]: true });
  };

  const onHoverOutButton = (email: string) => {
    setHovering({ ...hovering, [email]: false });
  };

  const { t } = useTranslation();

  return (
    <Card>
      <CardContent>
        <Typography component="h6">
          {t(tKeys.vaadMembersPage.cardMembers.title())}
        </Typography>
        <TableContainer>
          <Table>
            <TableBody>
              {members.map((member, index) => (
                <TableRow key={`${index}:${member.email}`}>
                  <TableCell>
                    <Gravatar email={member.email} />
                  </TableCell>
                  <TableCell>{member.name}</TableCell>
                  {admin && (
                    <TableCell>
                      {members.length > 1 && (
                        <Button
                          color={
                            hovering[member.email] ? 'primary' : 'secondary'
                          }
                          variant={
                            hovering[member.email] ? 'contained' : 'outlined'
                          }
                          onMouseOver={() => onHoverOverButton(member.email)}
                          onMouseOut={() => onHoverOutButton(member.email)}
                          onClick={() => onRevoke(member.email)}
                        >
                          {t(tKeys.vaadMembersPage.button.revoke())}
                        </Button>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};
