import React from 'react';
import { useTranslation } from 'react-i18next';
import { Cheque } from 'api';
import { TableList } from '#common/TableList';
import { useFormat, formatDateMonthNameAndYear } from '../../../i18n';
import { makeDateFromYm } from '@vaad/utils';
import { tKeys } from 'translationKeys';

export const ChequesList = ({ cheques }: { cheques: Cheque[] }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { formatCurrency2 } = useFormat();

  return (
    <TableList<(typeof cheques)[0]>
      paper
      columns={[
        {
          title: t(tKeys.table.column.chequeNum()),
          align: 'right',
          render: (row) => row.chequeNo,
        },
        {
          title: t(tKeys.table.column.date()),
          render: (row) =>
            formatDateMonthNameAndYear(makeDateFromYm(row.date), language),
        },
        {
          title: t(tKeys.table.column.amount()),
          align: 'right',
          render: (row) => formatCurrency2(row.amount),
        },
      ]}
      items={cheques}
      size="small"
    />
  );
};
