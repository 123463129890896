import { css } from '@emotion/css';

export const empty = css`
  background-color: rgb(255, 238, 231);
`;

export const minicol = css`
  width: 1px;
  white-space: nowrap;
`;

export const emailTextField = css`
  margin-inline-start: 1em;
`;

export const email = css`
  color: silver;
`;

export const expires = css`
  color: #a3a3a3;
  flex-grow: 2;
  text-align: center;
`;
