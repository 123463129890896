import { FC } from 'react';
import { Apartment, Property } from 'api';
import { Stack, Typography } from '@mui/material';
import { useApartmentTitle } from '../../features/utils';

interface Props {
  property: Property;
  apartment?: Apartment;
}

export const PropertyAddress: FC<Props> = ({ property, apartment }) => {
  const aptTitle = useApartmentTitle({
    aptNum: apartment?.aptNum,
    entrance: apartment?.entrance,
    property,
  });
  return (
    <Stack>
      <Typography>
        {property.address}
        {aptTitle && <> / {aptTitle}</>}
      </Typography>
      <Typography>{property.city}</Typography>
    </Stack>
  );
};
