import React from 'react';
import { ChequesManageEmptyState } from './ChequesManageEmptyState';
import { useQueryCheques } from 'features/data/queries';
import { Loader } from '#common/Loader';
import { useAppSelector, useNavigateToPropertyPath } from 'app/hooks';
import { selectRole } from 'features/auth/authSlice';
import { Paths } from 'app/Paths';
import { ChequesManageTable } from './ChequesManageTable';

export const ChequesManageList = () => {
  const role = useAppSelector(selectRole)!;
  const goTo = useNavigateToPropertyPath();

  const { isFetching, data } = useQueryCheques();

  const onEmptyStateClick = () => {
    goTo(Paths.property.cheques.new, {
      role: role.role,
      propId: role.propertyId,
    });
  };

  const isLoadedButEmpty = () => !isFetching && data && data.length === 0;
  const hasData = () => !isFetching && data && data.length > 0;

  return (
    <>
      {isFetching && <Loader size="big" />}

      {isLoadedButEmpty() && (
        <ChequesManageEmptyState onClick={onEmptyStateClick} />
      )}

      {hasData() && (
        <ChequesManageTable cheques={data!} onCreate={onEmptyStateClick} />
      )}
    </>
  );
};
