import { useAppDispatch, useAppSelector } from 'app/hooks';
import { clearNetworkError, selectNetworkError } from 'features/auth/authSlice';
import { toast } from 'react-toastify';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { tKeys } from 'translationKeys';
export const NetworkError: FC<{ autoClose?: boolean }> = ({ autoClose }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isNetworkError = useAppSelector(selectNetworkError);

  const onClose = () => {
    dispatch(clearNetworkError());
  };

  useEffect(() => {
    if (isNetworkError) {
      toast.error(t(tKeys.networkError()), {
        toastId: 'networkError',
        position: 'top-right',
        autoClose: autoClose ? 5000 : false,
        hideProgressBar: false,
        closeOnClick: autoClose ?? false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        onClose,
      });
    }
  });
  return null;
};
