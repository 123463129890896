import { css } from '@emotion/css';

export const paper = css`
  padding: 30px;
`;

export const alignEnd = css`
  text-align: end;
`;

export const inviteRow = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px 20px;
`;

export const expires = css`
  color: #a3a3a3;
  flex-grow: 2;
  text-align: end;
`;

export const cardActionReverse = css`
  flex-direction: row-reverse;
`;

export const currentTenant = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`;

export const center = css`
  text-align: center;
`;
