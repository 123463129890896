import {
  AptNumber,
  EntranceNumber,
  OwnersData,
  useMutationDeclareOwner,
  useMutationRevokeOwnerInvite,
  useQueryOwners,
} from 'features/data/queries/owners';
import { useEffect, useState } from 'react';
import { useQueryResidentsClear } from 'features/data/queries/residents';
import { useCurrentRole } from 'app/hooks';

export const useLoadOwnersData = () => {
  const { roleId, propertyId } = useCurrentRole();
  const { isFetching, data } = useQueryOwners(propertyId);
  const [ownersData, setOwnersData] = useState<OwnersData | undefined>(data);
  const revokeOwnerInvite = useMutationRevokeOwnerInvite(roleId);
  const mutationDeclareOwner = useMutationDeclareOwner(roleId);
  const clearResidents = useQueryResidentsClear();
  const isEmpty = !isFetching && !!data && Object.keys(data).length === 0;

  useEffect(() => {
    if (!isFetching && data) {
      setOwnersData(data);
    }
  }, [isFetching]);

  const modifyOwnersData = (
    entrance: number,
    aptNum: number,
    data: Omit<OwnersData[EntranceNumber][AptNumber], 'aptId' | 'balance'>
  ) =>
    setOwnersData({
      ...ownersData,
      [entrance]: {
        ...(ownersData ?? {})[entrance],
        [aptNum]: { ...data, aptId: '', balance: { budget: 0, projects: 0 } },
      },
    });

  const declareOwner = ({
    entrance,
    aptNum,
    fullName,
    email,
  }: {
    entrance: number;
    aptNum: number;
    fullName: string;
    email: string;
  }) =>
    mutationDeclareOwner.mutate(
      { entrance, aptNum, fullName, email },
      {
        onSuccess() {
          return clearResidents();
        },
      }
    );

  return {
    isLoading: isFetching,
    ownersData,
    setOwnersData,
    modifyOwnersData,
    revokeOwnerInvite,
    declareOwner,
    isEmpty,
  };
};
