import { FC } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import frLocale from 'date-fns/locale/fr';
import enLocale from 'date-fns/locale/en-US';
import heLocale from 'date-fns/locale/he';
import { useTranslation } from 'react-i18next';

// For dynamically loading the date-fns locales, see:
// https://robertmarshall.dev/blog/dynamically-import-datefns-locale-mui-datepicker-localization/
// but at the moment with only 3 languages, the bargain is of about 4Kb total on the gzipped build.

export const DateLocalizationProvider: FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language as 'en' | 'fr' | 'he';
  const locale =
    {
      en: enLocale,
      fr: frLocale,
      he: heLocale,
    }[currentLanguage] ?? enLocale;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      {children}
    </LocalizationProvider>
  );
};
