import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyState } from '#common/EmptyState/EmptyState';
import { ReactComponent as ToDoListSvg } from './ToDoList.svg';
import { makeIcon } from '#common/icon-utils';
import { tKeys } from 'translationKeys';
interface Props {
  onPrimaryClick: () => void;
}

export const BudgetEmptyState = (props: Props) => {
  const { t } = useTranslation();
  return (
    <EmptyState
      button={t(tKeys.configureBudget.emptyState.button())}
      dataTestid="configure-budget-empty-state"
      tagline={t(tKeys.configureBudget.emptyState.tagline())}
      description={t(tKeys.configureBudget.emptyState.description())}
      icon={makeIcon(ToDoListSvg)}
      onClick={props.onPrimaryClick}
    />
  );
};
