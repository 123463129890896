import { Role } from './authSlice';
import { RoleType } from 'api';
import { prefixForRole } from 'app/constants';

export const linkForRole = (role: Role): string => {
  switch (role.role) {
    case RoleType.TENANT:
      return `/${prefixForRole(RoleType.TENANT)}/prop/${role.propertyId}/apt/${
        role.aptId
      }/payments`;
    case RoleType.OWNER:
      return `/${prefixForRole(RoleType.OWNER)}/prop/${role.propertyId}/apt/${
        role.aptId
      }/payments`;
    case RoleType.VAAD:
      return `/${prefixForRole(RoleType.VAAD)}/prop/${role.propertyId}/budget`;
    default:
      return '';
  }
};
