import { InputAdornment, TextField } from '@mui/material';
import { useClasses } from '#common/styles-utils';
import { FC } from 'react';
import { useFormat } from '../../i18n';

const styles = () => ({
  input: {
    textAlign: 'right',
  },
});

export const AmountTextField: FC<Parameters<typeof TextField>[0]> = (props) => {
  const classes = useClasses(styles);
  const { formatCurrency } = useFormat();

  const isEnd = formatCurrency(0).charAt(0) === '0';

  const adornment = (
    <InputAdornment position={isEnd ? 'end' : 'start'}>
      {formatCurrency(0).replace(/[0 ]+/, '')}
    </InputAdornment>
  );

  return (
    <TextField
      type="number"
      {...props}
      InputProps={{
        ...props.InputProps,
        classes,
        ...(isEnd
          ? { endAdornment: adornment }
          : { startAdornment: adornment }),
      }}
    />
  );
};
