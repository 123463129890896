import React from 'react';
import { useAppSelector } from 'app/hooks';
import { selectIsLogged } from 'features/auth/authSlice';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Signup } from '../LoginPage/Signup';
import { Signin } from '../LoginPage/Signin';
import { PreAccept } from '../LoginPage/PreAccept';
import { LoggedInWall } from './LoggedInWall';
import { LoginPage } from '../LoginPage/LoginPage';
import { Board } from '../Board/Board';
import { RolesFullPage } from '../FullPages/RolesFullPage';
import { MyInvitesFullPage } from '../FullPages/MyInvitesFullPage';
import { BoardWithDrawer } from '../Board/BoardWithDrawer';
import { AddRoleFullPage } from '../FullPages/AddRoleFullPage/AddRoleFullPage';
import { BudgetPage } from '../Pages/BudgetPage/BudgetPage';
import { BankManagePage } from '../Pages/BankDetailsPage/BankManagePage';
import { BankDetailsPage } from '../Pages/BankDetailsPage/BankDetailsPage';
import { ProjectsPage } from '../Pages/ProjectsPage';
import { OwnersPage } from '../Pages/OwnersPage/OwnersPage';
import { VaadMembersPage } from '../Pages/VaadMembersPage/VaadMembersPage';
import { PaymentsPage } from '../Pages/PaymentsPage/PaymentsPage';
import { ChequesPage } from '../Pages/ChequesPage/ChequesPage';
import { TenantPage } from '../Pages/TenantPage/TenantPage';
import { BalancePage } from '../Pages/BalancePage';
import { useUrlParser } from 'features/urlParser';
import { RoleType } from 'api';
import { generatePropertyPath, Paths } from 'app/Paths';
import { RedirectToLogin } from './RedirectToLogin';
import { ChequesVaadPage } from '../Pages/ChequesPage/ChequesVaadPage';
import { PaymentsVaadPage } from '../Pages/PaymentsPage/PaymentsVaadPage';
import { AptChequesPage } from 'components/Pages/ChequesPage/AptChequesPage';
import { AptPaymentsPage } from '../Pages/PaymentsPage/AptPaymentsPage';
import { AptBalancePage } from '../Pages/BalancePage/AptBalancePage';
import { ApartmentIndexPage } from '../Pages/ApartmentIndexPage';
import { DocumentsPage } from '../Pages/DocumentsPage';
import { PricingPage } from '../Pages/PricingPage/PricingPage';

function isDefined<T>(val: T | null | undefined): val is T {
  return !!val;
}

export const AppRoutes = () => {
  const { isLogged } = useAppSelector(selectIsLogged);
  const { role, propertyId: propId, isVaad } = useUrlParser();

  return (
    <Routes>
      <Route path={Paths.signup} element={<Signup />} />
      <Route path={Paths.signin} element={<Signin />} />
      <Route path={Paths.inviteLanding} element={<PreAccept />} />

      <Route element={<LoggedInWall />}>
        {!isLogged && (
          <>
            <Route path={Paths.login} element={<LoginPage />} />
            <Route path="*" element={<RedirectToLogin />} />
          </>
        )}
        {isLogged && (
          <Route element={<Board />}>
            <Route path={Paths.roles} element={<RolesFullPage />} />
            <Route path={Paths.myInvites} element={<MyInvitesFullPage />} />
            <Route path={Paths.property.index} element={<BoardWithDrawer />}>
              <Route index element={<>PropIndex</>} />
              <Route path={Paths.property.budget} element={<BudgetPage />} />
              <Route
                path={Paths.property.bank}
                element={isVaad ? <BankManagePage /> : <BankDetailsPage />}
              />
              <Route
                path={Paths.property.documents}
                element={<DocumentsPage />}
              />
              <Route path={Paths.property.pricing} element={<PricingPage />} />

              <Route
                path={`${Paths.property.projects.list}/*`}
                element={<ProjectsPage />}
              />

              {isVaad && (
                <Route path={Paths.property.owners} element={<OwnersPage />} />
              )}

              <Route path={Paths.property.vaad} element={<VaadMembersPage />} />

              {isVaad && (
                <Route
                  path={`${Paths.property.cheques.list}/*`}
                  element={<ChequesVaadPage />}
                />
              )}

              {isVaad && (
                <Route
                  path={`${Paths.property.payments.list}/*`}
                  element={<PaymentsVaadPage />}
                />
              )}

              <Route path={Paths.apartment.index}>
                <Route index element={<ApartmentIndexPage />} />
                <Route
                  path={Paths.apartment.payments}
                  element={isVaad ? <AptPaymentsPage /> : <PaymentsPage />}
                />
                <Route
                  path={Paths.apartment.cheques}
                  element={isVaad ? <AptChequesPage /> : <ChequesPage />}
                />
                {role === RoleType.OWNER && (
                  <Route
                    path={Paths.apartment.tenant}
                    element={<TenantPage />}
                  />
                )}
                <Route
                  path={Paths.apartment.accountBalance}
                  element={isVaad ? <AptBalancePage /> : <BalancePage />}
                />
              </Route>

              {isDefined(role) && isDefined(propId) && (
                <Route
                  path="*"
                  element={
                    <Navigate
                      to={generatePropertyPath(Paths.property.index, {
                        role,
                        propId,
                      })}
                    />
                  }
                />
              )}
            </Route>

            <Route path={Paths.addRole} element={<AddRoleFullPage />} />
            <Route path="*" element={<Navigate to={Paths.roles} />} />
          </Route>
        )}
      </Route>
    </Routes>
  );
};
