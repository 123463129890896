import { Loader } from '#common/Loader';
import React, { FC } from 'react';

interface Props {
  isLoading: boolean;
  isEmpty: boolean;
  EmptyState: JSX.Element;
  Content: JSX.Element;
}

export const ContentOrEmptyOrLoader: FC<Props> = ({
  isLoading,
  isEmpty,
  EmptyState,
  Content,
}) => {
  if (isLoading) {
    return <Loader size="big" />;
  }

  if (isEmpty) {
    return EmptyState;
  }

  return Content;
};
