import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef } from 'react';
import { RtlTextField } from '#common/RtlTextField';
import { tKeys } from 'translationKeys';
export const StepAddress = ({
  address,
  city,
  onSetCity,
  onSetAddress,
  onEnter,
}: {
  address: string;
  city: string;
  onSetAddress(val: string): void;
  onSetCity(val: string): void;
  onEnter(): void;
}) => {
  const { t } = useTranslation();

  const handleChangeAddress = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    onSetAddress(event.target.value as string);
  };
  const handleChangeCity = (event: React.ChangeEvent<{ value: unknown }>) => {
    onSetCity(event.target.value as string);
  };
  const refAddress = useRef<HTMLInputElement>();
  const refCity = useRef<HTMLInputElement>();

  useEffect(() => {
    if (!city.trim() && !address.trim()) {
      refAddress.current!.focus();
    }
  });

  const onPressEnter = () => {
    if (!address.trim()) {
      refAddress.current?.focus();
      return;
    }
    if (!city.trim()) {
      refCity.current?.focus();
      return;
    }
    onEnter();
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
      <RtlTextField
        inputRef={refAddress}
        label={t(tKeys.addRole.address.label())}
        placeholder={t(tKeys.addRole.address.placeholder())}
        variant="standard"
        value={address}
        onChange={handleChangeAddress}
        onKeyDown={(ev) => {
          if (ev.key === 'Enter') {
            ev.preventDefault();
            onPressEnter();
          }
        }}
        fullWidth
      />
      <RtlTextField
        inputRef={refCity}
        label={t(tKeys.addRole.city.label())}
        helperText={t(tKeys.addRole.address.helper())}
        placeholder={t(tKeys.addRole.city.placeholder())}
        variant="standard"
        value={city}
        onChange={handleChangeCity}
        onKeyDown={(ev) => {
          if (ev.key === 'Enter') {
            ev.preventDefault();
            onPressEnter();
          }
        }}
        fullWidth
      />
    </div>
  );
};
