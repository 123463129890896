import { useMutation, useQuery, useQueryClient } from 'react-query';
import { api, Budget, BudgetLine } from 'api';
import { useAppSelector } from 'app/hooks';
import { selectPropertyId } from '../../auth/authSlice';
import { tokenManager } from '../../token-manager';
import { keyBudget } from './keys';

export const useQueryBudget = () => {
  const propId = useAppSelector(selectPropertyId)!;
  return useQuery<Budget>(keyBudget, async () => {
    return (
      await api.budget({
        params: { propId },
        token: tokenManager.get(),
      })
    ).budget as Budget;
  });
};

export const useMutationSaveBudget = () => {
  const propId = useAppSelector(selectPropertyId)!;
  const queryClient = useQueryClient();
  return useMutation(
    ({ lines, collection }: { lines: BudgetLine[]; collection: number }) => {
      return api.saveBudget({
        params: { propId },
        body: { collection, lines },
        token: tokenManager.get(),
      });
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(keyBudget);
      },
    }
  );
};
