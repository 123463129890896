import { useTranslation } from 'react-i18next';
import { Page } from '#common/Page/Page';
import { EmptyState } from '#common/EmptyState';
import { makeIcon } from '#common/icon-utils';
import { ReactComponent as Money } from './Money.svg';
import { PaymentsTable } from './PaymentsTable';
import { WithLoader } from '#common/WithLoader';
import { useQuerySelfPayments } from '../../../features/data/queries';
import { tKeys } from 'translationKeys';
export const PaymentsPage = () => {
  const { t } = useTranslation();
  const { isFetching, data: payments } = useQuerySelfPayments();

  if (!isFetching && payments && !payments.length) {
    return (
      <Page title={t(tKeys.payments.page.title())}>
        <EmptyState
          tagline={t(tKeys.payments.page.emptyState.tagline())}
          description={t(tKeys.payments.page.emptyState.description())}
          icon={makeIcon(Money)}
        />
      </Page>
    );
  }

  return (
    <Page
      title={t(tKeys.payments.page.title())}
      subtitle={t(tKeys.payments.page.subtitle())}
    >
      <WithLoader loading={isFetching}>
        <PaymentsTable payments={payments!} />
      </WithLoader>
    </Page>
  );
};
