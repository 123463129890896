import { Card, CardHeader, Grid, Typography } from '@mui/material';
import { tKeys } from 'translationKeys';
import { StyledBox } from './StyledBox';
import { Gauge } from '#common/Gauge';
import { effectiveAngle } from './effectiveAngle';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormat } from '../../../i18n';

export const ProjectsGridItem: FC<{ angle: number; balance: number }> = ({
  angle,
  balance,
}) => {
  const { t } = useTranslation();
  const { formatCurrency } = useFormat();

  return (
    <Grid item xs>
      <Card>
        <CardHeader title={t(tKeys.balance.page.card.projects.title())} />
        <StyledBox>
          <div>
            <Gauge angle={effectiveAngle(angle)} />
          </div>
          <Typography variant="h4">{formatCurrency(balance, true)}</Typography>
        </StyledBox>
      </Card>
    </Grid>
  );
};
