import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
  Tooltip,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';
import { Domain, useUrlParser } from 'features/urlParser';
import { useClasses } from '#common/styles-utils';
import { useMenuOpen } from './useMenuOpen';
import { FC } from 'react';

interface Props {
  menuId: string;
  domain?: Domain;
  Icon: OverridableComponent<SvgIconTypeMap>;
  text: string;
  to: string;
}

export const Item: FC<Props> = ({ menuId, domain, Icon, text, to }) => {
  const { isOpen } = useMenuOpen();
  const { activeMenu, domain: urlDomain } = useUrlParser();

  const isActive =
    activeMenu === menuId && (domain === undefined || domain === urlDomain);

  const styles = (theme: Theme) => ({
    active: {
      color: theme.palette.primary.dark,
    },
  });

  const classes = useClasses(styles);

  return (
    <ListItemButton selected={isActive} component={Link} to={to}>
      <ListItemIcon>
        {isOpen && <Icon color={isActive ? 'primary' : 'inherit'} />}
        {!isOpen && (
          <Tooltip title={text}>
            <Icon color={isActive ? 'primary' : 'inherit'} />
          </Tooltip>
        )}
      </ListItemIcon>
      <ListItemText
        aria-current="page"
        primary={text}
        classes={{ primary: isActive ? classes.active : 'inherit' }}
        style={{ textAlign: 'start' }}
      />
    </ListItemButton>
  );
};
