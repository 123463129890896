import { CircularProgress } from '@mui/material';
import React from 'react';

export const FullPageLoader = () => {
  return (
    <div style={{ height: '100%' }} data-testid="fullpage-loader">
      <div
        style={{
          margin: 0,
          width: '100%',
          textAlign: 'center',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
        }}
      >
        <CircularProgress />
      </div>
    </div>
  );
};
