import { ReactComponent as GaugeSvg } from './Gauge.svg';
import { useEffect, useState } from 'react';

export const Gauge = ({ angle }: { angle: number }) => {
  const [id] = useState(`gauge__${Math.floor(10000 * Math.random())}`);

  const beginAngle = angle < 0 ? angle + 60 : angle - 60;
  const beginStyle = {
    transitionDuration: '0s',
    transitionProperty: 'none',
    transform: `rotate(${beginAngle}deg)`,
    visibility: 'visible',
  };

  const endStyle = {
    transitionDuration: '0.8s',
    transitionProperty: 'transform',
    transform: `rotate(${angle}deg)`,
  };

  useEffect(() => {
    const gauge = document.getElementById(id);
    const eltNeedle = gauge!.querySelector('.needle');
    Object.assign((eltNeedle as HTMLElement).style, beginStyle);
    setTimeout(() => {
      Object.assign((eltNeedle as HTMLElement).style, endStyle);
    }, 100);
  });

  return <GaugeSvg id={id} />;
};
