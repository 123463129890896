import { FC } from 'react';
import { Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PaidButton } from '#common/PaidButton';

interface Props {
  buttons?: {
    caption: string;
    onClick?: () => void;
    to?: string;
    needsPaidPlan?: boolean;
  }[];
}

export const TableActions: FC<Props> = ({ buttons }) => {
  const navigate = useNavigate();

  return (
    <Container style={{ display: 'flex', flexDirection: 'row-reverse' }}>
      {buttons?.map((button, index) => (
        <PaidButton
          key={index}
          needsPaidPlan={button.needsPaidPlan === true}
          variant="contained"
          onClick={
            button.onClick ??
            (() => {
              button.to && navigate(button.to);
            })
          }
        >
          {button.caption}
        </PaidButton>
      ))}
    </Container>
  );
};
