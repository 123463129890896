import { OwnersData, useQueryOwners } from '../../features/data/queries/owners';
import { useCurrentRole } from './useCurrentRole';

interface EntranceAndAptNums {
  entranceNum: number;
  aptNum: number;
}

const findEntranceForAptId: (
  ownersData?: OwnersData,
  aptId?: string
) => EntranceAndAptNums | undefined = (ownersData, aptId) => {
  let foundAptNum: number | undefined;
  let foundEntranceNum: number | undefined;

  Object.entries<OwnersData[number]>(
    (ownersData ?? {}) as unknown as { [s: string]: OwnersData[number] }
  ).find(([entranceNum, entranceData]) => {
    Object.entries(entranceData).find(([aptNum, aptData]) => {
      if (aptData.aptId === aptId) {
        foundAptNum = aptNum as unknown as number;
        foundEntranceNum = entranceNum as unknown as number;
        return true;
      }
      return false;
    });
    return !!foundAptNum;
  });

  if (foundEntranceNum && foundAptNum) {
    return {
      entranceNum: foundEntranceNum,
      aptNum: foundAptNum,
    };
  }
};

export const useEntranceForAptId: (aptId?: string) => {
  entranceNum?: number;
  aptNum?: number;
  isFetching: boolean;
} = (aptId) => {
  const { propertyId } = useCurrentRole();
  const { isFetching, data: ownersData } = useQueryOwners(propertyId);

  const { entranceNum, aptNum }: EntranceAndAptNums =
    isFetching || !aptId
      ? ({} as EntranceAndAptNums)
      : findEntranceForAptId(ownersData, aptId) ?? ({} as EntranceAndAptNums);

  return { isFetching, entranceNum, aptNum };
};
