import { Appearance, Pill } from '#common/Pill';
import { useTranslation } from 'react-i18next';
import { ProjectStatus } from '../../../api';
import { FC } from 'react';
import { Project } from 'api';
import { tKeys } from '../../../translationKeys';

const statusToAppearance: Record<ProjectStatus, Appearance> = {
  [ProjectStatus.active]: Appearance.active,
  [ProjectStatus.archived]: Appearance.archived,
  [ProjectStatus.draft]: Appearance.draft,
  [ProjectStatus.paused]: Appearance.draft,
};

const statusToKey: Record<ProjectStatus, string> = {
  [ProjectStatus.active]: tKeys.projects.table.column.statusActive(),
  [ProjectStatus.archived]: tKeys.projects.table.column.statusArchived(),
  [ProjectStatus.draft]: tKeys.projects.table.column.statusDraft(),
  [ProjectStatus.paused]: tKeys.projects.table.column.statusPaused(),
};

export const ProjectStatusPill: FC<{
  project: Partial<Project> & Pick<Project, 'status'>;
}> = ({ project: { status } }) => {
  const { t } = useTranslation();
  return (
    <Pill appearance={statusToAppearance[status]}>
      {t(statusToKey[status])}
    </Pill>
  );
};
