import {
  Button,
  Card,
  CardActions,
  CircularProgress,
  Container,
  FormControl,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useRef, useEffect, useState } from 'react';
import { api, InviteDataResponse } from 'api';
import { entranceString } from 'features/utils';
import { Trans, useTranslation } from 'react-i18next';
import { FullPageRollingBg } from '#common/FullPageRollingBg/FullPageRollingBg';
import { LanguageMenu } from '#common/AppBar/LanguageMenu';
import { Logo128 } from '#common/Logo128/Logo128';
import { useAppDispatch } from 'app/hooks';
import { setToken } from 'features/auth/authSlice';
import { tKeys } from 'translationKeys';
import { RtlTextField } from '#common/RtlTextField';
import { StyledLoginBox } from './StyledLoginBox';
import { linkForRole } from '../../features/auth/linkForRole';

export const PreAccept = () => {
  const { signinCode } = useParams();
  const [inviteData, setInviteData] = useState<InviteDataResponse | null>(null);
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const refName = useRef<HTMLInputElement>();
  const {
    t,
    i18n: { dir },
  } = useTranslation();
  const [acceptLoading, setAcceptLoading] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!inviteData && signinCode) {
      dispatch(setToken(''));
      api
        .fetchInviteData({ params: { signinCode } })
        .then((inviteData) => {
          setInviteData(inviteData as InviteDataResponse);
          setName(inviteData.name);
          refName.current?.focus();
        })
        .catch(() => {
          navigate('/', { replace: true });
        });
    }
  }, [inviteData, refName]);

  const onAccept = () => {
    if (!name) {
      refName.current!.focus();
      return;
    }
    setAcceptLoading(true);
    api
      .acceptInvite({ body: { signinCode: signinCode!, name } })
      .then(({ token, roles, properties }) => {
        dispatch(setToken(token));
        if (roles.length === 1) {
          const uniqueRole = { ...roles[0], property: properties[0] };
          navigate(linkForRole(uniqueRole));
        } else {
          navigate('/');
        }
      });
  };

  const onChangeName = (val: string) => setName(val);

  const renderDataBox = (data: InviteDataResponse) => {
    if (acceptLoading) {
      return <CircularProgress />;
    }
    return (
      <div style={{ direction: dir() }}>
        <p>
          <Trans
            i18nKey={tKeys.preAccept.phrase()}
            values={{
              name: data.invitor.name,
              email: data.invitor.email,
            }}
            components={{
              strong: <strong />,
              mailLink: <a href={`mailto:${data.invitor.email}`}>xx</a>,
            }}
          />
        </p>

        <p>
          <Trans
            i18nKey={tKeys.preAccept.phraseContinued()}
            values={{ role: t(`roleSelector.subtitle.role_${data.roleType}`) }}
            components={{ strong: <strong /> }}
          />
        </p>
        <div style={{ marginInlineStart: '2em' }}>
          <p>{data.property.address}</p>
          <p>
            {entranceString(t, data.property, data.entrance)}{' '}
            {data.apt && <span>Apt. {data.apt}</span>}
          </p>
        </div>

        <FormControl fullWidth style={{ marginTop: 20, marginBottom: 20 }}>
          <RtlTextField
            InputProps={{ readOnly: true, style: { color: '#666' } }}
            value={data.email}
            label={t(tKeys.preAccept.yourEmail())}
          />
        </FormControl>

        <FormControl fullWidth style={{ marginBottom: 20 }}>
          <RtlTextField
            inputRef={refName}
            value={name}
            label={t(tKeys.preAccept.yourName())}
            onChange={(e) => onChangeName(e.target.value)}
          />
        </FormControl>

        <CardActions
          style={{
            borderTop: 'solid 1px silver',
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
          }}
        >
          <Button variant="contained" onClick={() => onAccept()}>
            {t(tKeys.preAccept.acceptButton())}
          </Button>
          <LanguageMenu />
        </CardActions>
      </div>
    );
  };

  return (
    <FullPageRollingBg>
      <div style={{ height: '100%', position: 'relative' }}>
        <div
          style={{
            margin: 0,
            width: '100%',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          <Container maxWidth="sm">
            <Card>
              <StyledLoginBox>
                <Logo128 />
                {!inviteData && <CircularProgress />}
                {inviteData && renderDataBox(inviteData)}
              </StyledLoginBox>
            </Card>
          </Container>
        </div>
      </div>
    </FullPageRollingBg>
  );
};
