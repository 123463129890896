import React, { useCallback, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  checkTokenAsync,
  pickRole,
  selectIsLogged,
  selectNetworkError,
  selectRole,
  selectRoles,
  selectToken,
} from 'features/auth/authSlice';
import { useUrlParser } from 'features/urlParser';
import { NetworkError } from '#common/NetworkError';
import { FullPageLoader } from '#common/FullPageLoader';
import { App } from './App';
import { Paths } from 'app/Paths';
import { RoleType } from 'api';

export const LoggedInWall = () => {
  const { isLogged } = useAppSelector(selectIsLogged);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken);
  const urlMatch = useUrlParser();
  const roles = useAppSelector(selectRoles);
  const activeRole = useAppSelector(selectRole);
  const isNetworkError = useAppSelector(selectNetworkError);

  const findRoleIdForUrl = useCallback(
    () =>
      roles.find(
        (role) =>
          role.role === urlMatch.role &&
          role.propertyId === urlMatch.propertyId &&
          (urlMatch.domain === 'prop' ||
            urlMatch.aptId === role.aptId ||
            (urlMatch.domain === 'apt' && role.role === RoleType.VAAD))
      )?.id,
    [roles, urlMatch]
  );

  useEffect(() => {
    if (!isLogged && !isLoading) {
      if (token) {
        setLoading(true);
        dispatch(checkTokenAsync());
      }
    }
  }, [dispatch, isLogged, isLoading, token]);

  useEffect(() => {
    if (isLogged && token) {
      const roleId = findRoleIdForUrl();
      if (roleId) {
        if (activeRole?.id !== roleId) {
          dispatch(pickRole(roleId));
        }
      }
    }
  }, [
    findRoleIdForUrl,
    activeRole,
    dispatch,
    isLogged,
    roles,
    token,
    urlMatch,
  ]);

  if (token && isLogged && !findRoleIdForUrl() && urlMatch.roleLetter) {
    return <Navigate to={Paths.roles} />;
  }

  if (!token || isLogged) {
    return (
      <>
        {isNetworkError && <NetworkError />}
        <App />
      </>
    );
  }

  return (
    <>
      {isNetworkError && <NetworkError />}
      <FullPageLoader />
    </>
  );
};
