import { GenericBreadcrumbs } from '#common/Breadcrumbs/GenericBreadcrumbs';
import { Paths } from 'app/Paths';
import { FlowItem } from '#common/Breadcrumbs/FlowItem';
import { tKeys } from 'translationKeys';
import { useTranslation } from 'react-i18next';

const root = Paths.property.owners;

const useOwnersFlow = (): FlowItem[] => {
  const { t } = useTranslation();

  return [
    {
      title: t(tKeys.ownersPage.title()),
      index: true,
      crumbs: [t(tKeys.ownersPage.title())],
    },
  ];
};

export const OwnersBreadcrumbs = () => {
  const flowItems = useOwnersFlow();
  return <GenericBreadcrumbs root={root} items={flowItems} />;
};
