import { css } from '@emotion/css';

export const EmptyState = css`
  min-height: 375px;
`;

export const EmptyStateContent = css`
  text-align: center;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 42px;
`;
