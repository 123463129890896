import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { initStore } from './app/store';
import { configureAxios } from 'api';
import * as serviceWorker from './serviceWorker';
import './i18n';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createRoot } from 'react-dom/client';
import { queryClient } from './features/queryClient';
import { AppRoutes } from './components/App/AppRoutes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DateLocalizationProvider } from './components/App/DateLocalizationProvider';
import { StyledEngineProvider } from '@mui/material/styles';

const Root = ({
  store,
  queryClient,
}: {
  store: ReturnType<typeof initStore>;
  queryClient: QueryClient;
}) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Suspense fallback={<p />}>
          <DateLocalizationProvider>
            <BrowserRouter>
              <ToastContainer />
              <StyledEngineProvider injectFirst>
                <AppRoutes />
              </StyledEngineProvider>
            </BrowserRouter>
          </DateLocalizationProvider>
        </Suspense>
      </Provider>
    </QueryClientProvider>
  );
};

export function start({ apiRoot }: { apiRoot: string }) {
  const store = initStore();
  configureAxios({ root: apiRoot, dispatch: store.dispatch });

  createRoot(document.getElementById('root')!).render(
    <Root store={store} queryClient={queryClient} />
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
}
