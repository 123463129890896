import { Trans } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import { tKeys } from 'translationKeys';
import { useQueryProject } from 'features/data/queries/projects';
import { CircularProgress } from '@mui/material';
import { ProjectPageLocationState } from './ProjectPageLocationState';

export const ViewProjectTitle = () => {
  const { projectId } = useParams<'projectId'>();
  const locationState = useLocation().state as ProjectPageLocationState;
  const { isFetching, data: project } = useQueryProject(projectId!);
  const projectName = locationState?.project?.name ?? project?.name;
  const projectNameComponent =
    locationState?.project?.name ?? (!isFetching && project) ? (
      <span />
    ) : (
      <CircularProgress />
    );

  return (
    <Trans
      i18nKey={tKeys.projectsPage.viewProject.title()}
      values={{ name: projectName }}
      components={{
        name: projectNameComponent,
      }}
    />
  );
};
