import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, List } from '@mui/material';
import PieChartIcon from '@mui/icons-material/PieChartTwoTone';
import BuildingBankIcon from '@mui/icons-material/AccountBalanceWalletTwoTone';
import PaintWorkIcon from '@mui/icons-material/FormatPaintTwoTone';
import OwnerIcon from '@mui/icons-material/VpnKeyTwoTone';
import VaadIcon from '@mui/icons-material/WorkTwoTone';
import { Item } from './Item';
import { useCurrentRole } from 'app/hooks';
import { Subheader } from './Subheader';
import { tKeys } from 'translationKeys';
import { Paths, usePropertyPath } from 'app/Paths';

export const MenuListBuilding = () => {
  const { t } = useTranslation();
  const { generatePath } = usePropertyPath();
  const { isVaad, isTenant } = useCurrentRole();

  return (
    <>
      <Divider />
      <List>
        <Subheader text={t(tKeys.menu.header.building())} />

        {isVaad && (
          <Item
            menuId="owners"
            Icon={OwnerIcon}
            text={t(tKeys.menu.building.owners())}
            to={generatePath(Paths.property.owners)}
          />
        )}

        <Item
          menuId="budget"
          Icon={PieChartIcon}
          text={t(tKeys.menu.building.budget())}
          to={generatePath(Paths.property.budget)}
        />

        {!isTenant && (
          <Item
            menuId="projects"
            Icon={PaintWorkIcon}
            text={t(tKeys.menu.building.projects())}
            to={generatePath(Paths.property.projects.list)}
          />
        )}

        <Item
          menuId="bank"
          Icon={BuildingBankIcon}
          text={t(tKeys.menu.building.bank())}
          to={generatePath(Paths.property.bank)}
        />

        <Item
          menuId="vaad"
          Icon={VaadIcon}
          text={t(tKeys.menu.building.vaad())}
          to={generatePath(Paths.property.vaad)}
        />
      </List>
    </>
  );
};
