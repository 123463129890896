import i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: localStorage?.getItem('language') || 'he',
    nsSeparator: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;

const formatCurrency = (
  languageCode: string,
  amount: number,
  withPlus?: boolean
): string =>
  (withPlus && amount > 0 ? '+' : '') +
  new Intl.NumberFormat([languageCode], {
    style: 'currency',
    currency: 'ILS',
    minimumFractionDigits: 0,
  }).format(amount);

export const useFormat = () => {
  const {
    i18n: { language },
  } = useTranslation();
  return {
    formatCurrency: (amount: number, withPlus?: boolean) =>
      formatCurrency(language, amount, withPlus),
    formatCurrency2: (amount: number) => formatCurrency2(language, amount),
    formatDateMonthNameAndYear: (date: Date) =>
      formatDateMonthNameAndYear(date, language),
  };
};

const formatCurrency2 = (languageCode: string, amount: number): string =>
  new Intl.NumberFormat([languageCode], {
    style: 'currency',
    currency: 'ILS',
    minimumFractionDigits: 2,
  }).format(amount);

export const formatDateShort = (date: Date, locale?: string): string =>
  new Intl.DateTimeFormat(locale, {
    dateStyle: 'short',
  }).format(date);

export const formatDateMonthNameAndYear = (
  date: Date,
  locale?: string
): string =>
  new Intl.DateTimeFormat(locale, {
    month: 'long',
    year: 'numeric',
  }).format(date);
