import { useAppDispatch, useAppSelector, useCurrentLanguage } from 'app/hooks';
import { selectIsLogged, checkTokenAsync } from 'features/auth/authSlice';
import {
  selectIsLoading,
  startLoading,
  stopLoading,
} from 'features/menu/menuSlice';
import { useEffect, useState } from 'react';
import { LoginBox } from './LoginBox';
import { api } from 'api';
import { tokenManager } from 'features/token-manager';

export const LoginPage = () => {
  const lang = useCurrentLanguage();
  const { isLogged } = useAppSelector(selectIsLogged);
  const isLoading = useAppSelector(selectIsLoading);
  const [isSent, setIsSent] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isLogged && !isLoading) {
      const token = tokenManager.get();
      if (token) {
        dispatch(checkTokenAsync());
      }
    }
  });

  const performLogin = (email: string) => {
    if (email) {
      dispatch(startLoading());
      const redir = new URLSearchParams(location.search).get('r') ?? undefined;
      api.login({ body: { email, lang, captcha: 'foo', redir } }).then(() => {
        setTimeout(() => {
          setIsSent(true);
          dispatch(stopLoading());
        }, 3000);
      });
    }
  };

  return (
    <LoginBox
      isLoading={isLoading}
      isSent={isSent}
      performLogin={performLogin}
    />
  );
};
