import { PropsWithChildren, useEffect, useState } from 'react';
import * as classes from './classes';

export const FullPageRollingBg = (props: PropsWithChildren<unknown>) => {
  const [backgroundImg, setBackgroundImg] = useState('');

  useEffect(() => {
    if (!backgroundImg) {
      fetch('/data/backgrounds.json')
        .then((response) => response.json())
        .then((backgrounds) => {
          setBackgroundImg(
            backgrounds[Math.floor(Math.random() * 100) % backgrounds.length]
          );
        });
    }
  });

  return (
    <>
      <div
        className={classes.background}
        style={{
          backgroundImage:
            (backgroundImg && `url(${backgroundImg})`) || undefined,
        }}
      />
      <div className={classes.blur} />

      {props.children}
    </>
  );
};
