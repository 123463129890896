import { css } from '@emotion/css';

export const nude = css`
  text-decoration: none;
  color: inherit;
`;

export const round = css`
  border-radius: 10px;
`;
