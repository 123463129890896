import { PageSubTitle, PageTitle } from '../PageTitle';
import styled from '@emotion/styled';
import { FC } from 'react';

type Props = {
  title?: string | React.ReactElement;
  subtitle?: string;
};

const StyledOuterDiv = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const StyledInnerDiv = styled.div`
  padding-inline-start: 20px;
  padding-inline-end: 20px;
  max-width: 1440px;
  margin: auto;
`;

const ThinLine = ({ widthPerc }: { widthPerc: number }) => {
  return (
    <div
      style={{
        height: 1,
        width: `${widthPerc}%`,
        backgroundColor: 'silver',
        margin: 'auto',
        marginBottom: 3,
      }}
    />
  );
};

export const Page: FC<React.PropsWithChildren<Props>> = ({
  title,
  subtitle,
  children,
}) => {
  return (
    <StyledOuterDiv>
      <StyledInnerDiv>
        <div style={{ textAlign: 'center' }}>
          {title && <PageTitle title={title} />}
          <ThinLine widthPerc={40} />
          <ThinLine widthPerc={30} />
          <PageSubTitle title={subtitle} />
        </div>

        {children}
      </StyledInnerDiv>
    </StyledOuterDiv>
  );
};
