import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

type SvgFile = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & { title?: string }
>;

export const makeIcon =
  (icon: SvgFile) =>
  // eslint-disable-next-line react/display-name
  (props: SvgIconProps & { className?: string }): JSX.Element =>
    <SvgIcon component={icon} {...props} viewBox="0 0 24 24" />;
