import { start } from './app';

declare const API_ROOT: string;

const config = JSON.parse(
  document.getElementById('runtime-config')!.textContent!
);
Object.assign(config, {
  ...config,
  api: localStorage.getItem('api-endpoint') || config.api,
});

start({ apiRoot: API_ROOT || config.api });
