import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';
import TenantIcon from '@mui/icons-material/HotelTwoTone';
import OwnerIcon from '@mui/icons-material/VpnKeyTwoTone';
import VaadIcon from '@mui/icons-material/WorkTwoTone';
import { RoleType } from '@vaad/client';

const iconForRole = {
  [RoleType.TENANT]: TenantIcon,
  [RoleType.OWNER]: OwnerIcon,
  [RoleType.VAAD]: VaadIcon,
};

const WrappedIcon = ({
  Icon,
}: {
  Icon: OverridableComponent<SvgIconTypeMap>;
}) => {
  return <Icon color="primary" style={{ fontSize: '100px' }} />;
};

export const RoleIcon = ({ roleType }: { roleType: RoleType }) => (
  <WrappedIcon Icon={iconForRole[roleType]} />
);
