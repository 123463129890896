import { useState } from 'react';
import { Paper, Typography } from '@mui/material';
import { cx } from '@emotion/css';
import * as classes from './classes';

interface Props {
  title: string;
  subtitle?: string;
  className?: string;
  contentClassName?: string;
  footer?: React.ReactNode;
  elevateOnHover?: boolean;
}

export const Vignette = (props: React.PropsWithChildren<Props>) => {
  const [elevation, setElevation] = useState(1);

  const mouseLeave = () => {
    setTimeout(() => {
      setElevation(1);
    }, 10);
  };

  const mouseEnter = () => {
    if (props.elevateOnHover) {
      setElevation(6);
    }
  };

  return (
    <div onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
      <Paper
        className={cx(classes.paper, props.className, {
          moveY: elevation === 6,
        })}
        elevation={elevation}
      >
        <div className={cx(classes.cardContent, props.contentClassName)}>
          <Typography className={classes.title}>{props.title}</Typography>
          <Typography color="textSecondary" className={classes.subtitle}>
            {props.subtitle}
          </Typography>
          {props.children}
        </div>
        <div>{props.footer}</div>
      </Paper>
    </div>
  );
};
