import {
  Button,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Gravatar } from '#common/Gravatar/Gravatar';
import { expiresInT } from '../../../app/constants';
import * as s from './classes';
import { anonemail } from '@vaad/utils';
import { tKeys } from 'translationKeys';
import { InviteData } from './types';

export const InvitesMembers = ({
  invites,
  onRevoke,
}: {
  invites: InviteData[];
  onRevoke: (id: string) => void;
}) => {
  const { t } = useTranslation();

  const [hovering, setHovering] = useState({} as { [email: string]: boolean });

  const onHoverOverButton = (email: string) => {
    setHovering({ ...hovering, [email]: true });
  };

  const onHoverOutButton = (email: string) => {
    setHovering({ ...hovering, [email]: false });
  };

  const expiresIn = expiresInT(t);

  const renderInvitesRows = () => (
    <TableContainer>
      <Table>
        <TableBody>
          {invites.map((invite) => (
            <TableRow key={invite.invitee.email}>
              <TableCell>
                <Gravatar email={invite.invitee.email} />
              </TableCell>
              <TableCell>
                <div>{invite.invitee.name}</div>
                <div className={s.email}>{anonemail(invite.invitee.email)}</div>
              </TableCell>
              <TableCell style={{ textAlign: 'center' }}>
                {invite.id && (
                  <Button
                    color={
                      hovering[invite.invitee.email] ? 'primary' : 'secondary'
                    }
                    variant={
                      hovering[invite.invitee.email] ? 'contained' : 'outlined'
                    }
                    onMouseOver={() => onHoverOverButton(invite.invitee.email)}
                    onMouseOut={() => onHoverOutButton(invite.invitee.email)}
                    onClick={() => onRevoke(invite.id)}
                  >
                    {t(tKeys.vaadMembersPage.button.revoke())}
                  </Button>
                )}
                <br />
                {invite.expires > 0 && (
                  <div className={s.expires}>
                    {t(tKeys.invite.pending.expiresIn(), {
                      expiresIn: expiresIn(invite.expires),
                    })}
                  </div>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderEmpty = () => (
    <Typography className={s.nothingToDisplay}>
      {t(tKeys.vaadMembersPage.cardInvites.nothingToDisplay())}
    </Typography>
  );

  return (
    <Card>
      <CardContent>
        <Typography component="h6">
          {t(tKeys.vaadMembersPage.cardInvites.title())}
        </Typography>
        {invites.length ? renderInvitesRows() : renderEmpty()}
      </CardContent>
    </Card>
  );
};
