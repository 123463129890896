import { RoleType } from 'api';
import { UseTranslationResponse } from 'react-i18next';
import { tKeys } from '../translationKeys';

export const prefixForRole = (role: RoleType): string => {
  return role.substr(0, 1);
};

export const expiresInT =
  (t: UseTranslationResponse<string>[0]) => (expires: number) => {
    const now = Date.now();
    const days = Math.floor((expires - now) / 84600000);
    if (days > 2) {
      return t(tKeys.invite.pending.expiresInDays(), { days });
    }
    if (days > 1) {
      return t(tKeys.invite.pending.expiresIn2Days());
    }
    if (days > 0) {
      return t(tKeys.invite.pending.expiresTomorrow());
    }
    const hours = Math.floor((expires - now) / 3600000);
    if (hours > 2) {
      return t(tKeys.invite.pending.expiresInHours(), { hours });
    }
    if (hours > 1) {
      return t(tKeys.invite.pending.expiresIn2Hours());
    }
    return t(tKeys.invite.pending.expiresInLessThan1Hour());
  };
