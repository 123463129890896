import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Menu from '@mui/material/Menu';
import styled from '@emotion/styled';
import { tKeys } from 'translationKeys';
import { Button } from '@mui/material';
import { DesktopOnly } from '#common/MediaQuery';

const StyledLanguageCode = styled.span`
  text-transform: uppercase;
  padding-inline-end: 0.3rem;
`;

const StyledHorizontalPadded = styled.div`
  padding-inline-end: 1rem;
`;

const StyledNoTransformButton = styled(Button)`
  text-transform: unset;
`;

export const LanguageMenu = () => {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  const menuOpen = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lang: string) => {
    localStorage.setItem('language', lang);
    handleClose();
    i18n.changeLanguage(lang);
  };

  return (
    <StyledHorizontalPadded>
      <StyledNoTransformButton
        aria-label="current language"
        aria-controls="menu-languages"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <StyledLanguageCode>{t(tKeys.languageCode())}</StyledLanguageCode>
        <DesktopOnly>| {t(tKeys.languageName())}</DesktopOnly>
      </StyledNoTransformButton>
      <Menu
        id="menu-languages"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={menuOpen}
        onClose={handleClose}
      >
        <MenuItem onClick={() => changeLanguage('en')}>EN | English</MenuItem>
        <MenuItem onClick={() => changeLanguage('fr')}>FR | Français</MenuItem>
        <MenuItem onClick={() => changeLanguage('he')}>HE | עברית</MenuItem>
      </Menu>
    </StyledHorizontalPadded>
  );
};
