import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useAppSelector } from 'app/hooks';
import { selectRole } from 'features/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  FormGroup,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { tKeys } from 'translationKeys';
import { VerticalForm } from '#common/VerticalForm';
import { ApartmentSelector } from '#common/ApartmentSelector';
import { generatePropertyPath, Paths } from 'app/Paths';
import { useClasses } from '#common/styles-utils';
import { SaveActionBar } from '#common/SaveActionBar';
import { DatePicker } from '@mui/x-date-pickers';
import { useMutationNewPayment } from '../../../features/data/queries';
import { toastSuccess } from '#common/toasts';
import { useCreatePayment } from './useCreatePayment';
import { PaymentDispatch } from 'api';

const styles = () => ({
  input: {
    textAlign: 'right',
  },
});

const PaymentDispatchSwitch = ({
  dispatch,
  onChange,
}: {
  dispatch: PaymentDispatch;
  onChange: (val: PaymentDispatch) => void;
}) => {
  const { t } = useTranslation();

  return (
    <ToggleButtonGroup
      exclusive
      value={dispatch}
      onChange={(_, val) => onChange(val)}
    >
      <ToggleButton value="budget">
        {t(tKeys.cheques.managePage.newCheque.dispatchBudget())}
      </ToggleButton>
      <ToggleButton value="project">
        {t(tKeys.cheques.managePage.newCheque.dispatchProject())}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export const CreatePayment = () => {
  const { minDate, maxDate, aptId, onChangeApt } = useCreatePayment();

  const { t } = useTranslation();
  const [amount, setAmount] = useState(0);
  const [date, setDate] = useState(new Date(Date.now()));
  const [paymentDispatch, setPaymentDispatch] = useState(
    PaymentDispatch.BUDGET
  );
  const role = useAppSelector(selectRole)!;
  const { property } = role;
  const navigate = useNavigate();
  const classes = useClasses(styles);
  const mutationNewPayment = useMutationNewPayment();

  const onChangeAmount = (val: number) => {
    setAmount(val);
  };

  const onCancel = () => {
    navigate(
      generatePropertyPath(Paths.property.cheques.list, {
        role: role.role,
        propId: role.propertyId,
      })
    );
  };

  const onChangeDate = (val: Date | null) => {
    if (!val || !('getFullYear' in val)) {
      return;
    }
    setDate(new Date(val));
  };

  const canSave = !(!amount || !aptId);

  const onSave = () => {
    if (!canSave) {
      return;
    }

    mutationNewPayment.mutate(
      {
        aptId,
        amount,
        date: date.toISOString().replace(/T.*$/, ''),
        dispatch: paymentDispatch,
      },
      {
        onSuccess() {
          toastSuccess(t(tKeys.payments.managePage.newPayment.success()));
          navigate(
            generatePropertyPath(Paths.property.payments.list, {
              propId: role.propertyId,
              role: role.role,
            })
          );
        },
      }
    );
  };

  return (
    <Card>
      <CardHeader title={t(tKeys.payments.managePage.newPayment.title())} />
      <CardContent>
        <Container maxWidth="sm">
          <FormGroup>
            <VerticalForm
              lines={[
                {
                  label: t(tKeys.cheques.managePage.newCheque.apartment()),
                  value: (
                    <ApartmentSelector
                      data-testid="apartment-selector"
                      property={property}
                      aptId={aptId}
                      onChange={onChangeApt}
                    />
                  ),
                },
                {
                  label: t(tKeys.cheques.managePage.newCheque.amount()),
                  value: (
                    <TextField
                      data-testid="textfield-amount"
                      value={amount === 0 ? '' : amount}
                      type="number"
                      InputProps={{ classes }}
                      onChange={(evt) =>
                        onChangeAmount(parseFloat(evt.target.value))
                      }
                    />
                  ),
                },
                {
                  label: t(tKeys.cheques.managePage.newCheque.date()),
                  value: (
                    <DatePicker
                      minDate={minDate}
                      maxDate={maxDate}
                      views={['year', 'month', 'day']}
                      openTo={'month'}
                      value={new Date(date)}
                      onChange={onChangeDate}
                      renderInput={(params) => (
                        <TextField {...params} helperText={null} />
                      )}
                    />
                  ),
                },
                {
                  label: t(tKeys.cheques.managePage.newCheque.dispatch()),
                  value: (
                    <PaymentDispatchSwitch
                      dispatch={paymentDispatch}
                      onChange={setPaymentDispatch}
                    />
                  ),
                },
              ]}
            />
          </FormGroup>

          <SaveActionBar
            canSave={canSave}
            onSave={onSave}
            onCancel={onCancel}
          />
        </Container>
      </CardContent>
    </Card>
  );
};
