import { Avatar } from '@mui/material';
import { avatarSrc } from '../../../features/utils';

export const Gravatar = ({
  email,
  hash,
  defaultType,
}: {
  email?: string;
  hash?: string;
  defaultType?: 'silhouette' | 'retro';
}) => {
  return <Avatar src={avatarSrc({ email, hash, defaultType })} />;
};
