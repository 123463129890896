import { useMutation, useQuery, useQueryClient } from 'react-query';
import { api, TenantDataResponse } from 'api';
import { useCurrentRole } from 'app/hooks';
import { tokenManager } from '../../token-manager';
import { keyTenantData } from './keys';

export type TenantInfo = NonNullable<TenantDataResponse['tenant']>;

export const useQueryTenant = () => {
  const { roleId } = useCurrentRole();
  return useQuery<TenantDataResponse>(keyTenantData(roleId), () =>
    api.getTenantInfo({ token: tokenManager.get(), params: { roleId } })
  );
};

export const useMutationRevokeTenant = () => {
  const { roleId } = useCurrentRole();
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      return api.revokeTenant({
        token: tokenManager.get(),
        params: {
          roleId,
        },
      });
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(keyTenantData(roleId));
      },
    }
  );
};
