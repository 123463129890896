import { cx } from '@emotion/css';
import {
  AppBar as MuiAppBar,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
import { styles } from './styles';
import { UserMenu } from './UserMenu';
import { LanguageMenu } from './LanguageMenu';
import { useAppSelector } from 'app/hooks';
import { selectRole } from 'features/auth/authSlice';
import { useClasses } from '../styles-utils';
import { RoleType } from '@vaad/client';
import { apartmentTitle } from 'features/utils';
import { useMenuOpen } from '../../Drawer/useMenuOpen';
import { Notifications } from './Notifications';
import { DesktopOnly, MediaHideIfSmall, MobileOnly } from '#common/MediaQuery';
import { tKeys } from '../../../translationKeys';

type AppBarProps = {
  drawerWidth?: number;
  fullWidth?: boolean;
  onOpenDrawer?: () => void;
  role?: RoleType | null;
  noAddress?: boolean;
  title?: string;
};

const keyMyAccountForRole: Record<RoleType, () => string> = {
  [RoleType.VAAD]: tKeys.appBar.myAccount_vaad,
  [RoleType.OWNER]: tKeys.appBar.myAccount_owner,
  [RoleType.TENANT]: tKeys.appBar.myAccount_tenant,
};

const keyRoleType: Record<RoleType, () => string> = {
  [RoleType.VAAD]: tKeys.appBar.roleType_vaad,
  [RoleType.OWNER]: tKeys.appBar.roleType_owner,
  [RoleType.TENANT]: tKeys.appBar.roleType_tenant,
};

export const AppBar = ({
  drawerWidth,
  fullWidth,
  onOpenDrawer,
  role,
  noAddress,
  title,
}: AppBarProps) => {
  const { t } = useTranslation();
  const { isOpen: isStateOpen } = useMenuOpen();
  const classes = useClasses(styles({ drawerWidth: drawerWidth || 0 }));
  const activeRole = useAppSelector(selectRole);
  const effectiveRole = role || activeRole?.role;

  const isOpen = fullWidth ? false : isStateOpen;

  return (
    <MuiAppBar
      position="absolute"
      className={cx(classes.appBar, isOpen && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        {onOpenDrawer && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => onOpenDrawer && onOpenDrawer()}
            className={cx(
              classes.menuButton,
              isOpen && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
        )}

        <div className={classes.title}>
          <Typography variant="h6">
            {(role !== null && effectiveRole && (
              <>
                <DesktopOnly>
                  {t(keyMyAccountForRole[effectiveRole]())}
                </DesktopOnly>
                <MobileOnly>{t(keyRoleType[effectiveRole]())}</MobileOnly>
              </>
            )) ||
              title}
          </Typography>

          {!noAddress && activeRole && activeRole.property && (
            <MediaHideIfSmall cut={isOpen && 700}>
              <Typography>
                {activeRole.property.address}
                {activeRole.role !== RoleType.VAAD &&
                  ` / ${apartmentTitle(t, activeRole)}`}
              </Typography>
            </MediaHideIfSmall>
          )}
        </div>
        <LanguageMenu />
        <Notifications />
        <UserMenu />
      </Toolbar>
    </MuiAppBar>
  );
};
