import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import { useTranslation } from 'react-i18next';
import { tKeys } from 'translationKeys';
import { EmptyState } from '#common/EmptyState';

export const OwnersPageEmptyState = ({
  onClickEmptyState,
}: {
  onClickEmptyState: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <EmptyState
      tagline={t(tKeys.ownersPage.emptyState.tagline())}
      description={t(tKeys.ownersPage.emptyState.description())}
      button={t(tKeys.ownersPage.emptyState.button())}
      onClick={() => {
        onClickEmptyState();
      }}
      icon={PeopleAltTwoToneIcon}
    />
  );
};
