import { Card, CardContent, Container } from '@mui/material';
import { useFormat, formatDateShort } from '../../../i18n';
import { useTranslation } from 'react-i18next';
import { Payment } from '@vaad/client';
import { TableList } from '#common/TableList';
import { makeDateFromYmd } from '@vaad/utils';
import { tKeys } from 'translationKeys';

export const PaymentsTable = ({ payments }: { payments: Payment[] }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { formatCurrency2 } = useFormat();

  return (
    <Container maxWidth="sm">
      <Card>
        <CardContent>
          <TableList<(typeof payments)[0]>
            paper
            size="small"
            columns={[
              {
                title: t(tKeys.table.column.date()),
                render: (row) =>
                  formatDateShort(makeDateFromYmd(row.date), language),
              },
              {
                title: t(tKeys.table.column.amount()),
                align: 'right',
                render: (row) => formatCurrency2(row.amount),
              },
              {
                title: t(tKeys.payments.table.column.type()),
                render: (row) => t(`payments.types.${row.type}`),
              },
              {
                title: t(tKeys.payments.table.column.ref()),
                render: (row) => row.ref,
              },
            ]}
            items={payments}
          />
        </CardContent>
      </Card>
    </Container>
  );
};
