import { TextField, TextFieldProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useClasses } from './styles-utils';
import styled from '@emotion/styled';

const styles = (dir: 'ltr' | 'rtl') => () => ({
  root: {
    right: dir === 'rtl' ? '30px' : 'unset',
    left: dir === 'rtl' ? 'unset' : 0,
    transformOrigin: dir === 'rtl' ? 'top right' : 'top-left',
  },
  shrink: {
    backgroundColor: 'white',
    paddingInline: '6px',
  },
  helper: {
    textAlign: 'unset',
    backgroundColor: 'yellow',
  },
});

const RtlDivOutlinedLegendSpanRemoved = styled(TextField)`
  & legend > span {
    display: none;
`;

export const RtlTextField = (props: TextFieldProps) => {
  const {
    i18n: { dir },
  } = useTranslation();
  const classes = useClasses(styles(dir()));

  if (props.variant === 'outlined') {
    return (
      <TextField
        {...props}
        InputLabelProps={{
          classes: { root: classes.root, shrink: classes.shrink },
        }}
        InputProps={{ notched: false }}
        FormHelperTextProps={{ classes: { root: classes.helper } }}
      />
    );
  }

  const DirectionalTextField =
    dir() === 'rtl' ? RtlDivOutlinedLegendSpanRemoved : TextField;

  return (
    <DirectionalTextField
      {...props}
      InputLabelProps={{
        classes: { root: classes.root, shrink: classes.shrink },
      }}
      FormHelperTextProps={{ classes: { root: classes.helper } }}
    />
  );
};
