import { useMemo } from 'react';
import { css } from '@emotion/css';
import { useTheme } from '@emotion/react';
import { Theme } from '@mui/material';
import { PaletteColorEx } from '../Board/AreaThemeProvider/theme';

// https://dev.to/atonchev/material-ui-5-the-easiest-way-to-migrate-from-makestyles-to-emotion-1i9l

// eslint-disable-next-line @typescript-eslint/ban-types
export const useClasses = (stylesElement: Function | Object) => {
  const theme = useTheme();
  return useMemo(() => {
    const rawClasses =
      typeof stylesElement === 'function'
        ? stylesElement(theme)
        : stylesElement;
    const prepared: { [key: string]: ReturnType<typeof css> } = {};

    Object.entries(rawClasses).forEach(([key, value = {}]) => {
      prepared[key] = css(value as TemplateStringsArray);
    });

    return prepared;
  }, [stylesElement, theme]);
};

const roleBackgroundStyles = (theme: Theme) => ({
  contentBox: {
    paddingTop: '64px',
    width: '100%',
    minHeight: '100%',
  },
  page: {
    backgroundColor: (theme.palette.primary as PaletteColorEx).lighter,
  },
});

export const useRoleBackgroundClasses = () => useClasses(roleBackgroundStyles);
