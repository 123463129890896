import styled from '@emotion/styled';

export const StyledLoginBox = styled.div`
  background-color: white;
  padding: 0 30px;
  min-height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
