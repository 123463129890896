import { Page } from '#common/Page/Page';
import { useTranslation } from 'react-i18next';
import { tKeys } from 'translationKeys';
import { DocumentsEmptyState } from './DocumentsEmptyState';
import { useCurrentRole } from 'app/hooks';
import { VaadDocumentsEmptyState } from './VaadDocumentsEmptyState';

export const DocumentsPage = () => {
  const { t } = useTranslation();
  const { isVaad } = useCurrentRole();

  return (
    <Page
      title={t(tKeys.documents.page.title())}
      subtitle={t(tKeys.documents.page.subtitle())}
    >
      {isVaad ? <VaadDocumentsEmptyState /> : <DocumentsEmptyState />}
    </Page>
  );
};
