import React from 'react';
import { cx } from '@emotion/css';
import { IconButton, Drawer as MuiDrawer } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styles } from './styles';
import { MenuListOwner } from './MenuListOwner';
import { MenuListBuilding } from './MenuListBuilding';
import { useAppSelector } from 'app/hooks';
import { selectRole } from 'features/auth/authSlice';
import { RoleType } from 'api';
import { MenuListTenant } from './MenuListTenant';
import { MenuListRenter } from './MenuListRenter';
import { MenuListVaadMoney } from './MenuListVaadMoney';
import { useClasses } from '#common/styles-utils';
import { MenuListCommunity } from './MenuListCommunity';
import { useMenuOpen } from './useMenuOpen';
import { useUrlParser } from 'features/urlParser';

export const Drawer = ({
  width,
  onClose,
}: {
  width: number;
  onClose: () => void;
}) => {
  const { isOpen } = useMenuOpen();
  const activeRole = useAppSelector(selectRole)!;
  const classes = useClasses(styles({ width }));
  const {
    i18n: { dir },
  } = useTranslation();

  const urlMatch = useUrlParser();

  return (
    <MuiDrawer
      anchor={dir() === 'rtl' ? 'right' : 'left'}
      variant="permanent"
      classes={{
        paper: cx(classes.drawerPaper, !isOpen && classes.drawerPaperClose),
      }}
      open={isOpen}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={() => onClose()}>
          {dir() === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </div>

      {RoleType.OWNER === activeRole.role && (
        <>
          <MenuListOwner />
          <MenuListRenter />
        </>
      )}

      {RoleType.TENANT === activeRole.role && <MenuListTenant />}

      {RoleType.VAAD === activeRole.role && urlMatch.domain === 'apt' && (
        <MenuListOwner aptId={urlMatch.aptId} />
      )}

      <MenuListBuilding />

      {RoleType.VAAD === activeRole.role && <MenuListVaadMoney />}

      <MenuListCommunity />
    </MuiDrawer>
  );
};
