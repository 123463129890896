import { Outlet } from 'react-router-dom';
import { cx } from '@emotion/css';
import { useTranslation } from 'react-i18next';
import * as classes from './classes';
import React, { useEffect } from 'react';
import {
  useAppDispatch,
  useAppSelector,
  useNavigateToPropertyPath,
} from 'app/hooks';
import {
  clearNeedsPaidPlan,
  selectNeedsPaidPlan,
  selectRole,
} from 'features/auth/authSlice';
import { Paths } from 'app/Paths';

export const App = () => {
  const {
    i18n: { dir },
  } = useTranslation();
  const dispatch = useAppDispatch();
  const needsPaidPlan = useAppSelector(selectNeedsPaidPlan);
  const role = useAppSelector(selectRole)!;
  const navigateToPropertyPath = useNavigateToPropertyPath();

  useEffect(() => {
    if (needsPaidPlan) {
      navigateToPropertyPath(Paths.property.pricing, {
        role: role.role,
        propId: role.propertyId,
      });
      dispatch(clearNeedsPaidPlan());
    }
  }, [needsPaidPlan]);

  if (needsPaidPlan) {
    return null;
  }

  return (
    <div className={cx(classes.app, { [classes.rtl]: dir() === 'rtl' })}>
      <Outlet />
    </div>
  );
};
