import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormGroup,
  TextField,
} from '@mui/material';
import { tKeys } from '../../../translationKeys';
import { VerticalForm } from '#common/VerticalForm';
import { AmountTextField } from '#common/AmountTextField';
import { NumberTextField } from '#common/NumberTextField';
import { SaveActionBar } from '#common/SaveActionBar';
import { useTranslation } from 'react-i18next';
import { FC, useRef, useState } from 'react';
import { Project } from 'api';
import { makeDateFromYm, makeYearMonth } from '@vaad/utils';
import { DatePicker } from '@mui/x-date-pickers';

const Installment: FC<{
  amount: number;
  date: Date;
  onChangeDate(date: Date | null): void;
}> = ({ amount, date, onChangeDate }) => {
  return (
    <div style={{ display: 'flex', gap: '0.25rem' }}>
      <AmountTextField
        value={amount}
        onChange={(_evt) => {}}
        size="small"
        InputProps={{
          sx: { width: '8rem' },
        }}
      />
      <DatePicker
        minDate={new Date('2010-01-01')}
        views={['year', 'month']}
        openTo={'month'}
        value={new Date(date)}
        onChange={onChangeDate}
        InputProps={{ size: 'small' }}
        renderInput={(params) => <TextField {...params} helperText={null} />}
      />
    </div>
  );
};

interface Props {
  editedProject?: Partial<Project>;
  onConfirm: ({
    name,
    description,
    amount,
    installments,
  }: Pick<Project, 'name' | 'description' | 'amount' | 'installments'>) => void;
  onCancel(): void;
}

export const EditProjectForm: FC<Props> = ({
  editedProject,
  onConfirm,
  onCancel,
}) => {
  console.log('Awed again');
  const { t } = useTranslation();
  const [isMutating, setMutating] = useState(false);
  const [projectName, setProjectName] = useState(editedProject?.name ?? '');
  const inputRefDescription = useRef<HTMLInputElement>();
  const [amount, setAmount] = useState(editedProject?.amount ?? 0);
  const now = new Date(Date.now());
  const [installments, setInstallments] = useState(
    editedProject?.installments ?? [
      { amount: 0, ym: now.getFullYear() * 100 + now.getMonth() + 1 },
    ]
  );
  const [nbPayments, setNbPayments] = useState(
    editedProject?.installments?.length ?? 1
  );

  const canSave = !!projectName?.trim() && (amount ?? 0) > 0;

  const recalcPayments = (amount: number, nbPayments: number) => {
    const eachPayment = Math.floor(amount / nbPayments);
    const roundUp = amount % nbPayments;
    setInstallments(
      Array.from({ length: nbPayments }).map((_, i) => ({
        amount: eachPayment + (i < roundUp ? 1 : 0),
        ym: makeYearMonth(Date.now() + i * 32 * 24 * 3600 * 1000),
      }))
    );
  };

  const onChangeNbPayments = (val: number) => {
    const newValue = Math.max(val, 1);
    setNbPayments(newValue);
    recalcPayments(amount, newValue);
  };

  const onChangeInstallmentDate = (index: number) => (date: Date | null) => {
    if (!date) return;
    setInstallments(
      installments.map((installment, i) =>
        i !== index
          ? installment
          : { ...installment, ym: makeYearMonth(date.getTime()) }
      )
    );
  };

  const onChangeAmount = (val: number) => {
    const newAmount = Math.max(1, val);
    setAmount(newAmount);
    recalcPayments(newAmount, nbPayments);
  };

  const onClickConfirm = () => {
    setMutating(true);
    const description = inputRefDescription.current?.value ?? '';
    onConfirm({ name: projectName, description, amount, installments });
  };

  return (
    <Container maxWidth="md">
      <Card>
        <CardContent>
          <Container maxWidth="sm">
            <FormGroup>
              <VerticalForm
                lines={[
                  {
                    label: t(tKeys.projectsPage.newProject.name()),
                    value: (
                      <TextField
                        size="small"
                        value={projectName}
                        onChange={(evt) => setProjectName(evt.target.value)}
                      />
                    ),
                  },
                  ...(projectName
                    ? [
                        {
                          label: t(
                            tKeys.projectsPage.viewProject.description()
                          ),
                          value: (
                            <TextField
                              sx={{ width: '100%' }}
                              multiline
                              defaultValue={editedProject?.description ?? ''}
                              InputProps={{
                                inputRef: inputRefDescription,
                              }}
                            />
                          ),
                        },
                      ]
                    : []),
                  ...(projectName
                    ? [
                        {
                          label: t(tKeys.projectsPage.newProject.amount()),
                          value: (
                            <AmountTextField
                              size="small"
                              data-testid="textfield-amount"
                              value={amount === 0 ? '' : amount}
                              onChange={(evt) =>
                                onChangeAmount(parseFloat(evt.target.value))
                              }
                            />
                          ),
                        },
                      ]
                    : []),
                  ...(amount > 0
                    ? [
                        {
                          label: t(
                            tKeys.projectsPage.newProject.nbOfInstallments()
                          ),
                          value: (
                            <NumberTextField
                              size="small"
                              data-testid="textfield-nbpayments"
                              value={nbPayments}
                              onChange={(evt) =>
                                onChangeNbPayments(parseInt(evt.target.value))
                              }
                            />
                          ),
                        },
                      ]
                    : []),
                  ...(amount > 0 ? installments : []).map((installment, i) => ({
                    label: t(tKeys.projectsPage.newProject.installmentNum(), {
                      num: i + 1,
                    }),
                    value: (
                      <Installment
                        amount={installment.amount}
                        date={makeDateFromYm(installment.ym)}
                        onChangeDate={onChangeInstallmentDate(i)}
                      />
                    ),
                  })),
                ]}
              />
            </FormGroup>

            {isMutating ? (
              <Box style={{ textAlign: 'center' }}>
                <CircularProgress />
              </Box>
            ) : (
              <SaveActionBar
                canSave={canSave}
                onSave={onClickConfirm}
                onCancel={onCancel}
              />
            )}
          </Container>
        </CardContent>
      </Card>
    </Container>
  );
};
