import { useTranslation } from 'react-i18next';
import { Page } from '#common/Page/Page';
import { EmptyState } from '#common/EmptyState';
import { makeIcon } from '#common/icon-utils';
import { ReactComponent as Cheque } from './Cheque.svg';
import React from 'react';
import { tKeys } from 'translationKeys';

export const ChequesEmptyState = () => {
  const { t } = useTranslation();

  return (
    <Page title={t(tKeys.cheques.page.emptyState.title())}>
      <EmptyState
        tagline={t(tKeys.cheques.page.emptyState.tagline())}
        description={t(tKeys.cheques.page.emptyState.description())}
        icon={makeIcon(Cheque)}
      />
    </Page>
  );
};
