import React from 'react';
import { AppBar } from '#common/AppBar';
import { Box, Container } from '@mui/material';
import { cx } from '@emotion/css';
import { useRoleBackgroundClasses } from '#common/styles-utils';
import { useTranslation } from 'react-i18next';
import { tKeys } from 'translationKeys';
import { InvitesList } from './InvitesList';
import { Page } from '#common/Page/Page';
import {
  useMutationAcceptInvite,
  useMutationDeclineInvite,
  useQuerySelfInvites,
} from 'features/data/queries';
import { toastSuccess } from '#common/toasts';
import { EmptyInvites } from './EmptyInvites';

export const MyInvitesFullPage = () => {
  const styledClasses = useRoleBackgroundClasses();
  const { t } = useTranslation();
  const { data: myInvites } = useQuerySelfInvites();
  const mutationDeclineInvite = useMutationDeclineInvite();
  const mutationAcceptInvite = useMutationAcceptInvite();

  const declineInvite = (inviteId: string) => {
    mutationDeclineInvite.mutate(inviteId, {
      onSuccess() {
        toastSuccess(t(tKeys.invitesPage.declinedSuccess()));
      },
    });
  };

  const acceptInvite = (inviteId: string) => {
    mutationAcceptInvite.mutate(inviteId, {
      onSuccess() {
        toastSuccess(t(tKeys.invitesPage.acceptSuccess()));
      },
    });
  };

  return (
    <>
      <AppBar
        fullWidth
        noAddress
        role={null}
        title={t(tKeys.appBar.invites())}
      />
      <Box className={cx(styledClasses.contentBox, styledClasses.page)}>
        {myInvites && (
          <Page>
            <Container>
              {myInvites?.length === 0 ? (
                <EmptyInvites />
              ) : (
                <InvitesList
                  invites={myInvites}
                  onDecline={declineInvite}
                  onAccept={acceptInvite}
                />
              )}
            </Container>
          </Page>
        )}
      </Box>
    </>
  );
};
