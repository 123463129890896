import { useParams, useNavigate, Outlet } from 'react-router-dom';
import { Box, Theme } from '@mui/material';
import { AppBar } from '#common/AppBar';
import { Drawer } from '../Drawer/Drawer';
import { RoleType } from 'api';
import { useAppSelector } from 'app/hooks';
import { selectRole } from 'features/auth/authSlice';
import { cx } from '@emotion/css';
import { PaletteColorEx } from './AreaThemeProvider/theme';
import { useClasses } from '#common/styles-utils';
import { useMenuOpen } from '../Drawer/useMenuOpen';
import { prefixForRole } from 'app/constants';
import { Paths } from 'app/Paths';
import { Loader } from '#common/Loader';

const drawerWidth = 200;

const styles = (theme: Theme) => ({
  contentBox: {
    paddingTop: '64px',
    width: '100%',
    minHeight: '100%',
  },
  page: {
    backgroundColor: (theme.palette.primary as PaletteColorEx).lighter,
  },
});

const allTheRolePrefixes = Object.values(RoleType).map(prefixForRole);

export const BoardWithDrawer = () => {
  const { roleLetter } = useParams();
  const navigate = useNavigate();
  if (!roleLetter || !allTheRolePrefixes.includes(roleLetter)) {
    navigate(Paths.roles, { replace: true });
    return null;
  }

  const styledClasses = useClasses(styles);

  const activeRole = useAppSelector(selectRole);

  const { setOpen } = useMenuOpen();

  if (!activeRole) {
    return (
      <div style={{ width: '100%' }}>
        <Loader size="big" />;
      </div>
    );
  }

  return (
    <>
      <AppBar drawerWidth={drawerWidth} onOpenDrawer={() => setOpen(true)} />
      <Drawer width={drawerWidth} onClose={() => setOpen(false)} />

      <Box className={cx(styledClasses.contentBox, styledClasses.page)}>
        <Outlet />
      </Box>
    </>
  );
};
