import { Page } from '#common/Page/Page';
import { Paths } from 'app/Paths';
import { CreateProject } from './CreateProject';
import { relativize, useNestedRoutes } from 'features/utils';
import { ProjectsList } from './ProjectsList';
import { useCurrentRole } from 'app/hooks/useCurrentRole';
import { ViewProject } from './ViewProject';
import { ProjectsBreadcrumbs, ProjectsPageTitle } from './ProjectsBreadcrumbs';
import { EditProject } from './EditProject';

const root = Paths.property.projects.list;

export const ProjectsRoutes = () => {
  const { isVaad } = useCurrentRole();
  return useNestedRoutes(root, [
    {
      index: true,
      element: <ProjectsList />,
    },
    isVaad && {
      path: relativize(root)(Paths.property.projects.new),
      element: <CreateProject />,
    },
    isVaad && {
      path: relativize(root)(Paths.property.projects.edit),
      element: <EditProject />,
    },
    {
      path: relativize(root)(Paths.property.projects.view),
      element: <ViewProject />,
    },
  ]);
};

export const ProjectsPage = () => {
  return (
    <>
      <ProjectsBreadcrumbs />
      <Page title={<ProjectsPageTitle />}>
        <ProjectsRoutes />
      </Page>
    </>
  );
};
