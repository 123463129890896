import styled from '@emotion/styled';

export const DesktopOnly = styled.div`
  @media only screen and (max-width: 599px) {
    display: none;
  }
`;

export const MobileOnly = styled.div`
  @media only screen and (min-width: 600px) {
    display: none;
  }
`;

type CutValue = 'desktop' | number | false;
export const MediaHideIfSmall = styled.div<{ cut: CutValue }>(({ cut }) =>
  cut === false
    ? ''
    : `
  @media only screen and (max-width: ${cut === 'desktop' ? 599 : cut}px) {
    display: none;
  }
`
);
