import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  Input,
  InputLabel,
  Paper,
} from '@mui/material';
import * as classes from './classes';
import { useTranslation } from 'react-i18next';
import { createRef, useState } from 'react';
import { useCurrentLanguage } from 'app/hooks';
import { RoleType } from '@vaad/client';
import { onEnter } from '#common/keyboard-utils';
import { tKeys } from 'translationKeys';
import { useMutationSendInvite } from 'features/data/queries';
export const OwnerInviteTenant = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const lang = useCurrentLanguage();
  const refEmail = createRef<HTMLInputElement>();
  const refName = createRef<HTMLInputElement>();
  const mutationSendInvite = useMutationSendInvite();
  const sent = mutationSendInvite.isLoading;

  const onSend = () => {
    if (!refEmail.current?.checkValidity()) {
      refEmail.current?.focus();
      return;
    }
    if (!refName.current?.checkValidity()) {
      refName.current?.focus();
      return;
    }
    mutationSendInvite.mutate({ email, lang, name, type: RoleType.TENANT });
  };

  const onChangeEmail = (str: string): void => {
    setEmail(str);
  };

  const onChangeName = (str: string): void => {
    setName(str);
  };

  return (
    <Container maxWidth="sm">
      <Paper className={classes.paper}>
        <FormControl fullWidth sx={{ m: 1 }} variant="standard">
          <InputLabel htmlFor="tenant-email">
            {t(tKeys.tenantPage.invite.email.label())}
          </InputLabel>
          <Input
            inputRef={refEmail}
            required
            type="email"
            name="email"
            value={email}
            placeholder="name@email.com"
            onChange={(evt) => onChangeEmail(evt.target.value)}
            onKeyDown={onEnter(onSend)}
          />
        </FormControl>
        <FormControl fullWidth sx={{ m: 1 }} variant="standard">
          <InputLabel htmlFor="tenant-name">
            {t(tKeys.tenantPage.invite.name.label())}
          </InputLabel>
          <Input
            inputRef={refName}
            required
            type="text"
            name="name"
            value={name}
            placeholder={t(tKeys.tenantPage.invite.name.placeholder())}
            onChange={(evt) => onChangeName(evt.target.value)}
            onKeyDown={onEnter(onSend)}
          />
        </FormControl>
        <div className={classes.alignEnd}>
          {!sent && (
            <Button onClick={onSend}>
              {t(tKeys.tenantPage.invite.send())}
            </Button>
          )}
          {sent && <CircularProgress />}
        </div>
      </Paper>
    </Container>
  );
};
