import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeIcon } from '#common/icon-utils';
import { EmptyState } from '#common/EmptyState';
import { ReactComponent as Money } from './Money.svg';
import { tKeys } from 'translationKeys';
export const PaymentsManageEmptyState = ({
  onClick,
}: {
  onClick: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <EmptyState
      tagline={t(tKeys.payments.managePage.emptyState.tagline())}
      description={t(tKeys.payments.managePage.emptyState.description())}
      button={t(tKeys.payments.managePage.emptyState.button())}
      icon={makeIcon(Money)}
      onClick={onClick}
    />
  );
};
