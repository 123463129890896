import { Card, CardContent } from '@mui/material';
import { formatDateMonthNameAndYear, useFormat } from 'i18n';
import { useTranslation } from 'react-i18next';
import { TableList } from '#common/TableList';
import { makeDateFromYmd } from '@vaad/utils';
import { tKeys } from 'translationKeys';
import { FC } from 'react';
import { ProjectStatusPill } from './ProjectStatusPill';
import { ProjectResponse } from 'api';

interface Props {
  projects: ProjectResponse[];
  onClickOnProject: (project: ProjectResponse) => void;
}
export const ProjectsTable: FC<Props> = ({ projects, onClickOnProject }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { formatCurrency } = useFormat();

  return (
    <Card>
      <CardContent>
        <TableList<typeof projects[0]>
          onClick={(item) => onClickOnProject(item)}
          paper
          size="small"
          columns={[
            {
              title: t(tKeys.table.column.date()),
              render: (row) =>
                formatDateMonthNameAndYear(makeDateFromYmd(row.ts), language),
            },
            {
              title: t(tKeys.table.column.amount()),
              align: 'right',
              render: (row) => formatCurrency(row.amount),
            },
            {
              title: t(tKeys.projects.table.column.name()),
              render: (row) => row.name,
            },
            {
              title: t(tKeys.projects.table.column.status()),
              render: (row) => <ProjectStatusPill project={row} />,
            },
          ]}
          items={projects}
        />
      </CardContent>
    </Card>
  );
};
