import { Page } from '#common/Page/Page';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { useCurrentRole } from 'app/hooks';
import React from 'react';
import { tKeys } from 'translationKeys';
import { RecurringGridItem } from './RecurringGridItem';
import { ProjectsGridItem } from './ProjectsGridItem';
import { useQueryBudget } from 'features/data/queries/budget';
import { useQueryBalance } from 'features/data/queries/balance';
import { Loader } from '#common/Loader';
import { budgetAngle, projectAngle } from './balance-angles';

export const BalancePage = () => {
  const { t } = useTranslation();
  const { isOwner } = useCurrentRole();
  const { isFetching: isLoadingBalance, data: balance } = useQueryBalance();
  const { isFetching: isLoadingBudget, data: budget } = useQueryBudget();
  const loaded = !isLoadingBalance && !isLoadingBudget;
  const angleBudget = budgetAngle({ budget, balance });
  const angleProject = projectAngle(balance);

  return (
    <Page
      title={t(tKeys.balance.page.title())}
      subtitle={t(tKeys.balance.page.subtitle())}
    >
      {!loaded && <Loader size="big" />}

      {loaded && (
        <Grid container spacing={6}>
          <RecurringGridItem
            angle={angleBudget}
            balance={balance?.budget || 0}
          />
          {isOwner && (
            <ProjectsGridItem
              angle={angleProject}
              balance={balance?.project || 0}
            />
          )}
        </Grid>
      )}
    </Page>
  );
};
