import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, List } from '@mui/material';
import TenantIcon from '@mui/icons-material/HotelTwoTone';
import { Item } from './Item';
import { useAppSelector } from 'app/hooks';
import {
  selectAptId,
  selectPropertyId,
  selectRole,
} from '../../features/auth/authSlice';
import { Subheader } from './Subheader';
import { tKeys } from 'translationKeys';
import { generateApartmentPath, Paths } from 'app/Paths';

export const MenuListRenter = () => {
  const { t } = useTranslation();
  const activeRole = useAppSelector(selectRole)!;
  const propId = useAppSelector(selectPropertyId)!;
  const aptId = useAppSelector(selectAptId)!;
  const prefix = (path: string) =>
    generateApartmentPath(path, { role: activeRole.role, propId, aptId });

  return (
    <>
      <Divider />

      <List>
        <Subheader text={t(tKeys.menu.header.renter())} />

        <Item
          menuId="tenant"
          Icon={TenantIcon}
          text={t(tKeys.menu.renter.currentTenant())}
          to={prefix(Paths.apartment.tenant)}
        />
      </List>
    </>
  );
};
