import { Button, Tooltip } from '@mui/material';
import { Trans } from 'react-i18next';
import { tKeys } from '../../translationKeys';
import { css } from '@emotion/css';
import { Link } from 'react-router-dom';
import { Paths, usePropertyPath } from 'app/Paths';
import { FC } from 'react';
import styled from '@emotion/styled';
import { usePlan } from 'app/hooks';

type ButtonProps = Parameters<typeof Button>[0] & { needsPaidPlan?: boolean };

const tooltip = css`
  font-size: 150%;

  & a {
    color: #63f6ce;
  }
`;

const DisabledButton = styled(Button)<ButtonProps>(({ variant }) =>
  variant === 'contained'
    ? `
  background-color: silver;
  color: #656565;
  &:hover {
    background-color: silver;
  }
`
    : `
  color: silver;
`
);

export const PaidButton: FC<ButtonProps> = ((props: ButtonProps) => {
  const { needsPaidPlan, ...nativeProps } = props;
  const { generatePath } = usePropertyPath();

  const { isPaidPlan } = usePlan();

  return isPaidPlan || needsPaidPlan === false ? (
    <Button {...nativeProps} />
  ) : (
    <Tooltip
      title={
        <div className={tooltip}>
          <Trans
            i18nKey={tKeys.paidPlans.tooltip()}
            components={{
              br: <br />,
              pageLink: <Link to={generatePath(Paths.property.pricing)} />,
            }}
          />
        </div>
      }
    >
      <DisabledButton {...nativeProps} onClick={() => {}} />
    </Tooltip>
  );
}) as typeof Button;
