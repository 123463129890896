import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  Typography,
} from '@mui/material';
import { Page } from '#common/Page/Page';
import { useState } from 'react';
import { useFormat } from 'i18n';
import { useCurrentRole } from 'app/hooks';
import { aptsCount } from 'features/utils';
import styled from '@emotion/styled';

const BASIC_PLAN_PER_APT_PER_MONTH = 3;
const BRIEFCASE_PLAN_PER_APT_PER_MONTH = 3;
const BRIEFCASE_PLAN_FLAT_PER_YEAR = 200;
const OFFICE_PLAN_PER_APT_PER_MONTH = 5;
const OFFICE_PLAN_FLAT_PER_YEAR = 400;

interface PlanCardProps {
  name: string;
  img: string;
  tagline?: string;
  bullets: string[];
  price: number;
  priceDesc: string;
}

const StyledCardActions = styled(CardActions)`
  display: flex;
  justify-content: space-between;
`;

const PlanCard = ({
  name,
  img,
  tagline,
  bullets,
  price,
  priceDesc,
}: PlanCardProps) => {
  const { formatCurrency } = useFormat();

  const [elevation, setElevation] = useState(3);
  return (
    <Grid item md={4} sm={12}>
      <Card
        elevation={elevation}
        onMouseEnter={() => setElevation(12)}
        onMouseLeave={() => setElevation(3)}
        style={{ cursor: 'pointer' }}
      >
        <CardHeader title={name} />
        <CardMedia sx={{ height: 140 }} image={img} title={name} />
        <CardContent>
          <Typography>{tagline}</Typography>
          {bullets?.map((bullet, i) => (
            <Typography key={i}>
              <li>{bullet}</li>
            </Typography>
          ))}
        </CardContent>
        <StyledCardActions
          style={{
            backgroundColor: '#e9f8ff',
          }}
        >
          1 Year ({priceDesc})
          <Button size="small">{formatCurrency(price)}</Button>
        </StyledCardActions>
        <StyledCardActions style={{ backgroundColor: '#d0f4fd' }}>
          3 Years
          <br />
          save 20%
          <Button size="small">
            {formatCurrency(Math.floor(3 * price * 0.8))}
          </Button>
        </StyledCardActions>
        <StyledCardActions style={{ backgroundColor: '#c0eefc' }}>
          7 Years
          <br />
          save 35%
          <Button size="small">
            {formatCurrency(Math.floor(7 * price * 0.65))}
          </Button>
        </StyledCardActions>
      </Card>
    </Grid>
  );
};

export const PricingPage = () => {
  const { property } = useCurrentRole();
  const { formatCurrency } = useFormat();
  const aptsPerYear = aptsCount(property) * 12;

  return (
    <Page>
      <Grid container spacing={8}>
        <PlanCard
          name={'Basic'}
          img={
            'https://media.istockphoto.com/id/1166600020/photo/coloured-images-in-modern-office-buildings-in-downtown-hong-kong.jpg?s=612x612&w=0&k=20&c=Ukf5vP90_FwGXU2G59VE9BUo-BGnCIOJ8MLMgujjHD8='
          }
          bullets={['Cheques', 'Budget', 'Projects', 'Balances']}
          priceDesc={`1 Year (${formatCurrency(
            BASIC_PLAN_PER_APT_PER_MONTH
          )}/apt)`}
          price={BASIC_PLAN_PER_APT_PER_MONTH * aptsPerYear}
        />
        <PlanCard
          name={'Briefcase'}
          img={
            'https://media.istockphoto.com/id/479303220/photo/modern-business-building.jpg?s=612x612&w=0&k=20&c=PlkyTtZ89ss1x1rA_UJwQNMcb9yiDaq9qzkXSmQl0a4='
          }
          tagline={'All of Basic +'}
          bullets={['Polls', '100 Documents (max 20 MB each)']}
          priceDesc={`${formatCurrency(
            BRIEFCASE_PLAN_PER_APT_PER_MONTH
          )}/apt + ${BRIEFCASE_PLAN_FLAT_PER_YEAR}`}
          price={
            BRIEFCASE_PLAN_PER_APT_PER_MONTH * aptsPerYear +
            BRIEFCASE_PLAN_FLAT_PER_YEAR
          }
        />
        <PlanCard
          name={'Office'}
          img={
            'https://media.istockphoto.com/id/852149362/photo/bright-future-finance-buildings-seen-from-below.jpg?s=612x612&w=0&k=20&c=SHRRL97JcA8SeYKbpde7WJh_z52zg9aPIU84RXR0N34='
          }
          tagline={'All of Briefcase +'}
          bullets={['Unlimited Documents (max total 100 GB)']}
          priceDesc={`${formatCurrency(
            OFFICE_PLAN_PER_APT_PER_MONTH
          )}/apt + ${OFFICE_PLAN_FLAT_PER_YEAR}`}
          price={
            OFFICE_PLAN_PER_APT_PER_MONTH * aptsPerYear +
            OFFICE_PLAN_FLAT_PER_YEAR
          }
        />
      </Grid>
    </Page>
  );
};
