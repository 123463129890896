import { css } from '@emotion/css';

export const vignette = css`
  height: 180px;
  width: 180px;
  margin: 10px;
  overflow: hidden;
`;

export const roleIcon = css`
  font-size: 100px;
`;

export const archived = css`
  background-color: #ffffff55;
`;
