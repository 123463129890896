import { Grow } from '@mui/material';
import { Vignette } from '#common/Vignette';
import * as classes from './classes';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/AddCircleTwoTone';
import { useTranslation } from 'react-i18next';
import { tKeys } from 'translationKeys';
import { StyledRoleBox } from './StyledRoleBox';

export const AddRoleVignette = () => {
  const { t } = useTranslation();

  return (
    <div style={{ width: '240px', height: '240px' }}>
      <Grow in={true}>
        <div style={{ width: '180px', height: '180px' }}>
          <Vignette
            title={'\u00A0'}
            subtitle={t(tKeys.roleSelector.createVaad())}
            elevateOnHover
            className={classes.vignette}
          >
            <Link to="/add-role">
              <StyledRoleBox>
                <AddIcon color="secondary" className={classes.roleIcon} />
              </StyledRoleBox>
            </Link>
          </Vignette>
        </div>
      </Grow>
    </div>
  );
};
