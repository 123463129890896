import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from '@emotion/styled';

const StyledRoot = styled.div`
  display: flex;
  min-height: 100%;
`;

export const ThemedBoard = () => {
  return (
    <StyledRoot>
      <Outlet />
    </StyledRoot>
  );
};
