import { RootState } from '../app/store';
import { Role, selectRole, selectToken } from './auth/authSlice';
import { EntrancesNumbering, Property, RoleType } from 'api';
import { TFunction } from 'i18next';
import md5 from 'md5';
import { tKeys } from 'translationKeys';
import { RouteObject, useRoutes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export type GetState = () => unknown;

interface ExtractedAuthParams {
  roleType: RoleType;
  token: string;
  propertyId: string;
  roleId: string;
  aptId?: string;
}

export const extractAuthParams = (getState: GetState): ExtractedAuthParams => {
  const state: RootState = getState() as RootState;
  const role = selectRole(state)!;
  const { propertyId, id: roleId, aptId } = role;
  return {
    roleType: role.role,
    token: selectToken(state),
    propertyId,
    roleId,
    aptId,
  };
};

export const entranceString = (
  t: TFunction,
  property: Property,
  entrance?: number | undefined
): string => {
  if (undefined === entrance || property.entrances.length === 1) {
    return '';
  }
  switch (property.numbering) {
    case EntrancesNumbering.NAME:
      return property.entranceNames![entrance - 1];
    case EntrancesNumbering.LETTER:
      return String.fromCharCode(
        t('roleSelector.entrances.letters.firstLetter').charCodeAt(0) +
          (entrance - 1)
      );
    default:
      return `${entrance}`;
  }
};

export const apartmentTitle = (t: TFunction, role: Role): number | string => {
  if (role.aptNum === undefined) {
    return '';
  }
  if (role.property.entrances?.length <= 1) {
    return role.aptNum;
  }
  return `${entranceString(t, role.property, role.entrance)} ${role.aptNum}`;
};

export const useApartmentTitle = ({
  aptNum,
  entrance,
  property,
}: {
  aptNum?: number;
  entrance?: number;
  property: Property;
}) => {
  const { t } = useTranslation();
  return aptNum === undefined
    ? ''
    : property.entrances?.length <= 1
    ? aptNum
    : `${entranceString(t, property, entrance)} ${aptNum}`;
};

export const avatarSrc = (
  {
    email,
    hash,
    defaultType,
  }: { email?: string; hash?: string; defaultType?: 'silhouette' | 'retro' },
  size = 40
): string => {
  const imgHash = hash || md5(email!.trim().toLowerCase());
  const d = defaultType === 'silhouette' ? 'mp' : 'retro';
  return `https://www.gravatar.com/avatar/${imgHash}.jpg?s=${size}&d=${d}`;
};

// https://stackoverflow.com/a/46181/1663283
export const isValidEmail = (email: string): boolean => {
  return (
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) !== null
  );
};

export const entranceName =
  (t: TFunction) =>
  (
    property: Pick<Property, 'entranceNames' | 'numbering'>,
    i: number
  ): string => {
    if (
      !property.numbering ||
      property.numbering === EntrancesNumbering.NUMBER
    ) {
      return String(i);
    }
    if (
      !property.entranceNames ||
      property.numbering === EntrancesNumbering.LETTER
    ) {
      return String.fromCharCode(
        t(tKeys.addRole.entrances.ab()).charCodeAt(0) + (i - 1)
      );
    }
    return property.entranceNames[i - 1];
  };

export const relativize = (root: string) => (absolute: string) =>
  absolute.replace(`${root}/`, '');

function isTruthy<T>(x: T | false | undefined): x is NonNullable<T> {
  return !!x;
}

export const useNestedRoutes = (
  root: string,
  routes: (RouteObject | false | undefined)[]
) => {
  const rel = relativize(root);
  return useRoutes(
    routes
      .filter(isTruthy)
      .map((routeObj) =>
        routeObj.path ? { ...routeObj, path: rel(routeObj.path) } : routeObj
      )
  );
};

export const aptsCount = (property: Property): number =>
  property.entrances?.reduce((acc, num) => acc + num, 0) || 1;
