import { tKeys } from 'translationKeys';
import React from 'react';
import { AptPage } from '#common/AptPage/AptPage';
import { Loader } from '#common/Loader';
import { Grid } from '@mui/material';
import { RecurringGridItem } from './RecurringGridItem';
import { ProjectsGridItem } from './ProjectsGridItem';
import { useQueryAptBalance } from 'features/data/queries/balance';
import { useQueryBudget } from 'features/data/queries/budget';
import { budgetAngle, projectAngle } from './balance-angles';
import { useUrlParser } from 'features/urlParser';
import { useCurrentRole } from 'app/hooks';

export const AptBalancePage = () => {
  const { aptId } = useUrlParser();
  const { propertyId } = useCurrentRole();
  const { isFetching: isLoadingBalance, data: balance } = useQueryAptBalance(
    propertyId,
    aptId!
  );
  const { isFetching: isLoadingBudget, data: budget } = useQueryBudget();
  const loaded = !isLoadingBalance && !isLoadingBudget;
  const angleBudget = budgetAngle({ budget, balance });
  const angleProject = projectAngle(balance);

  return (
    <AptPage lastCrumbKey={tKeys.menu.resident.accountBalance()}>
      {!loaded && <Loader size="big" />}
      {loaded && (
        <Grid container spacing={6}>
          <RecurringGridItem
            angle={angleBudget}
            balance={balance?.budget || 0}
          />
          <ProjectsGridItem
            angle={angleProject}
            balance={balance?.project || 0}
          />
        </Grid>
      )}
    </AptPage>
  );
};
