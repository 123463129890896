import { AppBar } from '#common/AppBar';
import { RoleType } from '../../../api';
import { Box } from '@mui/material';
import { cx } from '@emotion/css';
import { AddRole } from '../../Pages/AddRole/AddRole';
import React from 'react';
import { useRoleBackgroundClasses } from '#common/styles-utils';

export const AddRoleFullPage = () => {
  const styledClasses = useRoleBackgroundClasses();

  return (
    <>
      <AppBar fullWidth role={RoleType.VAAD} noAddress />
      <Box className={cx(styledClasses.contentBox, styledClasses.page)}>
        <AddRole />
      </Box>
    </>
  );
};
