import { Table, TableBody, TableContainer, TableRow } from '@mui/material';
import { FC } from 'react';
import styled from '@emotion/styled';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

interface Props {
  lines: {
    label: string;
    value: string | JSX.Element;
  }[];
}

const Label = styled(TableCell)`
  text-align: end;
`;

const Value = styled(TableCell)`
  text-align: start;
`;

export const VerticalForm: FC<Props> = ({ lines }) => {
  return (
    <TableContainer>
      <Table
        size="small"
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: 'none',
          },
        }}
      >
        <TableBody>
          {lines.map((line, i) => (
            <TableRow key={i}>
              <Label>{line.label}</Label>
              <Value>{line.value}</Value>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
