import { BankManageEmptyState } from './BankManageEmptyState';
import { PropsWithChildren, useState } from 'react';
import { Page } from '#common/Page/Page';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectRole } from 'features/auth/authSlice';
import {
  Box,
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@mui/material';
import { Bank } from '@vaad/client';
import { saveBankDetailsAsync } from 'features/auth/authSlice';
import { tKeys } from 'translationKeys';

const Label = ({ text }: { text: string }) => (
  <TableCell style={{ textAlign: 'end', borderBottom: 'none' }}>
    {text}
  </TableCell>
);

const Value = (props: PropsWithChildren<unknown>) => (
  <TableCell style={{ textAlign: 'start', borderBottom: 'none' }}>
    {props.children}
  </TableCell>
);

export const BankManagePage = () => {
  const { t } = useTranslation();
  const [creating, setCreating] = useState(false);
  const property = useAppSelector(selectRole)!.property;
  const bank: Bank = {
    bank: '',
    branch: '',
    account: '',
    name: '',
    iban: '',
    ...property.bank,
  };
  const [editBank, setEditBank] = useState({
    bank: bank?.bank || '',
    branch: bank?.branch || '',
    account: bank?.account || '',
    name: bank?.name || '',
    iban: bank?.iban || '',
  } as Bank);
  const dispatch = useAppDispatch();

  const onEmptyStateClick = () => {
    setCreating(true);
  };

  const isBankEmpty = () =>
    !bank?.bank &&
    !bank?.branch &&
    !bank?.account &&
    !bank?.name &&
    !bank?.iban;

  if (isBankEmpty() && !creating) {
    return (
      <Page title={t(tKeys.bankDetails.page.title())}>
        <BankManageEmptyState onClick={onEmptyStateClick} />
      </Page>
    );
  }

  const onCancel = () => {
    setEditBank({ ...(bank as Bank) });
    setCreating(false);
  };

  const onSave = () => {
    dispatch(
      saveBankDetailsAsync({ propertyId: property.id, bankDetails: editBank })
    );
  };

  return (
    <Page
      title={t(tKeys.bankDetails.page.title())}
      subtitle={t(tKeys.bankDetails.page.subtitle())}
    >
      <Container maxWidth="sm">
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <Label text={t(tKeys.bankDetails.page.bank())} />
                <Value>
                  <TextField
                    fullWidth
                    value={editBank.bank}
                    onChange={(evt) => {
                      setEditBank({ ...editBank, bank: evt.target.value });
                    }}
                  />
                </Value>
              </TableRow>
              <TableRow>
                <Label text={t(tKeys.bankDetails.page.branch())} />
                <Value>
                  <TextField
                    fullWidth
                    value={editBank.branch}
                    onChange={(evt) => {
                      setEditBank({ ...editBank, branch: evt.target.value });
                    }}
                  />
                </Value>
              </TableRow>
              <TableRow>
                <Label text={t(tKeys.bankDetails.page.account())} />
                <Value>
                  <TextField
                    fullWidth
                    value={editBank.account}
                    onChange={(evt) => {
                      setEditBank({ ...editBank, account: evt.target.value });
                    }}
                  />
                </Value>
              </TableRow>
              <TableRow>
                <Label text={t(tKeys.bankDetails.page.name())} />
                <Value>
                  <TextField
                    fullWidth
                    value={editBank.name}
                    onChange={(evt) => {
                      setEditBank({ ...editBank, name: evt.target.value });
                    }}
                  />
                </Value>
              </TableRow>
              <TableRow>
                <Label text={t(tKeys.bankDetails.page.iban())} />
                <Value>
                  <TextField
                    fullWidth
                    value={editBank.iban}
                    onChange={(evt) => {
                      setEditBank({ ...editBank, iban: evt.target.value });
                    }}
                  />
                </Value>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Box style={{ textAlign: 'end', paddingTop: '20px' }}>
          <Button style={{ marginInlineEnd: '20px' }} onClick={onCancel}>
            {t(tKeys.actions.cancel())}
          </Button>
          <Button variant="contained" color="primary" onClick={onSave}>
            {t(tKeys.actions.save())}
          </Button>
        </Box>
      </Container>
    </Page>
  );
};
