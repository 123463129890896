import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, List } from '@mui/material';
import PaymentsIcon from '@mui/icons-material/ReceiptTwoTone';
import ChequesIcon from '@mui/icons-material/NoteTwoTone';
import AccountBalanceIcon from '@mui/icons-material/AccountBalanceTwoTone';
import { Item } from './Item';
import { useAppSelector } from 'app/hooks';
import {
  selectAptId,
  selectPropertyId,
  selectRole,
} from 'features/auth/authSlice';
import { Subheader } from './Subheader';
import { tKeys } from 'translationKeys';
import { generateApartmentPath, Paths } from 'app/Paths';
import { RoleType } from 'api';

interface Props {
  aptId?: string;
}

export const MenuListOwner: FC<Props> = ({ aptId: propAptId }) => {
  const { t } = useTranslation();
  const activeRole = useAppSelector(selectRole)!;
  const propId = useAppSelector(selectPropertyId)!;
  const stateAptId = useAppSelector(selectAptId)!;
  const aptId =
    activeRole.role === RoleType.VAAD && propAptId ? propAptId : stateAptId;
  const prefix = (path: string) =>
    generateApartmentPath(path, {
      role: activeRole.role,
      propId,
      aptId,
    });

  return (
    <>
      <Divider />

      <List>
        <Subheader text={t(tKeys.menu.header.owner())} />

        <Item
          menuId="payments"
          Icon={PaymentsIcon}
          text={t(tKeys.menu.resident.payments())}
          to={prefix(Paths.apartment.payments)}
        />
        <Item
          menuId="cheques"
          Icon={ChequesIcon}
          text={t(tKeys.menu.resident.cheques())}
          to={prefix(Paths.apartment.cheques)}
        />
        <Item
          menuId="account-balance"
          Icon={AccountBalanceIcon}
          text={t(tKeys.menu.resident.accountBalance())}
          to={prefix(Paths.apartment.accountBalance)}
        />
      </List>
    </>
  );
};
