import { useLocation, useParams } from 'react-router-dom';
import {
  useMutationDeleteProject,
  useQueryProject,
} from 'features/data/queries';
import { ProjectPageLocationState } from './ProjectPageLocationState';

export const useViewProject = () => {
  const { projectId } = useParams<'projectId'>();
  const { data } = useQueryProject(projectId!);
  const locationState = useLocation().state as ProjectPageLocationState;
  const { project: projectFromState } = locationState ?? {};
  const project = data ?? projectFromState;
  const mutationDeleteProject = useMutationDeleteProject();

  const deleteProject = ({ onSuccess }: { onSuccess?: () => void } = {}) => {
    projectId &&
      mutationDeleteProject.mutate(
        { id: projectId },
        {
          onSuccess() {
            onSuccess && onSuccess();
          },
        }
      );
  };

  return {
    project,
    deleteProject,
  };
};
