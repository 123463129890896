import { useTranslation } from 'react-i18next';
import { useCurrentRole } from 'app/hooks';
import { relativize, useNestedRoutes } from 'features/utils';
import { generatePropertyPath, Paths } from 'app/Paths';
import { tKeys } from 'translationKeys';
import { ViewProjectTitle } from './ViewProjectTitle';
import { GenericBreadcrumbs } from '#common/Breadcrumbs/GenericBreadcrumbs';
import { FlowItem } from '#common/Breadcrumbs/FlowItem';

const root = Paths.property.projects.list;

const useProjectsFlow = (): FlowItem[] => {
  const { t } = useTranslation();
  const { isVaad, roleType, propertyId } = useCurrentRole();
  const listHref = relativize(root)(
    generatePropertyPath(Paths.property.projects.list, {
      role: roleType,
      propId: propertyId,
    })
  );

  return [
    {
      index: true,
      crumbs: [t(tKeys.projectsPage.title())],
      title: t(tKeys.projectsPage.title()),
    },
    ...(isVaad
      ? [
          {
            path: Paths.property.projects.new,
            crumbs: [
              {
                title: t(tKeys.projectsPage.title()),
                href: listHref,
              },
              t(tKeys.projectsPage.newProject.title()),
            ],
            title: t(tKeys.projectsPage.newProject.title()),
          },
        ]
      : []),
    {
      path: Paths.property.projects.view,
      crumbs: [
        { title: t(tKeys.projectsPage.title()), href: listHref },
        <ViewProjectTitle key="viewProjectTitle" />,
      ],
      title: <ViewProjectTitle key="viewProjectTitle" />,
    },
    {
      path: Paths.property.projects.edit,
      crumbs: [
        { title: t(tKeys.projectsPage.title()), href: listHref },
        <ViewProjectTitle key="viewProjectTitle" />,
      ],
      title: <ViewProjectTitle key="viewProjectTitle" />,
    },
  ];
};

export const ProjectsPageTitle = () => {
  const flowItems = useProjectsFlow();
  return useNestedRoutes(
    root,
    flowItems.map((item) => ({
      index: item.index,
      path: item.path,
      element: item.title,
    }))
  );
};

export const ProjectsBreadcrumbs = () => {
  const flowItems = useProjectsFlow();
  return <GenericBreadcrumbs root={root} items={flowItems} />;
};
