import { TableActions } from '#common/TableActions/TableActions';
import { tKeys } from 'translationKeys';
import { Card, CardContent, Container } from '@mui/material';
import { BuildingChequesList } from './BuildingChequesList';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useAppSelector } from 'app/hooks';
import { selectRole } from 'features/auth/authSlice';
import {
  ChequesData,
  useMutationDeleteCheque,
  useMutationDepositCheque,
} from 'features/data/queries';
import { toastSuccess } from '#common/toasts';

interface Props {
  onCreate: () => void;
  cheques: ChequesData;
  noApt?: boolean;
}

const ToastMsgDeposit: FC<{ chequeNo: string }> = ({ chequeNo }) => (
  <Trans
    i18nKey={tKeys.cheques.managePage.depositCheque.success()}
    values={{ chequeNo }}
    components={{ strong: <b /> }}
  />
);

const toastMsgDeposit = (chqNo: string) => <ToastMsgDeposit chequeNo={chqNo} />;

export const ChequesManageTable: FC<Props> = ({ onCreate, cheques, noApt }) => {
  const { t } = useTranslation();
  const { property } = useAppSelector(selectRole)!;
  const mutationDepositCheque = useMutationDepositCheque();
  const mutationDeleteCheque = useMutationDeleteCheque();

  const onDestroyCheque = (id: string) => {
    mutationDeleteCheque.mutate({ id });
  };

  const onDepositCheque = (id: string, chequeNo: string) => {
    mutationDepositCheque.mutate(
      { id },
      {
        onSuccess() {
          toastSuccess(toastMsgDeposit(chequeNo));
        },
      }
    );
  };

  return (
    <Container maxWidth="md">
      <TableActions
        buttons={[
          {
            caption: t(tKeys.cheques.managePage.emptyState.button()),
            onClick: onCreate,
          },
        ]}
      />
      <Card>
        <CardContent>
          <BuildingChequesList
            property={property}
            items={cheques}
            onDestroyCheque={onDestroyCheque}
            onDepositCheque={onDepositCheque}
            noApt={noApt}
          />
        </CardContent>
      </Card>
    </Container>
  );
};
