import { css } from '@emotion/css';

export const expires = css`
  color: #a3a3a3;
  flex-grow: 2;
  text-align: center;
`;

export const email = css`
  color: #a3a3a3;
`;

export const nothingToDisplay = css`
  color: #a3a3a3;
`;
