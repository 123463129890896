import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyState } from '#common/EmptyState';
import { ReactComponent as ToDoListSvg } from './ToDoList.svg';
import { makeIcon } from '#common/icon-utils';
import { tKeys } from 'translationKeys';
export const BudgetViewEmptyState = () => {
  const { t } = useTranslation();
  return (
    <EmptyState
      dataTestid="view-budget-empty-state"
      tagline={t(tKeys.viewBudget.emptyState.tagline())}
      description={t(tKeys.viewBudget.emptyState.description())}
      icon={makeIcon(ToDoListSvg)}
    />
  );
};
