import React from 'react';
import { Vignette } from '../Vignette';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';
import * as classes from './classes';
import styled from '@emotion/styled';
import { PaidButton } from '#common/PaidButton';

interface Props {
  button?: string;
  tagline: string;
  description: string | JSX.Element;
  icon: OverridableComponent<SvgIconTypeMap>;
  onClick?: () => void;
  dataTestid?: string;
  disabled?: boolean;
  needsPlan?: boolean;
}

const StyledCenteredDiv = styled.div`
  text-align: center;
`;

export const EmptyState = (props: Props) => {
  const Btn = props.needsPlan ? PaidButton : Button;
  const renderFooter = () => {
    return (
      <StyledCenteredDiv>
        <Btn
          onClick={() => props.onClick!()}
          variant="contained"
          color="primary"
          disabled={props.disabled}
        >
          {props.button}
        </Btn>
      </StyledCenteredDiv>
    );
  };

  const Icon = props.icon;
  const StyledBigIcon = styled(Icon)`
    width: 180px !important;
    height: 180px !important;
  `;

  return (
    <Container maxWidth="sm" data-testid={props.dataTestid}>
      <Vignette
        title=""
        className={classes.EmptyState}
        contentClassName={classes.EmptyStateContent}
        footer={props.button && renderFooter()}
      >
        <StyledBigIcon color="secondary" />

        <Typography align="center" variant="h6">
          {props.tagline}
        </Typography>
        <Typography align="center" component="div">
          {props.description}
        </Typography>
      </Vignette>
    </Container>
  );
};
