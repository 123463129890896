import { EmptyState } from '#common/EmptyState';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BuildingBankIcon from '@mui/icons-material/AccountBalanceWalletTwoTone';
import { tKeys } from 'translationKeys';
export const BankDetailsEmptyState = () => {
  const { t } = useTranslation();

  return (
    <EmptyState
      tagline={t(tKeys.bankDetails.emptyState.tagline())}
      description={t(tKeys.bankDetails.emptyState.description())}
      icon={BuildingBankIcon}
    />
  );
};
