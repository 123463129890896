import { Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

interface Crumb {
  title: string | JSX.Element;
  href?: string;
}

interface Props {
  crumbs: Crumb[];
}

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  &:hover,
  &:focus {
    text-decoration: underline;
    color: inherit;
  }

  &:visited {
    color: inherit;
  }
`;

export const Breadcrumbs: FC<Props> = ({ crumbs }) => {
  return (
    <div style={{ height: '5em' }}>
      <div
        style={{
          backgroundColor: 'white',
          width: '100%',
          position: 'fixed',
          height: '2em',
          padding: '0.5em',
          paddingTop: '1em',
        }}
      >
        <MuiBreadcrumbs separator="›" aria-label="breadcrumb">
          {crumbs.map(
            (crumb, i) =>
              i < crumbs.length - 1 &&
              (crumb.href ? (
                <StyledLink key={i} color="inherit" to={crumb.href}>
                  {crumb.title}
                </StyledLink>
              ) : (
                <Typography key={i} color="text.primary">
                  {crumb.title}
                </Typography>
              ))
          )}
          <Typography color="text.primary" variant="h5">
            {crumbs[crumbs.length - 1].title}
          </Typography>
        </MuiBreadcrumbs>
      </div>
    </div>
  );
};
