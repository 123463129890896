import { TextField } from '@mui/material';
import { useClasses } from '#common/styles-utils';
import { FC } from 'react';

const styles = () => ({
  input: {
    textAlign: 'right',
  },
});

export const NumberTextField: FC<Parameters<typeof TextField>[0]> = (props) => {
  const classes = useClasses(styles);

  return (
    <TextField
      type="number"
      {...props}
      InputProps={{
        ...props.InputProps,
        classes,
      }}
    />
  );
};
