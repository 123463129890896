import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, List } from '@mui/material';
import HowToVoteTwoToneIcon from '@mui/icons-material/HowToVoteTwoTone';
import FilePresentTwoToneIcon from '@mui/icons-material/FilePresentTwoTone';
import { Item } from './Item';
import { Subheader } from './Subheader';
import { tKeys } from 'translationKeys';
import { Paths, usePropertyPath } from 'app/Paths';

export const MenuListCommunity = () => {
  const { t } = useTranslation();
  const { generatePath } = usePropertyPath();

  return (
    <>
      <Divider />
      <List>
        <Subheader text={t(tKeys.menu.header.community())} />

        <Item
          menuId="surveys"
          Icon={HowToVoteTwoToneIcon}
          text={t(tKeys.menu.community.surveys())}
          to={generatePath(Paths.property.surveys)}
        />

        <Item
          menuId="documents"
          Icon={FilePresentTwoToneIcon}
          text={t(tKeys.menu.community.documents())}
          to={generatePath(Paths.property.documents)}
        />
      </List>
    </>
  );
};
