import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';

export const useCreatePayment = () => {
  const this_year = new Date(Date.now()).getFullYear();
  const minDate = new Date(`${this_year - 7}-01-01`);
  const maxDate = new Date(`${this_year + 8}-12-31`);
  const [searchParams] = useSearchParams();
  const [aptId, setAptId] = useState(searchParams.get('aptId') ?? '');

  const onChangeApt = (val: string) => {
    setAptId(val);
  };

  return {
    minDate,
    maxDate,
    aptId,
    onChangeApt,
  };
};
