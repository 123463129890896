import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { useAppSelector } from 'app/hooks';
import { selectRole } from 'features/auth/authSlice';
import { theme } from './theme';
import { useUrlParser } from 'features/urlParser';
import { RoleType } from 'api';
import { FC, PropsWithChildren } from 'react';

export const AreaThemeProvider: FC<PropsWithChildren<unknown>> = (props) => {
  const activeRole = useAppSelector(selectRole);
  const { domain: routeDomain } = useUrlParser();
  const domain =
    routeDomain === 'vaad'
      ? RoleType.VAAD
      : (activeRole?.role as RoleType) ?? 'none';
  const domainTheme = theme(domain);

  return (
    <MuiThemeProvider theme={domainTheme}>
      <ThemeProvider theme={domainTheme}>{props.children}</ThemeProvider>
    </MuiThemeProvider>
  );
};
