import { css } from '@emotion/css';

export const budgetPage = css`
  height: 100%;
  &.emptyState {
    background-color: rgba(0, 0, 0, 0.08);
  }
`;

export const loader = css`
  text-align: center;
  height: 60vh;
  transform: translateY(50%);
`;

export const pieContainer = css`
  direction: ltr;
  text-align: center;
  width: 100% !important;
  height: 100% !important;
`;
