import { Typography } from '@mui/material';

export const PageTitle = ({
  title,
}: {
  title: string | React.ReactElement;
}) => {
  return (
    <div style={{ paddingInlineStart: '16px' }}>
      <Typography variant="h4" gutterBottom>
        {title}
      </Typography>
    </div>
  );
};

export const PageSubTitle = ({ title }: { title?: string }) => {
  return (
    <div style={{ paddingInlineStart: '16px' }}>
      <Typography color="textSecondary" gutterBottom component="h6">
        {title}
        {!title && <>&#160;</>}
      </Typography>
    </div>
  );
};
