import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface MenuState {
  isDrawerOpen: boolean;
  loading: boolean;
}

export const menuSliceInitialState: MenuState = {
  isDrawerOpen: true,
  loading: false,
};

const menuSlice = createSlice({
  name: 'menu',
  initialState: menuSliceInitialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    startLoading: (state) => {
      state.loading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
    openDrawer: (state) => {
      state.isDrawerOpen = true;
    },
    closeDrawer: (state) => {
      state.isDrawerOpen = false;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  // extraReducers: (_builder) => {},
});

export const { startLoading, stopLoading, openDrawer, closeDrawer } =
  menuSlice.actions;

export const selectIsLoading = (state: RootState): boolean => {
  return state.menu.loading;
};

export const selectIsDrawerOpen = (state: RootState): boolean =>
  state.menu.isDrawerOpen;

export default menuSlice.reducer;
