import {
  createAsyncThunk,
  createSlice,
  Draft,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { api, Bank, IdentityResponse, Property } from 'api';
import { extractAuthParams, GetState } from '../utils';
import { tokenManager } from '../token-manager';
import { queryClient } from '../queryClient';
import { RoleType } from '@vaad/client';

type StateGetter = { getState: () => unknown; dispatch: unknown };

export interface Role
  extends Omit<IdentityResponse['roles'][number], 'userId'> {
  role: RoleType;
  property: Property;
}

export interface AuthState {
  isLogged: boolean;
  email: string | null;
  fullName: string | null;
  roles: { [id: string]: Role };
  activeRole: string | null;
  networkError: boolean;
  needsPaidPlan: boolean;
  token: string;
}

const initialState = (): AuthState => ({
  isLogged: false,
  fullName: null,
  email: null,
  roles: {},
  activeRole: null,
  networkError: false,
  needsPaidPlan: false,
  token: tokenManager.get() || '',
});

export const performOtpAsync = createAsyncThunk(
  'auth/otp',
  async (signinCode: string) => {
    return await api.otp({ body: { signinCode } });
  }
);

export const checkTokenAsync = createAsyncThunk(
  'auth/checkToken',
  async (params, { getState }: StateGetter) => {
    if (!(getState() as unknown as RootState).auth.networkError) {
      const token = tokenManager.get();
      if (token) {
        return await api.checkToken({ token });
      }
    }
    return false;
  }
);

export const saveBankDetailsAsync = createAsyncThunk(
  'data/saveBankDetails',
  async (
    {
      propertyId,
      bankDetails,
    }: {
      propertyId: string;
      bankDetails: Bank;
    },
    { getState }: StateGetter
  ) => {
    const { token } = extractAuthParams(getState as GetState);
    await api.updateBank({
      token,
      params: { propId: propertyId },
      body: { bankDetails },
    });
    return bankDetails;
  }
);

const toMap = <T>(arr: T[], k: keyof T): { [key: string]: T } =>
  arr.reduce((acc, item) => {
    Object.assign(acc, { [item[k] as unknown as string]: item });
    return acc;
  }, {} as { [key: string]: T });

const processRoles = (
  state: Draft<AuthState>,
  roles: IdentityResponse['roles'],
  properties: Property[]
) => {
  const propertyMap = toMap<Property>(properties, 'id');

  state.isLogged = true;
  state.roles = roles.reduce(
    (acc, role) => ({
      ...acc,
      [role.id]: {
        ...role,
        property: propertyMap[role.propertyId],
      },
    }),
    {}
  );
  state.activeRole = (roles.length === 1 && (roles[0].id as RoleType)) || null;
};

function processIdentityResponse(
  tokenResponse: IdentityResponse,
  state: Draft<AuthState>
) {
  const { roles, properties, token } = tokenResponse;
  if (token) {
    state.isLogged = true;
    state.email = tokenResponse.email;
    state.fullName = tokenResponse.fullName;
    state.token = token;
    tokenManager.set(token);
    processRoles(state, roles, properties);
    state.isLogged = true;
  } else {
    state.isLogged = false;
    state.token = '';
    tokenManager.clear();
  }
}

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState(),
  reducers: {
    networkError: (state) => {
      state.networkError = true;
    },
    clearNetworkError: (state) => {
      state.networkError = false;
    },
    needsPaidPlan: (state) => {
      state.needsPaidPlan = true;
    },
    clearNeedsPaidPlan: (state) => {
      state.needsPaidPlan = false;
    },
    logout: (state) => {
      Object.assign(state, initialState());
      state.token = '';
      tokenManager.clear();
    },
    pickRole: (state, action: PayloadAction<string>) => {
      queryClient.clear();
      state.activeRole = action.payload;
    },
    addActiveRole: (state, action: PayloadAction<Role>) => {
      state.roles[action.payload.id] = action.payload;
      state.activeRole = action.payload.id;
    },
    setToken: (state, action: PayloadAction<string>) => {
      tokenManager.set(action.payload);
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkTokenAsync.fulfilled, (state, action) => {
        if (action.payload === false) {
          return;
        }
        processIdentityResponse(
          action.payload as unknown as IdentityResponse,
          state
        );
      })
      .addCase(performOtpAsync.fulfilled, (state, action) => {
        processIdentityResponse(
          action.payload as unknown as IdentityResponse,
          state
        );
      })
      .addCase(saveBankDetailsAsync.fulfilled, (state, action) => {
        state.roles[state.activeRole!]!.property.bank = action.payload;
      });
  },
});

export const {
  networkError,
  clearNetworkError,
  needsPaidPlan,
  clearNeedsPaidPlan,
  logout,
  pickRole,
  addActiveRole,
  setToken,
} = authSlice.actions;

export const selectToken = (state: RootState) => state.auth.token;
export const selectNetworkError = (state: RootState) => state.auth.networkError;
export const selectNeedsPaidPlan = (state: RootState) =>
  state.auth.needsPaidPlan;
export const selectIsLogged = (state: RootState) => ({
  isLogged: state.auth.isLogged,
  email: state.auth.email,
  fullName: state.auth.fullName,
});
export const selectRole = (state: RootState): Role | null =>
  (state.auth.roles &&
    state.auth.activeRole &&
    state.auth.roles[state.auth.activeRole]) ||
  null;
export const selectRoles = (state: RootState): Role[] =>
  Object.values(state.auth.roles);
export const selectPropertyId = (state: RootState) =>
  selectRole(state)?.propertyId;
export const selectProperty = (state: RootState) =>
  state.auth.roles &&
  state.auth.activeRole &&
  state.auth.roles[state.auth.activeRole].property;

export const selectAptId = (state: RootState) => selectRole(state)?.aptId;
export default authSlice.reducer;
