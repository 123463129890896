import { Box, Card, CardContent } from '@mui/material';
import { TableList } from '#common/TableList';
import { tKeys } from 'translationKeys';
import { useTranslation } from 'react-i18next';
import { entranceString } from 'features/utils';
import { Property, RoleType } from 'api';
import { FC } from 'react';
import {
  FlatOwner,
  flattenOwnersData,
  OwnersData,
} from 'features/data/queries/owners';
import { generateApartmentPath, Paths } from 'app/Paths';
import { useCurrentRole } from 'app/hooks';
import { Link } from 'react-router-dom';
import { Gravatar } from '#common/Gravatar';
import { useFormat } from '../../../i18n';

interface Props {
  property: Property;
  ownersData: OwnersData;
}

const ActionsCell = ({ aptId }: { aptId: string }) => {
  const { t } = useTranslation();
  const { propertyId } = useCurrentRole();
  return (
    <Link
      to={generateApartmentPath(Paths.apartment.index, {
        role: RoleType.VAAD,
        propId: propertyId,
        aptId,
      })}
    >
      {t(tKeys.actions.view())}
    </Link>
  );
};

const BudgetDebtCell = ({ owner }: { owner: FlatOwner }) => {
  const { formatCurrency } = useFormat();
  return (
    <Box textAlign="right">
      {owner.balance.budget >= 0 ? '✅' : formatCurrency(owner.balance.budget)}
    </Box>
  );
};

const ProjectDebtCell = ({ owner }: { owner: FlatOwner }) => {
  const { formatCurrency } = useFormat();
  return (
    <Box textAlign="right">
      {owner.balance.projects === 0
        ? '✅'
        : formatCurrency(owner.balance.projects)}
    </Box>
  );
};

const OwnerNameCell = ({ owner }: { owner: FlatOwner }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '1em',
    }}
  >
    <Gravatar email={owner.email} /> {owner.fullName}
  </div>
);

export const OwnersListRead: FC<Props> = ({ property, ownersData }) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardContent>
        <TableList<FlatOwner>
          paper
          columns={[
            {
              title: t(tKeys.ownersPage.table.entrance()),
              align: 'center',
              render: (item) => entranceString(t, property, item.entrance),
            },
            {
              title: t(tKeys.ownersPage.table.apartment()),
              align: 'center',
              render: (item) => item.aptNum,
            },
            {
              title: t(tKeys.ownersPage.table.owner()),
              render: (item) => <OwnerNameCell owner={item} />,
            },
            {
              title: t(tKeys.ownersPage.table.debtBudget()),
              render: (item) => <BudgetDebtCell owner={item} />,
            },
            {
              title: t(tKeys.ownersPage.table.debtProject()),
              render: (item) => <ProjectDebtCell owner={item} />,
            },
            { render: (item) => <ActionsCell aptId={item.aptId!} /> },
          ]}
          items={flattenOwnersData(ownersData)}
        />
      </CardContent>
    </Card>
  );
};
