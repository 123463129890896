import { useAppSelector } from './index';
import { selectRole } from 'features/auth/authSlice';
import { RoleType } from 'api';

export const useCurrentRole = () => {
  const role = useAppSelector(selectRole)!;

  return {
    roleId: role.id,
    roleType: role.role,
    property: role.property,
    propertyId: role.propertyId,
    aptId: role.aptId,
    isVaad: role.role === RoleType.VAAD,
    isOwner: role.role === RoleType.OWNER,
    isTenant: role.role === RoleType.TENANT,
  };
};
