import { EmptyState } from '#common/EmptyState';
import { useTranslation } from 'react-i18next';
import BuildingBankIcon from '@mui/icons-material/AccountBalanceWalletTwoTone';
import { tKeys } from 'translationKeys';
export const BankManageEmptyState = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();

  return (
    <EmptyState
      tagline={t(tKeys.bankManage.emptyState.tagline())}
      description={t(tKeys.bankManage.emptyState.description())}
      icon={BuildingBankIcon}
      button={t(tKeys.bankManage.emptyState.button())}
      onClick={onClick}
    />
  );
};
