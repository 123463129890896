import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import menuReducer from '../features/menu/menuSlice';
import authReducer from '../features/auth/authSlice';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  menu: menuReducer,
  auth: authReducer,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const initStore = (preloadedState?: any) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
  });

export type Store = ReturnType<typeof initStore>;
export type AppDispatch = ReturnType<typeof initStore>['dispatch'];
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
