import { useAppSelector } from 'app/hooks';
import { selectRole } from 'features/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import { generatePropertyPath, Paths } from 'app/Paths';
import { useMutationNewProject } from 'features/data/queries/projects';
import { toastSuccess } from '#common/toasts';
import { tKeys } from 'translationKeys';
import { useTranslation } from 'react-i18next';
import { EditProjectForm } from './EditProjectForm';
import { Project } from 'api';

export const CreateProject = () => {
  const { t } = useTranslation();
  const role = useAppSelector(selectRole)!;
  const propId = role.propertyId;
  const navigate = useNavigate();

  const mutationNewProject = useMutationNewProject();

  const onClickCreate = ({
    name,
    description,
    amount,
    installments,
  }: Pick<Project, 'name' | 'description' | 'amount' | 'installments'>) => {
    mutationNewProject.mutate(
      {
        name,
        description,
        amount,
        installments,
      },
      {
        onSuccess() {
          toastSuccess(t(tKeys.projectsPage.newProject.success()));
          navigate(
            generatePropertyPath(Paths.property.projects.list, {
              propId,
              role: role.role,
            })
          );
        },
      }
    );
  };

  const onCancel = () => {
    navigate(
      generatePropertyPath(Paths.property.projects.list, {
        role: role.role,
        propId: role.propertyId,
      })
    );
  };

  return <EditProjectForm onConfirm={onClickCreate} onCancel={onCancel} />;
};
