import { tKeys } from 'translationKeys';
import { generateApartmentPath, generatePropertyPath, Paths } from 'app/Paths';
import { useTranslation } from 'react-i18next';
import { useCurrentRole } from 'app/hooks';
import { entranceString } from 'features/utils';

interface Params {
  entranceNum?: number;
  aptNum?: number;
  aptId?: string;
}

export const useBreadcrumbsForApartment = ({
  entranceNum,
  aptNum,
  aptId,
}: Params) => {
  const { t } = useTranslation();
  const { property, roleType } = useCurrentRole();
  const aptCaption =
    entranceNum !== undefined && aptNum !== undefined
      ? `${entranceString(t, property, entranceNum)} ${aptNum}`
      : '...';

  return [
    {
      title: t(tKeys.menu.building.owners()),
      href: generatePropertyPath(Paths.property.owners, {
        role: roleType,
        propId: property.id,
      }),
    },
    {
      title: aptCaption,
      href:
        aptId &&
        generateApartmentPath(Paths.apartment.index, {
          role: roleType,
          propId: property.id,
          aptId,
        }),
    },
  ];
};
