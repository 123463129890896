import { Page } from '#common/Page/Page';
import { useTranslation } from 'react-i18next';
import { Container, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { api } from 'api';
import { useAppSelector, useCurrentLanguage } from 'app/hooks';
import { selectIsLogged, selectRole } from 'features/auth/authSlice';
import { MembersCard } from './MembersCard';
import { InvitesMembers } from './InvitesMembers';
import { InviteMember } from './InviteMember';
import { RoleType } from '@vaad/client';
import { VaadMembersEmptyState } from './VaadMembersEmptyState';
import { tokenManager } from 'features/token-manager';
import { WithLoader } from '#common/WithLoader';
import { tKeys } from 'translationKeys';
import { InviteData } from './types';

export const VaadMembersPage = () => {
  const { t } = useTranslation();
  const role = useAppSelector(selectRole)!;
  const roleId = role.id;
  const { email: myEmail } = useAppSelector(selectIsLogged);
  const [loading, setLoading] = useState(true);
  const [creating, setCreating] = useState(false);
  const currentLanguage = useCurrentLanguage();

  const [vaadMembers, setVaadMembers] = useState(
    [] as { name: string; email: string }[]
  );
  const [invites, setInvites] = useState([] as InviteData[]);

  const onRevokeMember = (email: string) => {
    setVaadMembers(vaadMembers.filter((member) => member.email !== email));
    api.revokeVaadMember({
      token: tokenManager.get()!,
      params: { email, roleId },
    });
  };

  const onRevokeInvite = (id: string) => {
    setInvites(invites.filter((member) => member.id !== id));
    api.revokeInvite({
      token: tokenManager.get()!,
      params: {
        inviteId: id,
        roleId,
        roleType: RoleType.VAAD,
      },
    });
  };

  useEffect(() => {
    if (loading) {
      api
        .getVaadMembers({ params: { roleId }, token: tokenManager.get()! })
        .then((data) => {
          setVaadMembers(data.members);
          setInvites(data.invites as unknown as InviteData[]);
          setLoading(false);
        });
    }
  }, [loading, setLoading, setVaadMembers]);

  const isEmptyState = () =>
    vaadMembers?.length === 1 &&
    vaadMembers[0].email === myEmail &&
    invites.length === 0;

  const canAdmin = role.role === RoleType.VAAD;

  if (canAdmin && isEmptyState() && !creating) {
    const onClickEmptyState = () => {
      setCreating(true);
    };

    return <VaadMembersEmptyState onClick={onClickEmptyState} />;
  }

  const inviteNewMember = async ({
    name,
    email,
  }: {
    name: string;
    email: string;
  }) => {
    setInvites([
      ...invites,
      {
        id: '',
        invitee: { name, email },
        expires: 0,
      },
    ]);
    const inviteResponse = await api.invite({
      token: tokenManager.get()!,
      params: {
        roleId,
      },
      body: {
        email,
        name,
        lang: currentLanguage,
        type: RoleType.VAAD,
      },
    });

    if (!inviteResponse || !inviteResponse.invite) {
      return;
    }

    setInvites([
      ...invites,
      {
        id: inviteResponse.invite.id,
        invitee: {
          name,
          email,
        },
        expires: inviteResponse.invite.expires,
      },
    ]);
  };

  const adminContent = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={6} xl={6}>
          <MembersCard members={vaadMembers} onRevoke={onRevokeMember} admin />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <InvitesMembers invites={invites} onRevoke={onRevokeInvite} />

          <div style={{ height: 16 }} />
          <InviteMember onSend={inviteNewMember} />
        </Grid>
      </Grid>
    );
  };

  const nonAdminContent = () => {
    return (
      <Container maxWidth="sm">
        <MembersCard members={vaadMembers} onRevoke={onRevokeMember} />
      </Container>
    );
  };

  const content = () => (canAdmin ? adminContent() : nonAdminContent());

  return (
    <Page title={t(tKeys.vaadMembersPage.title())}>
      <WithLoader loading={loading}>{content()}</WithLoader>
    </Page>
  );
};
