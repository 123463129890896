import { Grow } from '@mui/material';
import { Vignette } from '#common/Vignette';
import { Link } from 'react-router-dom';
import { EntrancesNumbering, RoleType } from 'api';
import { Role } from 'features/auth/authSlice';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { tKeys } from 'translationKeys';
import { RoleIcon } from '#common/RoleIcon/RoleIcon';
import { cx } from '@emotion/css';
import { linkForRole } from 'features/auth/linkForRole';
import { StyledRoleBox } from './StyledRoleBox';
import * as classes from './classes';

type Props = {
  role: Role;
  i: number;
  onClick: () => void;
  archived?: boolean;
};

const entranceName = (role: Role, t: TFunction): string => {
  switch (role.property.numbering) {
    case EntrancesNumbering.LETTER:
      return String.fromCharCode(
        t(tKeys.roleSelector.entrances.letters.firstLetter()).charCodeAt(0) +
          role.entrance! -
          1
      );
    case EntrancesNumbering.NAME:
      return role.property.entranceNames![role.entrance! - 1];
  }
  return `${role.entrance!}`;
};

const apartmentTitle = (role: Role, t: TFunction): number | string => {
  if (role.aptNum === undefined) {
    return '';
  }
  if (role.property.entrances?.length <= 1) {
    return role.aptNum;
  }
  return `${entranceName(role, t)} ${role.aptNum}`;
};

export const RoleVignette = ({ role, i, onClick, archived }: Props) => {
  const { t } = useTranslation();

  const keyForRole: Record<RoleType, () => string> = {
    [RoleType.VAAD]: tKeys.roleSelector.subtitle.role_vaad,
    [RoleType.OWNER]: tKeys.roleSelector.subtitle.role_owner,
    [RoleType.TENANT]: tKeys.roleSelector.subtitle.role_tenant,
  };

  return (
    <div style={{ width: '240px', height: '240px' }}>
      <Grow in={true} timeout={200 * (i + 1)}>
        <div style={{ width: '180px', height: '180px' }}>
          <Vignette
            title={role.property.address}
            subtitle={t(keyForRole[role.role]())}
            elevateOnHover
            className={cx(classes.vignette, { [classes.archived]: archived })}
          >
            <Link
              to={linkForRole(role)}
              onClick={() => onClick()}
              style={{ textDecoration: 'none', color: 'black' }}
            >
              <StyledRoleBox>
                <div>
                  <RoleIcon roleType={role.role} />
                </div>
                <div>{apartmentTitle(role, t)}</div>
              </StyledRoleBox>
            </Link>
          </Vignette>
        </div>
      </Grow>
    </div>
  );
};
