import React from 'react';
import { PieChart as MinimalPieChart } from 'react-minimal-pie-chart';
import { useTranslation } from 'react-i18next';
import { BudgetLine } from '@vaad/client';
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { useFormat } from '../../../i18n';

const comparatorDesc =
  <T, Field extends keyof T>(field: Field) =>
  (itemB: T, itemA: T) =>
    itemA[field] < itemB[field] ? -1 : itemA[field] > itemB[field] ? 1 : 0;

const colors = [
  '#F66D44',
  '#FEAE65',
  '#b4c27c',
  '#AADEA7',
  '#64C2A6',
  '#2D87BB',
  '#9b74e0',
  '#cbb3f1',
  '#e8e82c',
  '#dcab44',
  '#76ece2',
];

export const BudgetPie = ({
  budgetLines,
  layout = 'vertical',
}: {
  budgetLines: BudgetLine[] | null;
  layout?: 'vertical' | 'horizontal';
}) => {
  const { t } = useTranslation();
  const { formatCurrency } = useFormat();
  const total = (budgetLines || []).reduce((acc, curr) => acc + curr.amount, 0);
  const pieData = (budgetLines || [])
    .filter((line) => line.amount > 0)
    .map((line, i) => ({
      name: line.description || t(`budget.topic.${line.topic}`),
      value: line.amount,
      percentage: `${Math.round((line.amount / total) * 100)}%`,
      color: colors[i % colors.length],
    }))
    .sort(comparatorDesc('value'));

  return (
    <Box style={{ padding: '1em', paddingTop: '2em' }}>
      <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Grid item lg={layout === 'vertical' ? 12 : 6}>
          <MinimalPieChart
            style={{ fontSize: '4px', maxHeight: '350px' }}
            data={pieData}
            viewBoxSize={[100, 100]}
            animate
            paddingAngle={2}
            radius={50}
            label={({ dataEntry }) => dataEntry.percentage}
            lineWidth={50}
            labelPosition={80}
          />
        </Grid>
        <Grid item lg={layout === 'vertical' ? 12 : 6}>
          <Box>
            <Table size="small">
              <TableBody>
                {pieData.map((line, i) => (
                  <TableRow key={i} sx={{ td: { border: 0 } }}>
                    <TableCell>
                      <div
                        style={{
                          display: 'inline-block',
                          width: 10,
                          height: 10,
                          border: 'solid thin black',
                          marginInlineEnd: '.5em',
                          backgroundColor: line.color,
                        }}
                      />
                      <Typography style={{ display: 'inline' }}>
                        {line.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography color={line.color}>
                        {formatCurrency(line.value)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
