import {
  Button,
  Card,
  CardContent,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { createRef, useState } from 'react';
import { tKeys } from 'translationKeys';
export const InviteMember = ({
  onSend,
}: {
  onSend: ({ name, email }: { name: string; email: string }) => void;
}) => {
  const { t } = useTranslation();
  const nameRef = createRef<HTMLInputElement>();
  const emailRef = createRef<HTMLInputElement>();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const onClickSend = () => {
    if (!name) {
      nameRef.current!.focus();
      return;
    }

    if (!email || !emailRef.current?.checkValidity()) {
      emailRef.current!.focus();
      return;
    }

    onSend({ email, name });
    setName('');
    setEmail('');
  };

  const onKeyPress = (ev: React.KeyboardEvent) => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      onClickSend();
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography component="h6">
          {t(tKeys.vaadMembersPage.cardNewInvite.title())}
        </Typography>

        <FormControl fullWidth>
          <TextField
            onKeyDown={onKeyPress}
            required
            value={name}
            inputRef={nameRef}
            fullWidth
            variant="standard"
            label={t(tKeys.vaadMembersPage.cardMembers.name())}
            onChange={(evt) => setName(evt.target.value)}
          />
        </FormControl>

        <FormControl fullWidth>
          <TextField
            onKeyDown={onKeyPress}
            onChange={(evt) => setEmail(evt.target.value)}
            required
            value={email}
            type="email"
            inputRef={emailRef}
            fullWidth
            variant="standard"
            label={t(tKeys.vaadMembersPage.cardMembers.email())}
          />
        </FormControl>

        <div style={{ textAlign: 'end', paddingTop: 16 }}>
          <Button variant="contained" onClick={onClickSend}>
            {t(tKeys.vaadMembersPage.cardNewInvite.button())}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};
