import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import { tKeys } from 'translationKeys';
import { useFormat } from 'i18n';
import { BudgetPie } from './BudgetPie';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentRole } from 'app/hooks';
import { Budget } from 'api';
import { numberOfApartments } from '../../../features/property';

interface Props {
  budget?: Budget;
  onUpdate: () => void;
}

export const ViewBudget: FC<Props> = ({ budget, onUpdate }) => {
  const { t } = useTranslation();
  const { formatCurrency } = useFormat();
  const { isVaad, property } = useCurrentRole();

  const total = budget?.lines?.reduce((acc, line) => acc + line.amount, 0) || 0;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={6}>
        <Card>
          <CardContent>
            <Typography variant="h5">
              {t(tKeys.viewBudget.costs(), {
                total: formatCurrency(total),
              })}
            </Typography>
            <BudgetPie budgetLines={budget!.lines} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={6} lg={6}>
        <Grid container spacing={'1rem'}>
          <Grid item xs={6}>
            <Card>
              <CardContent>
                <Typography variant="h5">
                  {t(tKeys.viewBudget.monthlyCollection(), {
                    amount: formatCurrency(budget!.collection),
                  })}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card>
              <CardContent>
                <Typography variant="h5">
                  {t(tKeys.viewBudget.monthlyCollectionTotal(), {
                    amount: formatCurrency(
                      numberOfApartments(property) * budget!.collection
                    ),
                  })}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {isVaad && (
          <Box style={{ marginTop: '2em' }}>
            <Button
              style={{ width: '100%' }}
              onClick={onUpdate}
              variant="contained"
            >
              {t(tKeys.budgetPage.manage.updateButton.caption())}
            </Button>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
