import { generatePropertyPath } from '../Paths';
import { useNavigate } from 'react-router-dom';

type PathParams = Parameters<typeof generatePropertyPath>;
export const useNavigateToPropertyPath = () => {
  const navigate = useNavigate();
  return (path: PathParams[0], { role, propId, ...rest }: PathParams[1]) =>
    navigate(
      generatePropertyPath(path, {
        role,
        propId,
        ...rest,
      })
    );
};
