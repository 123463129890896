import {
  Card,
  CardContent,
  CardHeader,
  Container,
  FormGroup,
  TextField,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import React, { FC, useRef } from 'react';
import { useCurrentRole } from 'app/hooks';
import { tKeys } from 'translationKeys';
import { ApartmentSelector } from '#common/ApartmentSelector';
import { VerticalForm } from '#common/VerticalForm';
import { SaveActionBar } from '#common/SaveActionBar';
import { DatePicker } from '@mui/x-date-pickers';
import { AmountTextField } from '#common/AmountTextField';
import { useCreateCheque } from './useCreateCheque';

const ToastMsg: FC<{ chequeNo: string }> = ({ chequeNo }) => (
  <Trans
    i18nKey={tKeys.cheques.managePage.newCheque.success()}
    values={{ chequeNo }}
    components={{ strong: <b /> }}
  />
);

export const CreateCheque = () => {
  const chequeNoRef = useRef<HTMLInputElement>();

  const {
    onCancel,
    onChangeApt,
    onSave,
    canSave,
    aptId,
    chequeNo,
    amount,
    onChangeAmount,
    onChangeChequeNo,
    onChangeDate,
    date,
    minDate,
    maxDate,
  } = useCreateCheque(
    (chqNo: string) => <ToastMsg chequeNo={chqNo} />,
    chequeNoRef
  );

  const { t } = useTranslation();
  const { property } = useCurrentRole();

  return (
    <Container maxWidth="md">
      <Card>
        <CardHeader title={t(tKeys.cheques.managePage.newCheque.title())} />
        <CardContent>
          <Container maxWidth="sm">
            <FormGroup>
              <VerticalForm
                lines={[
                  {
                    label: t(tKeys.cheques.managePage.newCheque.apartment()),
                    value: (
                      <ApartmentSelector
                        data-testid="apartment-selector"
                        property={property}
                        aptId={aptId}
                        onChange={onChangeApt}
                      />
                    ),
                  },
                  {
                    label: t(tKeys.cheques.managePage.newCheque.amount()),
                    value: (
                      <AmountTextField
                        data-testid="textfield-amount"
                        value={amount === 0 ? '' : amount}
                        onChange={(evt) =>
                          onChangeAmount(parseFloat(evt.target.value))
                        }
                      />
                    ),
                  },
                  {
                    label: t(tKeys.cheques.managePage.newCheque.chequeNum()),
                    value: (
                      <TextField
                        data-testid="textfield-cheque-no"
                        inputRef={chequeNoRef}
                        value={chequeNo}
                        onChange={(evt) => onChangeChequeNo(evt.target.value)}
                      />
                    ),
                  },
                  {
                    label: t(tKeys.cheques.managePage.newCheque.date()),
                    value: (
                      <DatePicker
                        minDate={minDate}
                        maxDate={maxDate}
                        views={['year', 'month']}
                        openTo={'month'}
                        value={new Date(date)}
                        onChange={onChangeDate}
                        renderInput={(params) => (
                          <TextField {...params} helperText={null} />
                        )}
                      />
                    ),
                  },
                ]}
              />
            </FormGroup>

            <SaveActionBar
              canSave={canSave}
              onSave={onSave}
              onCancel={onCancel}
            />
          </Container>
        </CardContent>
      </Card>
    </Container>
  );
};
