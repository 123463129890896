import { Paths } from 'app/Paths';
import { Navigate } from 'react-router-dom';
import React from 'react';

// http://localhost:3000/v/prop/def/budget

export const RedirectToLogin = () => {
  return (
    <Navigate
      to={{ pathname: Paths.login, search: `r=${location.pathname}` }}
      replace
    />
  );
};
