import { Card, CircularProgress, Container } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FullPageRollingBg } from '#common/FullPageRollingBg/FullPageRollingBg';
import { SignupForm } from './SignupForm';
import { Logo128 } from '#common/Logo128/Logo128';
import { api } from 'api';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  networkError,
  selectNetworkError,
  setToken,
} from 'features/auth/authSlice';
import { NetworkError } from '#common/NetworkError';
import styled from '@emotion/styled';
import { StyledLoginBox } from './StyledLoginBox';

const StyledContainer = styled(Container)`
  padding-top: 200px;
`;

export const Signup = () => {
  const { signinCode, emailHash } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isNetworkError = useAppSelector(selectNetworkError);

  useEffect(() => {
    if (signinCode) {
      dispatch(setToken(''));
    }
  }, [signinCode]);

  useEffect(() => {
    if (isNetworkError) {
      navigate('/', { replace: true });
    }
  }, [isNetworkError]);

  const onAccept = (name: string) => {
    if (signinCode && name && name.trim()) {
      setLoading(true);
      api
        .signup({ body: { signinCode, lang: 'en', name, captcha: 'foo' } })
        .then((response) => {
          if (response.token) {
            dispatch(setToken(response.token));
            navigate('/');
          } else {
            dispatch(networkError());
          }
        })
        .catch(() => {
          dispatch(networkError());
        });
    }
  };

  return (
    <FullPageRollingBg>
      {isNetworkError && <NetworkError />}

      <StyledContainer maxWidth="sm">
        <Card>
          <StyledLoginBox>
            <Logo128 />

            {!loading && (
              <SignupForm emailHash={emailHash!} onAccept={onAccept} />
            )}
            {loading && (
              <div style={{ textAlign: 'center' }}>
                <CircularProgress />
              </div>
            )}
          </StyledLoginBox>
        </Card>
      </StyledContainer>
    </FullPageRollingBg>
  );
};
