import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TenantIcon from '@mui/icons-material/HotelTwoTone';
import { EmptyState } from '#common/EmptyState';
import { Page } from '#common/Page/Page';
import { OwnerInviteTenant } from './OwnerInviteTenant';
import { CircularProgress } from '@mui/material';
import { OwnerInvitePending } from './OwnerInvitePending';
import { ShowTenantInfo } from './ShowTenantInfo';
import { tKeys } from 'translationKeys';
import {
  useMutationRevokeTenant,
  useQueryTenant,
} from 'features/data/queries/tenant';

export const TenantPage = () => {
  const { t } = useTranslation();
  const { data: tenantData } = useQueryTenant();
  const mutationRevokeTenant = useMutationRevokeTenant();
  const [isCreating, setIsCreating] = useState(false);
  const isEmpty = !tenantData?.tenant && !tenantData?.invite;

  const onEmptyStateClick = () => {
    setIsCreating(true);
  };

  if (!tenantData) {
    return <CircularProgress />;
  }

  if (isEmpty && !isCreating) {
    return (
      <Page title={t(tKeys.tenantPage.title())}>
        <EmptyState
          tagline={t(tKeys.tenantPage.emptyState.tagline())}
          description={t(tKeys.tenantPage.emptyState.description())}
          icon={TenantIcon}
          button={t(tKeys.tenantPage.emptyState.button())}
          onClick={onEmptyStateClick}
        />
      </Page>
    );
  }

  const onRevokeTenant = () => {
    mutationRevokeTenant.mutate();
  };

  return (
    <Page
      title={t(tKeys.tenantPage.title())}
      subtitle={t(tKeys.tenantPage.subtitle())}
    >
      {isEmpty && <OwnerInviteTenant />}
      {tenantData.invite && <OwnerInvitePending invite={tenantData.invite} />}
      {tenantData.tenant && (
        <ShowTenantInfo
          tenant={tenantData.tenant}
          onRevoke={() => onRevokeTenant()}
        />
      )}
    </Page>
  );
};
