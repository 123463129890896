import { Paths } from 'app/Paths';
import { useTranslation } from 'react-i18next';
import { relativize, useNestedRoutes } from 'features/utils';
import { tKeys } from 'translationKeys';
import { Page } from '#common/Page/Page';
import { CreatePayment } from './CreatePayment';
import { PaymentsManageList } from './PaymentsManageList';

const root = Paths.property.payments.list;

export const PaymentsPageTitles = () => {
  const { t } = useTranslation();
  return useNestedRoutes(root, [
    {
      index: true,
      element: t(tKeys.payments.page.title()),
    },
    {
      path: Paths.property.payments.new,
      element: t(tKeys.payments.managePage.newPayment.title()),
    },
  ]);
};

export const PaymentsRoutes = () => {
  return useNestedRoutes(root, [
    {
      index: true,
      element: <PaymentsManageList />,
    },
    {
      path: relativize(root)(Paths.property.payments.new),
      element: <CreatePayment />,
    },
  ]);
};

export const PaymentsVaadPage = () => {
  return (
    <Page title={<PaymentsPageTitles />}>
      <PaymentsRoutes />
    </Page>
  );
};
