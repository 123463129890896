import { useAppSelector } from 'app/hooks';
import { selectPropertyId } from '../../auth/authSlice';
import { api } from 'api';
import { tokenManager } from '../../token-manager';
import { useQuery, useQueryClient } from 'react-query';
import { keyResidentsList } from './keys';

type Resident = {
  owners: string[];
  tenants?: string[];
  entrance: number;
  apt: number;
  aptId: string;
};

export const useQueryResidents = () => {
  const propId = useAppSelector(selectPropertyId)!;
  return useQuery<Resident[]>(keyResidentsList, async () => {
    return await api.getResidents({
      token: tokenManager.get(),
      params: { propId },
    });
  });
};

export const useQueryResidentsClear = () => {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries(keyResidentsList);
};
